import { recipientsConstants } from '../constants';

const updateJqueryListRecipients = (recipients) => {
  const $tag = $('.js-shippingAddressShow');
  if ($tag.length > 0) {
    const recipientsList = recipients.map(i => i.full_name)
    $tag.html('');
    recipientsList.forEach((i) => {
      $tag.append(`<div class='text-dark text-small-1x'>${ i }</div>`)
    })
  }
}

const getInitialState = {
  recipients: [],
  viewType: 'form',
  phoneCarries: [],
  cities: [],
  addNewRecipient: false,
  isSaving: false,
  fullMessageError: undefined,
  newRecipient: {
    change_first_name: '',
    change_last_name: '',
    change_phone_number: '',
    change_city: 'Banjul',
    change_cashpower_meter_num: '',
    change_phone_carrier_id: ''
  }
}

let newStateAfterReduced = undefined;

export function recipients(state = {}, action) {
  const { type, containerKey } = action;

  switch (type) {
    case recipientsConstants.RECIPIENTS_INIT_VARIABLES:
      return {
        ...state,
        ...getInitialState,
        ...action.payload
      };
    case recipientsConstants.RECIPIENTS_CHANGE_VALUE:
      const changeValueState = Object.assign(state, state);
      changeValueState[action.key] = action.value;

      if (action.key === 'addNewRecipient') {
        changeValueState.newRecipient['errors'] = {};
        changeValueState.newRecipient['city'] = 'Banjul';
        changeValueState.newRecipient['change_city'] = 'Banjul';
        changeValueState.newRecipient['change_first_name'] = '';
        changeValueState.newRecipient['change_last_name'] = '';
        changeValueState.newRecipient['change_phone_number'] = '';
        changeValueState.newRecipient['change_cashpower_meter_num'] = '';
        changeValueState.newRecipient['change_phone_carrier_id'] = '';
      };

      return {
        ...changeValueState
      };
    case recipientsConstants.RECIPIENTS_EDIT_TOGGLE:
      const editToggleRecipients = Object.assign([], state.recipients);
      const r = editToggleRecipients.find(i => i.id === action.recipient.id)

      r['edit'] = action.value;
      r['change_first_name'] = r.first_name || '';
      r['change_last_name'] = r.last_name || '';
      r['change_phone_number'] = r.phone_number || '';
      r['change_city'] = r.city || 'Banjul';
      r['change_cashpower_meter_num'] = r.cashpower_meter_num || '';
      r['change_phone_carrier_id'] = r.phone_carrier_id || '';
      r['errors'] = {};

      const editToggleState = Object.assign(state, { recipients: editToggleRecipients });

      return {
        ...editToggleState
      };
    case recipientsConstants.RECIPIENTS_CHANGE_RECIPIENT_FIELD:
      /**
       * Recipient Change Field
       * @params action.id
       * @params action.key
       * @params action.value
       */

      if (action.id) {
        const _changeRecipients = Object.assign([], state.recipients);
        const _r = _changeRecipients.find(i => i.id === action.id)
        _r[action.key] = action.value;
        const newStates = Object.assign(state, { recipients: _changeRecipients });

        return {
          ...newStates
        };
      } else {
        const _newRecipient = Object.assign({}, state.newRecipient);
        _newRecipient[action.key] = action.value;

        return {
          ...state,
          newRecipient: _newRecipient
        }
      }
    case recipientsConstants.RECIPIENTS_SAVE_CHANGE:
      /**
       * Saving Process
       * @recipient object params
       * @newRecipient object params
       * @changedRecipient obect params
       */

      const saveRecipient = action.recipient;

      saveRecipient.edit = false;

      if (action.newRecipient) {

        saveRecipient.change_first_name = '';
        saveRecipient.change_last_name = '';
        saveRecipient.change_phone_number = '';
        saveRecipient.change_city = 'Banjul';
        saveRecipient.change_cashpower_meter_num = '';
        saveRecipient.change_phone_carrier_id = '';
        saveRecipient.errors = {};
        const _recipients = state.recipients;
        _recipients.push(action.changedRecipient);
        newStateAfterReduced = Object.assign({}, { ...state, newRecipient: saveRecipient, addNewRecipient: false, recipients: _recipients });

        updateJqueryListRecipients(_recipients);

        return {
          ...newStateAfterReduced
        }
      } else {
        saveRecipient.last_name = action.changedRecipient.last_name;
        saveRecipient.phone_number = action.changedRecipient.phone_number;
        saveRecipient.city = action.changedRecipient.city;
        saveRecipient.cashpower_meter_num = action.changedRecipient.cashpower_meter_num;
        saveRecipient.phone_carrier_id = action.changedRecipient.phone_carrier_id;
        saveRecipient.full_name = action.changedRecipient.full_name;
        saveRecipient.cash_power_label = action.changedRecipient.cash_power_label;
        saveRecipient.phone_carrier_label = action.changedRecipient.phone_carrier_label;
        saveRecipient.errors = {};

        const saveRecipients = Object.assign([], state.recipients);
        const saveStateRecipient = Object.assign(state, { recipients: saveRecipients })

        updateJqueryListRecipients(saveRecipients);

        return {
          ...saveStateRecipient
        }
      }
    case recipientsConstants.RECIPIENT_UPDATE_ERRORS:
      if (action.newRecipient === true) {
        const newRecipientWithError = Object.assign({}, state.newRecipient)
        newRecipientWithError.errors = action.errors;

        return {
          ...state,
          newRecipient: newRecipientWithError
        }
      } else {
        const recipientError = state.recipients.find(i => i.id === action.recipient.id);
        recipientError.errors = action.errors;
        const newRecipients = Object.assign([], state.recipients);

        return {
          ...state,
          recipients: newRecipients
        }
      }
    case recipientsConstants.RECIPIENT_DELETE_RECIPIENT:
      const deleteRecipients = Object.assign([], state.recipients);
      const indexDelete = state.recipients.indexOf(action.recipient);
      deleteRecipients.splice(indexDelete, 1);

      updateJqueryListRecipients(deleteRecipients);

      return {
        ...state,
        recipients: deleteRecipients
      }
    default:
      return state
  }
}
