import _ from 'lodash';

import { store } from '../store';
import { recipientsConstants } from '../constants';
import { recipientService } from '../../../services/recipient_service';

/**
* Private Functions
**/

const actionType = recipientsConstants;
const reduxStore = store;

/**
* Global Actions
**/

const initState = payload => ({ type: recipientsConstants.RECIPIENTS_INIT_VARIABLES, payload });
const changeSimpleValue = (key, value) => ({
  type: recipientsConstants.RECIPIENTS_CHANGE_VALUE,
  key,
  value
});
const toggleEditRecipient = (recipient, value) => ({
  type: recipientsConstants.RECIPIENTS_EDIT_TOGGLE,
  recipient,
  value
});
const changeRecipientField = (id, key, value) => ({
  type: recipientsConstants.RECIPIENTS_CHANGE_RECIPIENT_FIELD,
  id,
  key,
  value
});
const saveRecipientChange = (recipient, newRecipient) => {
  return dispatch => {
    // Validation
    const errors = {};

    if (!recipient.change_first_name) { errors['first_name'] = 'is required' };
    if (!recipient.change_last_name) { errors['last_name'] = 'is required' };
    if (!recipient.change_phone_number) { errors['phone_number'] = 'is required' };

    if (_.isEmpty(errors)) {
      const { change_first_name, change_last_name, change_phone_number, change_city, change_cashpower_meter_num, change_phone_carrier_id } = recipient;

      const params = {
          recipient: {
            first_name: change_first_name,
            last_name: change_last_name,
            city: change_city,
            cashpower_meter_num: change_cashpower_meter_num,
            phone_carrier_id: change_phone_carrier_id,
            phone_number: change_phone_number
          }
        };

      let remote = undefined;

      if (newRecipient) {
        remote = recipientService.create(params);
      } else {
        remote = recipientService.update(recipient.id, params);
      }

      remote.then(
          response => {
            if(response.success) {
              dispatch({ type: recipientsConstants.RECIPIENTS_SAVE_CHANGE, recipient, newRecipient, changedRecipient: response.recipient });
            } else {
              dispatch(changeSimpleValue('fullMessageError', response.message))
            }
          },
          error => {
            dispatch(changeSimpleValue('fullMessageError', error))
          }
        );
    } else {
      dispatch({ type: recipientsConstants.RECIPIENT_UPDATE_ERRORS, recipient, errors, newRecipient });
    }
  }
}
const deleteRecipient = (recipient) => {
  return dispatch => {
    recipientService.deleteRecipient(recipient.id).then(
        response => {
          if(response.success) {
            dispatch({ type: recipientsConstants.RECIPIENT_DELETE_RECIPIENT, recipient });
          } else {
            dispatch(changeSimpleValue('fullMessageError', response.message))
          }
        },
        error => {
          dispatch(changeSimpleValue('fullMessageError', error))
        }
      );
  }
}

export const mainActions = {
  initState,
  changeSimpleValue,
  toggleEditRecipient,
  changeRecipientField,
  saveRecipientChange,
  deleteRecipient
};
