import _ from 'lodash';

import { store } from '../store';
import { constants } from '../constants';
import { userService } from '../../../services/user_service';

/**
* Private Functions
**/

const reduxStore = store;

/**
* Global Actions
**/

const initState = payload => ({ type: constants.INIT_VARIABLES, payload });
const setFile = file => ({ type: constants.SET_FILE, file });
const updateProp = (key, value) => { return { type: constants.UPDATE_VALUE, key, value } };
const verifyDocument = (e, file, first_name, last_name) => {
  e.preventDefault();

  return (dispatch) => {
    if (!first_name || !last_name || !file) {
      window.location.reload();
      return dispatch({ type: constants.UPDATE_VALUE, key: 'verifying', value: false });
    }

    dispatch({ type: constants.UPDATE_VALUE, key: 'verifying', value: true });
    dispatch({ type: constants.UPDATE_VALUE, key: 'showWebcam', value: false });

    userService.uploadVerifyDocument(file, first_name, last_name)
      .then(
        response => {
          if (response.errors && response.errors.length > 0) {
            window.location.reload();
          }
        },
        error => {
          dispatch({ type: constants.UPDATE_VALUE, key: 'verifying', value: false });

          setTimeout(
            () => {
              $('.modal').modal('hide');
            }, 500
          )
        }
      );
  }
}
const checkingVerification = () => {
  return (dispatch) => {
    userService.checkingVerification()
      .then(
        response => {
          if (response.verifying === false) {
            dispatch({ type: constants.UPDATE_VALUE, key: 'verifying', value: false });

            if (response.verified === true) {
              dispatch({ type: constants.UPDATE_VALUE, key: 'verifyStatus', value: 'success' });
            } else {
              dispatch({ type: constants.UPDATE_VALUE, key: 'verifyStatus', value: 'failed' });
              dispatch({ type: constants.UPDATE_VALUE, key: 'verificationError', value: response.error });
            }
          }
        },
        error => {
          dispatch({ type: constants.UPDATE_VALUE, key: 'verifying', value: false });
          setTimeout(
            () => {
              $('.modal').modal('hide');
            }, 500
          )
        }
      );
  }
}

export const mainActions = {
  initState,
  setFile,
  verifyDocument,
  updateProp,
  checkingVerification
};
