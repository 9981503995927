import React from "react";
import _ from 'lodash';
import { IMaskInput } from 'react-imask';
import InputMask from 'react-input-mask';

import AppVerifyAccount from '../../app-verify-account';
import WoofRecipientForm from './woof_recipient_form';
import WoofBillingAddressForm from './woof_billing_address_form';

import { userActions } from '../actions';
import { store } from '../store';
import { userConstants } from '../constants';
import { locationService } from '../../../services/location_service';
import { GTagRequest } from '../../../lib/GTag';

class WoofCheckoutComponent extends React.Component {
  constructor(props) {
    super(props);

    let currentStep = 0;
    if (!this.props.ownProps.current_user_email) {
      // Showing Email Registration
      currentStep = 0;
    } else if (!this.props.ownProps.current_user_full_name || !this.props.ownProps.current_user_phone_number || !this.props.hasPassword) {
      // Showing Create Password
      currentStep = 1;
    }
    else if ((!this.props.ownProps.current_user_email || this.props.orderState === 'address') && !this.props.recipient && !this.props.orderSkipRecipientDeliveryMethod) {
      // Showing Recipient Information
      currentStep = 2;
    }
    else if ((!this.props.ownProps.current_user_email || this.props.orderState === 'address') && this.props.recipient && !this.props.orderSkipRecipientDeliveryMethod) {
      // Showing Delivery Method
      currentStep = 3;
    } else if (this.props.orderState === 'payment' && _.isEmpty(this.props.subscriptionRecipient) && this.props.hasSubscription) {
      // Showing choosing recipient
      currentStep = 4;
    }
    // else if (this.props.shippingInformation.id && !this.props.current_user_has_free_limit) {
    //   // Showing Veryfing Section
    //   currentStep = 5;
    // }
    else if (this.props.shippingInformation.id || (this.props.shippingInformation.id  && this.props.hasSubscription && !_.isEmpty(this.props.subscriptionRecipient))) {
      // Showing Payment method
      currentStep = 6;
    }

    this.state = {
      steps: [
        'create-account',
        'create-password',
        'shipping-information',
        'delivery-method',
        'subscription-recipient',
        'verify-account',
        'payment-method'
      ],
      showAsSection: currentStep === 5 ? 'SHOW_AS_SECTION' : 'SHOW_AS_MODAL',
      currentStep,
      currentUserEmail: this.props.ownProps.current_user_email,
      currentUser: {
        email: this.props.ownProps.current_user_email || '',
        password: '',
        first_name: this.props.ownProps.current_user_first_name || '',
        last_name: this.props.ownProps.current_user_last_name || '',
        phone_number: this.props.ownProps.current_user_phone_number || ''
      },
      ...this.defaultRecipientAttributes(),
      shippingInformation: {
        firstname: this.props.shippingInformation.firstname || 'na',
        address1: this.props.shippingInformation.address1 || 'na',
        apt: this.props.shippingInformation.apt || '',
        zipcode: this.props.shippingInformation.zipcode || 'na',
        phone: this.props.shippingInformation.phone || '',
        state_id: this.props.shippingInformation.state_id || this.props.states[0].id,
        city: this.props.shippingInformation.city || this.props.states[0].name,
        country_id: this.props.shippingInformation.country_id || "85",
        shipping_method_id: this.props.shippingMethodId || ((this.props.shipping_methods && this.props.shipping_methods.length > 0) ? this.props.shipping_methods[this.props.shipping_methods.length - 1].id : undefined)
      },
      cardInformation: {
        ...this.defaultCardInformation()
      },
      stripeToken: null,
      stripeCard: null,
      recipientInformationConfirmed: true,
      createNewRecipient: false,
      addMoreInfoRecipient: false,
      showDeliveryMethod: currentStep === 3 ,
      nonceToken: undefined,
      payBy: this.props.orderCurrency === 'GMD' ? 'COD' : 'CreditCard',
      hasLoadPaypalButton: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecipientInformation = this.handleRecipientInformation.bind(this);
    this.handleRecipientSelect = this.handleRecipientSelect.bind(this);
  }

  componentDidMount = () => {
    const { ownProps, registerEmail, clearError } = this.props;

    $('.js-loadCheckout').remove();
    if (ownProps.current_user_email) {
      registerEmail(ownProps.current_user_email);
    }

    setTimeout(() => {
      FormController.prototype.listenForEvents();
      FormController.prototype.initialize();
      ApplicationController.prototype.initInputMask();
      this.initBraintreeForm();
      clearError('paymentInformationErrors');
    }, 600)
  }

  componentDidUpdate = (oldProps) => {
    const newProps = this.props;

    if (this.props.braintreePaymentMethod && (oldProps.braintreePaymentMethod !== this.props.braintreePaymentMethod)) {
      setTimeout(
        () => {
          this.initBraintreeForm()
          this.props.clearError('paymentInformationErrors');
        }, 600
      )
    }
    if (this.props.editMyCreditCard == true && oldProps.editMyCreditCard == false) {
      setTimeout(
        () => {
          this.initBraintreeForm();
          this.props.clearError('paymentInformationErrors');
        }, 600
      )
    }

    FormController.prototype.initialize();
    FormController.prototype.listenForEvents();
  }

  defaultCardInformation = () => {
    return {
      name_on_card: '',
      number: '',
      exp: '',
      cvc: '',
      address: '',
      zip_code: '',
      country_id: '',
      state_id: '',
      city: '',
      countries: this.props.countries,
      states: this.props.states
    }
  }

  initBraintreeForm = () => {
    const { braintreePaymentMethod, clearError, latestCreditCard, editMyCreditCard } = this.props;

    if (!_.isEmpty(braintreePaymentMethod) && !(latestCreditCard && !editMyCreditCard)) {
      window.braintree.client.create({
        authorization: braintreePaymentMethod.client_token
      }, this.setBraintreeCreateClient);

      clearError('paymentInformationErrors');
    }
  }

  collapseAllTabs = () => {
    $('[data-toggle="collapse"]').each(function() { $($(this).attr('href')).collapse('hide') })
  }

  defaultRecipientAttributes(recipient_id = null, subscription_recipient_id = null) {
    return {
      recipientInformation: {
        recipient_id: recipient_id || (this.props.recipient ? this.props.recipient.id : undefined),
        subscription_recipient_id: subscription_recipient_id || (this.props.subscriptionRecipient ? this.props.subscriptionRecipient.id : undefined),
        create_new_recipient: {
          first_name: '',
          last_name: '',
          phone_number: '',
          city: this.props.cities[0],
          second_phone_number: '',
          cashpower_meter_num: '',
          email: ''
        }
      }
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { currentUser } = this.state;

    this.setState({
      currentUser: {
        ...currentUser,
        [name]: value
      }
    });
  }

  handleChangeShippingInformation(event) {
    const { name, value } = event.target;
    const { shipping_methods } = this.props.ownProps;

    if (name === 'shipping_method_id') {
      const $subTotalPrice = $('.js-subTotalPrice');
      const subTotalPriceValue = parseFloat($subTotalPrice.data('value'));

      const $discountPrice = $('.js-discountPrice');
      const discountPriceValue = parseFloat($discountPrice.data('value'));

      const $shippingMethod = $('.js-shippingMethodPrice');
      const shippingMethod = this.props.ownProps.shipping_methods.find(i => i.id === parseInt(value));
      const shippingMethodValue = shippingMethod.price;
      $shippingMethod.html(shippingMethod.price_string)

      const allTotalPrice = subTotalPriceValue + discountPriceValue + shippingMethodValue;
      $('.js-totalPrice .money-whole').html(allTotalPrice);
      $('.js-totalPrice .money-decimal-mark').remove();
      $('.js-totalPrice .money-decimal').remove();
    }

    this.setState({
      shippingInformation: {
        ...this.state.shippingInformation,
        [name]: value
      }
    });
  }

  handleRecipientInformation(event) {
    const self = this;
    const { name, value } = event.target;
    const { recipients } = this.props;

    this.setState({
      recipientInformation: {
        create_new_recipient: {
          ...this.state.recipientInformation.create_new_recipient,
          [name]: value
        }
      }
    });
  }

  handleRecipientSelect(event) {
    const { name, value } = event.target;
    const { recipients } = this.props;

    if (name === 'recipient_id') {
      this.notUseFormCreateRecipient()
    }

    this.setState({
      recipientInformation: {
        ...this.state.recipientInformation,
        [name]: value,
      },
      createNewRecipient: false,
      showAsSection: 'SHOW_AS_MODAL'
    });
  }

  stateChooseOptions() {
    const { stateOptions } = this.props;
    const { states } = this.props.ownProps;
    const stateOptionsByCountry = stateOptions.filter(i => i.country_id === parseInt(this.state.shippingInformation.country_id));

    return this.state.isForInternational ? (stateOptionsByCountry) : states;
  }

  handleChangeCreditCard(name, value) {
    const self = this;
    if (name === 'country_id' && value) {
      locationService.apiStates(value)
        .then(
          (response) => {
            self.setState({
              cardInformation: {
                ...self.state.cardInformation,
                states: response.states
              }
            })
          },
          (error) => {
            throw Error('State could not be loaded.')
          }
        )
    } else {
      self.setState({
        cardInformation: {
          ...self.state.cardInformation,
          states: []
        }
      });
    }

    this.setState({
      cardInformation: {
        ...this.state.cardInformation,
        [name]: value
      }
    });
  }

  registerSuccess = (user) => {
    window.location.reload();
    // add email and switch step
    // this.setState({ currentUserEmail: user.email, currentStep: 1 });
  }

  createPasswordSuccess = () => {
    if (this.props.orderSkipRecipientDeliveryMethod && !this.props.current_user_has_free_limit) {
      this.setState({ currentStep: 4 });
    } else {
      this.setState({ currentStep: 2 });
    }
  }

  handleSubmit(event, type) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { currentUser} = this.state;
    const { clearError, addError, showSigninForm, hasPassword} = this.props;

    switch (type) {
      case 'SUBMIT_SIGNUP':
        clearError('signupErrors')

        if (!currentUser.email) {
          addError('signupErrors', 'email', 'Email is required');
          break;
        }
        if (showSigninForm && !currentUser.password) {
          addError('signupErrors', 'password', 'Password is required');
          break;
        }

        if (showSigninForm) {
          store.dispatch(userActions.login(currentUser));
        } else {
          store.dispatch(userActions.register(currentUser, this.registerSuccess));
        }

        break;
      case 'SUBMIT_CREATE_PASSWORD':
        clearError('signupErrors');
        let anySignupError = false;

        if (!currentUser.password && !hasPassword) { addError('signupErrors', 'password', 'Password is required'); anySignupError = true; }
        if (!currentUser.first_name) { addError('signupErrors', 'first_name', 'First name is required'); anySignupError = true; }
        if (!currentUser.last_name) { addError('signupErrors', 'last_name', 'Last name is required'); anySignupError = true; }
        if (!currentUser.phone_number) { addError('signupErrors', 'phone_number', 'Phone number is required'); anySignupError = true; }
        if (anySignupError) { break; }

        store.dispatch(userActions.createPassword(currentUser, currentUser, this.createPasswordSuccess));
        break;
      default:
        throw new Error('Type of signup is not valid');
    }
  }

  isActiveStep = (step) => {
    return this.state.steps.indexOf(step) <= this.state.currentStep;
  }

  ensureActiveStep = (step) => {
    return this.state.steps.indexOf(step) == this.state.currentStep;
  }

  buildUpdateParams = (type, options = {}) => {
    let attr = {};
    const attrAddress = {
      firstname: this.state.currentUser.first_name,
      address1: 'na',
      zipcode: this.props.billAddress.zipcode || this.state.cardInformation.zip_code || 'na',
      phone: this.state.currentUser.phone_number,
      state_id: this.props.states[0].id,
      city: this.props.states[0].name,
      country_id: this.state.cardInformation.country_id || '85',
      lastname: this.state.currentUser.last_name,
      address2: '',
     }

    if (type === 'address') {
      const id = this.props.shippingInformation.id || this.state.shippingInformation.id || null;
      const { shipAddressId } = this.props;

      attr = {
        order: {
         email: this.state.currentUser.email,
         bill_address_attributes: { ...attrAddress },
         use_billing: '0',
         shipping_method_id: this.state.shippingInformation.shipping_method_id
       },
      }

      if (id) { attr.order.bill_address_attributes['id'] = id };
      if (shipAddressId) { attr.order['ship_address_attributes'] = { id: shipAddressId }};

      return attr;
    } else if (type === 'payment') {
      const {
        latestCreditCard,
        editMyCreditCard,
        braintreePaymentMethod,
        payflowPaymentMethodId,
        codPaymentMethod,
        bankTransferPaymentMethod,
        orderCurrency,
        stripePaymentMethod
      } = this.props;

      const {
        payBy,
        stripeToken,
        stripeCard
      } = this.state;

      const _stripeCard = options.stripeCard || stripeCard;
      
      let firstNameOnCard = undefined;
      let lastNameOnCard = undefined;

      if (this.state.cardInformation.name_on_card) {
        firstNameOnCard = this.state.cardInformation.name_on_card.split(" ");
        lastNameOnCard = firstNameOnCard.pop();
        firstNameOnCard = firstNameOnCard[0];
      }

      let payment_method_id = payflowPaymentMethodId;

      if (orderCurrency === 'GMD') {
        if (payBy === 'COD') {
          payment_method_id = codPaymentMethod.id;
        } else {
          payment_method_id = bankTransferPaymentMethod.id;
        }
      } else if (payBy === 'CreditCard' && stripePaymentMethod) {
        payment_method_id = stripePaymentMethod.id;
      }

      attr = {
        order: {
          payments_attributes: [{
            payment_method_id: `${ payment_method_id }`
          }]
        },
        state: "confirm",
        temp_payment_country_id: this.state.cardInformation.country_id,
        payment_source: {}
      }

      if (orderCurrency !== 'GMD') {
        if (payBy === 'CreditCard' && latestCreditCard && !editMyCreditCard) {
          attr['use_existing_card'] = 'yes';
          attr.order['wallet_payment_source_id'] = latestCreditCard.id;

          attr.payment_source[`${ payflowPaymentMethodId }`] = {
            "payment_type": "CreditCard"
          }
        } else {
          attr.order['bill_address_attributes'] = {
            firstname: firstNameOnCard || this.props.billAddress.firstname || this.state.currentUser.first_name,
            lastname: lastNameOnCard || this.props.billAddress.lastname || this.state.currentUser.last_name,
            name_on_card: this.state.cardInformation.name_on_card,
            address1: this.state.cardInformation.address || this.props.billAddress.address1,
            zipcode: this.state.cardInformation.zip_code || this.props.billAddress.zipcode,
            phone: this.state.currentUser.phone_number,
            state_id: this.state.cardInformation.state_id || this.props.billAddress.state_id,
            city: this.state.cardInformation.city || this.props.billAddress.city,
            country_id: this.state.cardInformation.country_id || this.props.billAddress.country_id,
            address2: '',
          }

          if (stripePaymentMethod) {
            attr.payment_source[`${ stripePaymentMethod.id }`] = {
              cc_type: _stripeCard.brand,
              gateway_payment_profile_id: stripeToken,
              last_digits: _stripeCard.last4,
              month: _stripeCard.exp_month,
              year: _stripeCard.exp_year
            }

            const addressAttr = {
              name: this.state.shippingInformation.firstname,
              address_attributes: {
                ...this.state.shippingInformation,
                lastname: '',
                address2: ''
              }
            }

            attr.payment_source[`${ stripePaymentMethod.id }`] = Object.assign(attr.payment_source[`${ stripePaymentMethod.id }`], addressAttr)
          } else {
            attr.order['payments_attributes'][0]['source_attributes'] = {
              payment_type: 'CreditCard',
              card_references: {
                number: this.state.cardInformation.number.replace(/-/g, ""),
                year: this.state.cardInformation.exp.split('/')[1].trim(),
                month: this.state.cardInformation.exp.split('/')[0].trim(),
                security_code: this.state.cardInformation.cvc
              }
            }
          }
        }
      }

      return attr;
    } else if (type === 'recipient') {
      if (this.state.createNewRecipient === true) {
        attr = {
          create_new_recipient: true,
          recipient: this.state.recipientInformation.create_new_recipient,
          confirmed: this.state.recipientInformationConfirmed
        }
      } else {
        attr = {
          create_new_recipient: false,
          confirmed: this.state.recipientInformationConfirmed,
          recipient: {
            recipient_id: this.state.recipientInformation.recipient_id,
            subscription_recipient_id: this.state.recipientInformation.subscription_recipient_id
          }
        }
      }

      return attr;
    }
  }

  submitShippingInformation = (event) => {
    event.preventDefault();

    if (this.state.currentStep === 0) {
      this.setState({ currentStep: this.state.steps.indexOf('shipping-information') });
      this.collapseAllTabs();

      setTimeout(() => {
        $('#shippingMethod').collapse('show');
        FormController.prototype.listenForEvents();
      }, 500);
    } else if (this.state.currentStep === 1) {
      // Submit Moved to onSubmitShippingInformation
    } else if (this.state.currentStep === 3) {
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }
  }

  onSubmitDeliveryMethod = () => {
    event.preventDefault();
    const {
      updateShippingInformation,
      onSubmittingInfo,
      current_user_has_free_limit,
      subscriptionRecipient,
      hasSubscription,
      clearError
    } = this.props;

    const addressParams = this.buildUpdateParams('address');
    const self = this;

    const successCallback = (response) => {
      self.collapseAllTabs();
      const _current_user_has_free_limit = current_user_has_free_limit || response.current_user_has_free_limit;

      if (response.state === 'payment' && _.isEmpty(subscriptionRecipient) && hasSubscription) {
        self.setState({ currentStep: 4 });
      // } else if (!_current_user_has_free_limit) {
      //   self.setState({ currentStep: 5, showAsSection: 'SHOW_AS_SECTION' });
      } else if (self.state.currentStep === 3 || self.state.currentStep === 6) {
        self.setState({ currentStep: 6 });

        setTimeout(() => {
          $('#paymentMethod').collapse('show');
          self.initBraintreeForm();
        }, 500);

        setTimeout(() => {
          clearError('paymentInformationErrors');
        }, 700);
      }

      self.setState({ shippingInformation: Object.assign(self.state.shippingInformation, addressParams.order.bill_address_attributes) });
      self.setState({ showDeliveryMethod: false })

      setTimeout(() => {
        ApplicationController.prototype.initInputMask();
        FormController.prototype.listenForEvents();
      }, 500);
    }

    const failCallback = (response) => {
      window.location.reload();
      console.log('error');
    }

    onSubmittingInfo();
    updateShippingInformation(addressParams, successCallback, failCallback);
  }

  onSubmitRecipientInfo = (event, forSubscription) => {
    event.preventDefault();

    const {
      updateRecipientInfo,
      onSubmittingInfo,
      clearError
    } = this.props;
    const self = this;

    const successCallback = (response) => {
      const recipientAttributes = forSubscription ? self.defaultRecipientAttributes(null, response.recipient.id) : self.defaultRecipientAttributes(response.recipient.id, null);

      self.setState({
        showDeliveryMethod: true,
        createNewRecipient: false,
        addMoreInfoRecipient: false,
        ...recipientAttributes
      });

      self.collapseAllTabs();

      self.setState({ currentStep: self.state.steps.indexOf('delivery-method') });

      setTimeout(() => {
        $('#deliveryMethod').collapse('show');
        FormController.prototype.listenForEvents();
      }, 200);
      // if (response.recipient.has_free_limit) {

        // setTimeout(() => {
        // }, 200);
        // if (self.state.currentStep === 2) {
        // } else if (self.state.currentStep === 4) {
        //   const current_user_has_free_limit = self.props.current_user_has_free_limit || response.current_user_has_free_limit;
        //   if (!current_user_has_free_limit) {
        //     self.setState({ currentStep: 5, showAsSection: 'SHOW_AS_SECTION' });
        //   } else {
        //     clearError('paymentInformationErrors');
        //     self.setState({ currentStep: self.state.steps.indexOf('payment-method') });

        //     setTimeout(() => {
        //       $('#paymentMethod').collapse('show');
        //       self.initBraintreeForm();
        //       self.props.clearError('paymentInformationErrors');
        //     }, 200);
        //   }
        // } else if (self.state.currentStep === 6) {
        //   $('#deliveryMethod').collapse('show');
        // }
      // }
    }

    const failCallback = (event) => {
      console.log('error');
    }

    onSubmittingInfo();
    updateRecipientInfo(this.buildUpdateParams('recipient'), successCallback, failCallback, forSubscription);
  }

  onSubmitShippingInformation = () => {
    const { updateShippingInformation, onAddingShippingInfo } = this.props;
    event.preventDefault();
    const self = this;
    const successCallback = (response) => {
      if (self.state.currentStep === 3) {
        self.setState({ currentStep: self.state.steps.indexOf('payment-method') });
      }

      $('.js-shippingMethodPrice').html(response.display_ship_total.replace('.00', ''));
      $('.js-subTotalPrice').html(response.display_item_total.replace('.00', ''));
      $('.js-discountPrice').html(`-$${Math.abs(parseInt(response.adjustment_total))}`);
      $('.js-totalPrice').html(response.display_total.replace('.00', ''));

      self.collapseAllTabs();

      setTimeout(() => {
        $('#paymentMethod').collapse('show');
        ApplicationController.prototype.initInputMask();
        FormController.prototype.listenForEvents();
      }, 500);
    }

    const failCallback = (response) => {
      console.log('error');
    }

    onAddingShippingInfo();
    updateShippingInformation(this.buildUpdateParams('address'), successCallback, failCallback);
  }

  paymentSuccessCallback = (response) => {
    window.location.href = Routes.spree_confirmed_woof_order_path(response.number);
  }

  paymentFailCallback = (response) => {
    const { addError, stopPlacingOrder } = this.props;
    addError('paymentInformationErrors', 'number', response.errors[0]);
    stopPlacingOrder();
  }

  setBraintreeCreateClient = (err, clientInstance) => {
    const { addError, stopPlacingOrder, onPlacingOrder, clearError } = this.props;
    const self = this;
    const $form = document.querySelector('#braintreeFormCreditCard');
    const createHostedFields = (clientInstance) => {
      braintree.hostedFields.create({
        client: clientInstance,
        styles: {
          'input': {
            'font-family': 'graphie, sans-serif !important',
            'height': '50px !important',
            'padding': '.375rem .75rem !important',
            'padding-top': '18px !important',
            'color': '#495057 !important',
            'font-size': '15px !important'
          },
          ':focus': {
            'color': '#495057 !important'
          },
          '.valid': {
            'color': '#bec598 !important'
          }
        },
        fields: {
          number: {
            selector: '#active-card-number',
            placeholder: '0000-0000-0000-0000'
          },
          cvv: {
            selector: '#active-card-cvc',
            placeholder: '000'
          },
          expirationDate: {
            selector: '#active-card-date',
            placeholder: '00 / 00'
          }
        }
      }, (err, hostedFieldsInstance) => {
        
        if (err) {
          if (err.code !== "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME") {
            addError('paymentInformationErrors', 'fullMessageError', err.message);
          }
          stopPlacingOrder();
          return;
        }
        
        const teardown = (event) => {
          onPlacingOrder();
          event.preventDefault();

          hostedFieldsInstance.tokenize( (tokenizeErr, payload) => {
            clearError('paymentInformationErrors');
            const blankValues = ["name_on_card", "address", "zip_code", "country_id", "state_id", "city"].filter(i => !this.state.cardInformation[i])
            const {
              current_user_id_first_name,
              current_user_id_last_name,
              current_user_verified,
              latestCreditCard,
              editMyCreditCard
            } = this.props;

            const { name_on_card } = this.state.cardInformation;
            let lastNameOnCard = undefined;
            let firstNameOnCard = undefined;
            let _name_on_card = undefined;

            let badNameOnCard = false;
            if (name_on_card && current_user_verified && current_user_id_first_name && current_user_id_last_name) {
              _name_on_card = name_on_card.split(" ");
              lastNameOnCard = _name_on_card[_name_on_card.length - 1].toLowerCase();
              firstNameOnCard = _name_on_card[0].toLowerCase();
              badNameOnCard = (!firstNameOnCard || !lastNameOnCard) || (firstNameOnCard && firstNameOnCard != current_user_id_first_name.toLowerCase()) || (lastNameOnCard && lastNameOnCard != current_user_id_last_name.toLowerCase())
            }
            if (tokenizeErr) {
              addError('paymentInformationErrors', 'fullMessageError', tokenizeErr.message);
              stopPlacingOrder();
              return;
            } else if (blankValues.length > 0) {
              blankValues.forEach(i => addError('paymentInformationErrors', i, 'is required'))
              stopPlacingOrder();
              return;
            } else if (badNameOnCard && current_user_verified) {
              addError('paymentInformationErrors', 'name_on_card', "Name doesn't match wih your id card.");
              stopPlacingOrder();
              return;
            } else {
              self.setState({ nonceToken: payload.nonce });
              self.onSubmitBraintreePaymentInformation();

              hostedFieldsInstance.teardown( () => {
                createHostedFields(clientInstance);
                $form.removeEventListener('submit', teardown, false);
              });
            }
          });

        };

        $form.addEventListener('submit', teardown, false);
      });
    }

    if (err) {
      addError('paymentInformationErrors', 'fullMessageError', err);
      stopPlacingOrder();
      return;
    }
    createHostedFields(clientInstance);
  }

  onSubmitBraintreePaymentInformation = () => {
    const self = this;
    const { clearError, latestCreditCard, onPlacingOrder, updatePaymentInformation, editMyCreditCard, braintreePaymentMethod } = this.props;

    clearError('paymentInformationErrors');
    onPlacingOrder();
    updatePaymentInformation(self.buildUpdateParams('payment'), this.paymentSuccessCallback, this.paymentFailCallback);
  }

  onSubmitCardInformation = () => {
    event.preventDefault();

    const self = this;

    const {
      name_on_card,
      number,
      exp,
      cvc,
      address,
      zip_code,
      country_id,
      state_id,
      city,
    } = this.state.cardInformation;

    const {
      stopPlacingOrder,
      validatePaymentInformation,
      clearError,
      latestCreditCard,
      onPlacingOrder,
      updatePaymentInformation,
      editMyCreditCard,
      current_user_verified,
      current_user_id_first_name,
      current_user_id_last_name,
      addError,
      orderCurrency,
      stripePaymentMethod
    } = this.props;

    const {
      payBy
    } = this.state;

    let lastNameOnCard = undefined;
    let firstNameOnCard = undefined;
    let _name_on_card = undefined;

    let badNameOnCard = false;

    if ((payBy === 'CreditCard' && latestCreditCard && !editMyCreditCard) || orderCurrency === 'GMD') {
      onPlacingOrder();
      updatePaymentInformation(self.buildUpdateParams('payment'), self.paymentSuccessCallback, self.paymentFailCallback);
    } else {
      clearError('paymentInformationErrors');
      validatePaymentInformation(this.state.cardInformation);

      if (payBy === 'CreditCard') {
        if (name_on_card && current_user_verified && current_user_id_first_name && current_user_id_last_name) {
          _name_on_card = name_on_card.split(" ");
          lastNameOnCard = _name_on_card[_name_on_card.length - 1].toLowerCase();
          firstNameOnCard = _name_on_card[0].toLowerCase();

          badNameOnCard = (!firstNameOnCard || !lastNameOnCard) || (firstNameOnCard && firstNameOnCard != current_user_id_first_name.toLowerCase()) || (lastNameOnCard && lastNameOnCard != current_user_id_last_name.toLowerCase())
        }

        if (badNameOnCard && current_user_verified) {
          addError('paymentInformationErrors', 'name_on_card', "Name doesn't match wih your id card.");
        } else if (!_.isEmpty(name_on_card) && !_.isEmpty(number) && !_.isEmpty(exp) &&
                   !_.isEmpty(cvc) && !_.isEmpty(address) &&
                   !_.isEmpty(zip_code) && !_.isEmpty(country_id) && !_.isEmpty(state_id) &&
                   !_.isEmpty(city)) {

          onPlacingOrder();

          // load stripe
          Stripe.setPublishableKey(stripePaymentMethod.key);
          Stripe.card.createToken({
            number,
            cvc,
            exp_month: parseInt(exp.split('/')[0].trim()),
            exp_year: parseInt(exp.split('/')[1].trim())
          }, this.stripeResponseHandler.bind(this));
        }
      } else {
        // paypal
        if (this.state.hasLoadPaypalButton === false && (address != '' && zip_code != '' && country_id != '' && state_id != '' && city != '')) {
          ApplicationController.prototype.loadPaypalButton();
          this.setState({ hasLoadPaypalButton: true });
        }
      }
    }
  }

  stripeResponseHandler = (status, response) => {
    const { addError, updatePaymentInformation, stopPlacingOrder } = this.props;
    const self = this;
    
    if (response.error) {
      addError('paymentInformationErrors', response.error.type, response.error.message);
      stopPlacingOrder();
    } else {
      this.setState({
        stripeToken: response.id,
        stripeCard: response.card
      });

      updatePaymentInformation(this.buildUpdateParams('payment', { stripeCard: response.card }), this.paymentSuccessCallback, this.paymentFailCallback);
    }
  }

  formCreateAccount = () => {
    if (!this.isActiveStep('create-account')) { return null };
    if (this.state.currentUserEmail) { return (<div className='text-gray mt-1 text-size-lg-2x pl-4'><i>{ this.state.currentUserEmail }</i></div>); }

    const { currentUser } = this.state;
    const { signupErrors, registering, updateValue, showSigninForm } = this.props;
    let errorMessage = null;

    if (signupErrors.length > 0) {
      if (signupErrors[0].message === 'has already been taken') {
        errorMessage = <span>That email has already been taken. if it's yours, please <a href='#' className='link-border' onClick={ () => { updateValue('showSigninForm', true) } }>Sign in</a>.</span>
      } else {
        errorMessage = signupErrors[0].message;
      }
    }

    return (
      <div className="collapse show" id="createAccount">
        <form className='form-checkout mt-4' onSubmit={ e => this.handleSubmit(e, 'SUBMIT_SIGNUP') }>
          <div className="row">
            {
              showSigninForm ?
                (
                  <div className="col-12">
                    {
                      errorMessage ? <div className='alert alert-danger'>{ errorMessage }</div> : null
                    }
                    <div className='form-group form-group-50 form-group-focus'>
                      <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" className='form-control' id='email' name='email' value={ currentUser.email } onChange={ this.handleChange } />
                      <label htmlFor="email"><i>email</i></label>
                    </div>
                    <div className='form-group form-group-50 form-group-focus'>
                      <input type="password" className='form-control' id='password' name='password' value={ currentUser.password } onChange={ this.handleChange } />
                      <label htmlFor="email">Password</label>
                    </div>
                  </div>
                ) :
                (
                  <div className="col-12">
                    <div className={ `form-group form-group-50 form-group-focus ${ errorMessage ? 'has-error-bar' : '' }` }>
                      <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" className='form-control' id='email' name='email' value={ currentUser.email } onChange={ this.handleChange } />
                      <label htmlFor="email"><i>email</i></label>
                      <span className={ `hint text-danger ${ errorMessage ? 'd-inline-block' : 'd-none' }` }>{ errorMessage ? errorMessage : '' }</span>
                    </div>
                  </div>
                )
            }
          </div>

          <input type="submit" className='mt-0 mb-4 btn btn-primary btn-block' disabled={ registering == true ? true : false } value={ registering == true ? 'Submitting ...' : "Continue" } />

          {
            showSigninForm ?
             (
              <p className='text-center text-small-1x text-lighter-weight'>
                Don't have an account yet?
                <a href='#' className='text-semi-bold-weight text-dark' onClick={ () => { updateValue('showSigninForm', false) } }>&nbsp;Create One</a>
                <br />
                <span className='text-small-2x'>
                  Forgot Your Password?
                  <a href={ Routes.spree_recover_password_path() } className='text-semi-bold-weight text-dark'>&nbsp;Click Here</a>
                </span>
              </p>
             ) :
             (
              <p className='text-center text-small-1x text-lighter-weight'>
                <i>already have an account?</i>
                <a href='#' className='text-semi-bold-weight text-dark' onClick={ () => { updateValue('showSigninForm', true) } }>&nbsp;Sign in</a>
              </p>
             )
          }
        </form>
      </div>
    );
  }

  completeAccountInfoSection = () => {
    const {
      signupErrors,
      registering,
      updateValue,
      showSigninForm,
      current_user_last_name,
      current_user_first_name,
      hasPassword,
      current_user_phone_number
    } = this.props;

    if (!this.ensureActiveStep('create-password')) { return null };

    const { currentUser } = this.state;
    let passwordError = null;
    let firstNameError = null;
    let lastNameError = null;
    let phoneNumberError = null;

    if (signupErrors.length > 0) {
      passwordError = signupErrors.find(i => i.name === 'password')
      firstNameError = signupErrors.find(i => i.name === 'first_name')
      lastNameError = signupErrors.find(i => i.name === 'last_name')
      phoneNumberError = signupErrors.find(i => i.name === 'phone_number')
    }

    return (
      <div className="card create-password square bg-grey-2x no-borders">
        <a href="#createPassword" data-toggle="collapse" href="#createPassword" role="button" aria-expanded="false" aria-controls="createPassword">
          <h4 className='text-dark'></h4>
        </a>

        <div className="collapse show" id="createPassword">
          <form className='form-checkout' onSubmit={ e => this.handleSubmit(e, 'SUBMIT_CREATE_PASSWORD') }>
            <div className="row">
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ firstNameError ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='first_name' name='first_name' value={ this.state.currentUser.first_name } onChange={ this.handleChange } />
                  <label htmlFor="first_name"><i>First Name</i></label>
                  <span className={ `hint text-danger ${ firstNameError ? 'd-inline-block' : 'd-none' }` }>{ firstNameError ? firstNameError.message : '' }</span>
                </div>
              </div>
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ lastNameError ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='last_name' name='last_name' value={ this.state.currentUser.last_name } onChange={ this.handleChange } />
                  <label htmlFor="last_name"><i>Last Name</i></label>
                  <span className={ `hint text-danger ${ lastNameError ? 'd-inline-block' : 'd-none' }` }>{ lastNameError ? lastNameError.message : '' }</span>
                </div>
              </div>
              {
                !current_user_phone_number ?
                  <div className="col-12">
                    <div className={ `form-group form-group-50 form-group-focus ${ phoneNumberError ? 'has-error-bar' : '' }` }>
                      <InputMask maskChar=' ' mask="+999999999999999999999999" className='form-control' id='phone-number' name='phone_number' onChange={ this.handleChange } />
                      <label htmlFor="password"><i>Phone Number</i></label>
                      <span className={ `hint text-danger ${ phoneNumberError ? 'd-inline-block' : 'd-none' }` }>{ phoneNumberError ? phoneNumberError.message : '' }</span>
                    </div>
                  </div> : null

              }
              {
                !hasPassword ?
                  <div className="col-12">
                    <div className={ `form-group form-group-50 form-group-focus ${ passwordError ? 'has-error-bar' : '' }` }>
                      <input type="password" className='form-control' id='password' name='password' onChange={ this.handleChange } />
                      <label htmlFor="password"><i>set your password</i></label>
                      <span className={ `hint text-danger ${ passwordError ? 'd-inline-block' : 'd-none' }` }>{ passwordError ? passwordError.message : '' }</span>
                    </div>
                  </div> : null
              }
            </div>

            <input type="submit" className='mt-0 btn btn-primary btn-block' disabled={ registering == true ? true : false } value={ registering == true ? 'SUBMITTING ...' : "Continue" } />
          </form>
        </div>
      </div>
    );
  }

  errorMessage = (collection, key) => {
    try {
      return collection.find(e => e.name === key).message
    }
    catch(err) {
      return undefined;
    }
  }

  useFormCreateRecipient = () => {
    GTagRequest.checkoutAddRecipient();

    this.setState({
      createNewRecipient: true,
      recipientInformation: {
        recipient_id: '',
        subscription_recipient_id: '',
        create_new_recipient: this.state.recipientInformation.create_new_recipient
      }
    })
  }

  notUseFormCreateRecipient = () => {
    this.setState({
      createNewRecipient: false,
      recipientInformation: {
        ...this.state.recipientInformation,
        create_new_recipient: {
          first_name: '',
          last_name: '',
          phone_number: '',
          city: this.props.cities[0],
          second_phone_number: '',
          cashpower_meter_num: '',
          email: ''
        }
      }
    })
  }

  disabledFormPaymentMethod = () => {
    if (!this.isActiveStep('payment-method')) { return null };

    const {
      placingOrder,
      latestCreditCard,
      updateValue,
      paymentInformationErrors
    } = this.props;


    return (
      <div className={ `collapse ${ this.isActiveStep('payment-method') ? 'show' : '' }` } id="paymentMethod">
        <form className='form-checkout mt-4' onSubmit={ (e) => { this.onSubmitCardInformation(e) } }>

          { paymentInformationErrors.length > 0 ? <div className='alert alert-danger'>{ paymentInformationErrors[0].message }</div> : null }

          <div className={ `${ this.state.payBy === 'CreditCard' ? '' : 'hidden' }` }>
            <div className="form-merge form-credit-card mb-4">

              <div className="form-group form-group-50 form-group-focus card-number">
                <IMaskInput
                  mask="•••• •••• •••• 0000"
                  value={ `•••• •••• •••• ${ latestCreditCard.last_digits }` }
                  disabled={ true }
                  className='form-control no-experience'
                  id='card-number'
                  name='number'
                  placeholder="0000-0000-0000-0000"
                />
                <label htmlFor="card-number">Card Number</label>
              </div>
              <div className="form-group form-group-50 form-group-focus card-date">
                <IMaskInput
                  mask={ latestCreditCard.month ? '00 / 00' : '•• / ••' }
                  value={ `${ latestCreditCard.month || '••' } / ${ latestCreditCard.year || '••' }` }
                  disabled={ true }
                  className='form-control no-experience'
                  id='card-date'
                  name='exp'
                  placeholder="00 / 00"
                />
                <label htmlFor="card-date">MM/YY</label>
              </div>
              <div className="form-group form-group-50 form-group-focus card-cvc">
                <IMaskInput
                  mask="•••"
                  value='•••'
                  disabled={ true }
                  className='form-control no-experience'
                  id='card-cvc'
                  name='cvc'
                  placeholder="000"
                />
                <label htmlFor="card-cvc">CVC</label>
              </div>
            </div>

            <div className='pull-right'>
              <a href='#' className='js-notJump text-dark' onClick={ () => { updateValue('editMyCreditCard', true) } }>Update My Credit Card</a>
            </div>

            <div className='clearfix'></div>
            <input type="submit" className='mt-3 btn btn-primary btn-block' disabled={ placingOrder } value={ placingOrder ? 'Placing order...' : 'Place order'  } />
          </div>

          <div id="empty-cart" className={ `${ this.state.payBy === 'PayPal' ? '' : 'hidden' }` }>
          </div>
        </form>
      </div>
    );
  }

  formPaymentMethod = () => {
    if (!this.isActiveStep('payment-method')) { return null };

    const { name_on_card, number, exp, cvc, address, zip_code, country_id, state_id, city, states, countries } = this.state.cardInformation;
    const { paymentInformationErrors, placingOrder, updateValue, latestCreditCard, braintreePaymentMethod } = this.props;
    const errorNumber = this.errorMessage(paymentInformationErrors, 'number') || this.errorMessage(paymentInformationErrors, 'exp') || this.errorMessage(paymentInformationErrors, 'cvc');
    const onUpdateAddressInfo = this.handleChangeCreditCard.bind(this);

    return (
      <div className={ `collapse ${ this.isActiveStep('payment-method') ? 'show' : '' }` } id="paymentMethod">
        <form className='form-checkout mt-4' onSubmit={ (e) => { this.onSubmitCardInformation(e) } }>

          { this.errorMessage(paymentInformationErrors, 'fullMessageError') ? <div className='alert alert-danger'>{ this.errorMessage(paymentInformationErrors, 'fullMessageError') }</div> : null }

          <div className={ `${ this.state.payBy === 'CreditCard' ? '' : 'hidden' }` }>
            <h5 className='text-dark'>Card Information</h5>

            <div className="row">
              <div className="col-12">
                <div className={ `form-group form-group-50 form-group-focus ${ this.errorMessage(paymentInformationErrors, 'name_on_card') ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='name_on_card' name='name_on_card' value={ name_on_card } onChange={ (e) => ( this.handleChangeCreditCard('name_on_card', e.currentTarget.value))} />
                  <label htmlFor="name_on_card">Name on Card</label>
                  <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'name_on_card') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'name_on_card') }</span>
                </div>
              </div>
            </div>

            <div className='form-merge form-credit-card' style={{ marginBottom: '10px' }}>
              <div className={ `form-group form-group-50 form-group-focus card-number ${ errorNumber ? 'has-error-bar' : '' }` }>
                <IMaskInput
                  mask="0000-0000-0000-0000"
                  className='form-control no-experience'
                  id='card-number'
                  name='number'
                  placeholder="0000-0000-0000-0000"
                  inputRef={el => this.input = el}  // access to nested input
                  onAccept={
                    (value, mask) => this.handleChangeCreditCard('number', value)
                  }
                />
                <label htmlFor="card-number">Card Number</label>
                <span className={ `hint text-danger ${ errorNumber ? 'd-inline-block' : 'd-none' }` }>{ errorNumber }</span>
              </div>
              <div className="form-group form-group-50 form-group-focus card-date">
                <IMaskInput
                  mask="00 / 00"
                  className='form-control no-experience'
                  id='card-date'
                  name='exp'
                  placeholder="00 / 00"
                  inputRef={el => this.input = el}  // access to nested input
                  onAccept={
                    (value, mask) => this.handleChangeCreditCard('exp', value)
                  }
                />
                <label htmlFor="card-date">MM/YY</label>
              </div>
              <div className="form-group form-group-50 form-group-focus card-cvc">
                <IMaskInput
                  mask="000"
                  className='form-control no-experience'
                  id='card-cvc'
                  name='cvc'
                  placeholder="000"
                  inputRef={el => this.input = el}  // access to nested input
                  onAccept={
                    (value, mask) => this.handleChangeCreditCard('cvc', value)
                  }
                />
                <label htmlFor="card-cvc">CVC</label>
              </div>
            </div>

            <WoofBillingAddressForm
              addressInformation={ this.state.cardInformation }
              errorAttributes={ paymentInformationErrors }
              updateAddressAttribute={ onUpdateAddressInfo }
              showErrorAttribute={ this.errorMessage } />

            {
              latestCreditCard ?
                <div className='pull-right'>
                  <a href='#' className='js-notJump text-dark' onClick={ () => { updateValue('editMyCreditCard', false) } }>Use Current Credit Card</a>
                </div> :
                null
            }

            <div className='clearfix'></div>

            <input type="submit" className='mt-3 btn btn-primary btn-block' disabled={ placingOrder } value={ placingOrder ? 'Finishing order...' : 'Finish'  } />
          </div>

          <div className={ `${ this.state.payBy === 'PayPal' ? '' : 'hidden' }` }>

            <h5 className='text-dark mt-4'>Billing Address - <span className='text-gray text-small-1x text-italic'>required to process your payment.</span></h5>

            <div className='paypal-address-section'>
              <div className="row mb-2">
                <div className="col-8">
                  <div className={ `form-group form-group-50 form-group-focus ${ this.errorMessage(paymentInformationErrors, 'address') ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='address' name='address' readOnly={ this.state.hasLoadPaypalButton } value={ address } onChange={ (e) => ( this.handleChangeCreditCard('address', e.currentTarget.value))} />
                    <label htmlFor="address">Street Address</label>
                    <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'address') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'address') }</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ `form-group form-group-50 form-group-focus ${ this.errorMessage(paymentInformationErrors, 'city') ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='city' name='city' readOnly={ this.state.hasLoadPaypalButton } value={ city } onChange={ (e) => ( this.handleChangeCreditCard('city', e.currentTarget.value))} />
                    <label htmlFor="city">City</label>
                    <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'city') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'city') }</span>
                  </div>
                </div>

                <div className="col-8">
                  <div className="row">
                    <div className="col-6">
                      <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ this.errorMessage(paymentInformationErrors, 'country_id') ? 'has-error-bar' : '' }` }>
                        <select className='form-control' id='country_id' name='country_id' disabled={ this.state.hasLoadPaypalButton } value={ country_id } onChange={ (e) => ( this.handleChangeCreditCard('country_id', e.currentTarget.value))}>
                          <option value=''></option>
                          { countries.map(country => <option key={ country.id } value={ country.id }>{ country.name }</option>) }
                        </select>
                        <label htmlFor="country_id">Country</label>
                        <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'country_id') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'country_id') }</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ this.errorMessage(paymentInformationErrors, 'state_id') ? 'has-error-bar' : '' }` }>
                        <select className='form-control' id='state_id' name='state_id' disabled={ this.state.hasLoadPaypalButton } value={ state_id } onChange={ (e) => ( this.handleChangeCreditCard('state_id', e.currentTarget.value))}>
                          <option value=''></option>
                          { states.map(state => <option key={ state.id } value={ state.id }>{ state.name }</option>) }
                        </select>
                        <label htmlFor="state_id">State/Province</label>
                        <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'state_id') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'state_id') }</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ `form-group form-group-50 form-group-focus ${ this.errorMessage(paymentInformationErrors, 'zip_code') ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='zip_code' name='zip_code' readOnly={ this.state.hasLoadPaypalButton } value={ zip_code } onChange={ (e) => ( this.handleChangeCreditCard('zip_code', e.currentTarget.value))} />
                    <label htmlFor="zip_code">Zip/Postcode</label>
                    <span className={ `hint text-danger ${ this.errorMessage(paymentInformationErrors, 'zip_code') ? 'd-inline-block' : 'd-none' }` }>{ this.errorMessage(paymentInformationErrors, 'zip_code') }</span>
                  </div>
                </div>
              </div>
            </div>
            <div id='paypal-error-msg'></div>
            <input type="submit" className={ `mt-3 btn btn-primary btn-block` } disabled={ placingOrder || this.state.hasLoadPaypalButton } value='Checkout With Paypal' />
            <hr className={ `mt-4 d-block ${ !this.state.hasLoadPaypalButton ? 'hidden' : '' }` } />
            <div id="paypal-button-container" className={ `${ this.state.hasLoadPaypalButton === false ? 'hidden' : '' }` }></div>
          </div>
        </form>
      </div>
    );
  }

  setShippingMethod = (shippingMethod) => {
    const $subTotalPrice = $('.js-subTotalPrice');
    const subTotalPriceValue = parseFloat($subTotalPrice.data('value'));

    const $discountPrice = $('.js-discountPrice');
    const discountPriceValue = parseFloat($discountPrice.data('value'));

    const $shippingMethod = $('.js-shippingMethodPrice');
    $shippingMethod.html(shippingMethod.price === 0 ? 'Free' : shippingMethod.price_string)

    const allTotalPrice = subTotalPriceValue + discountPriceValue + parseFloat(shippingMethod.price);
    $('.js-totalPrice .money-whole').html(Math.round(allTotalPrice * 100) / 100);
    $('.js-totalPrice .money-decimal-mark').remove();
    $('.js-totalPrice .money-decimal').remove();

    this.setState({
      shippingInformation: {
        ...this.shipping_information,
        shipping_method_id: shippingMethod.id
      }
    })
  }

  formChooseDeliveryMethod = () => {
    const { recipient, shipping_methods, submittingInfo } = this.props;
    if (!this.state.showDeliveryMethod) { return null }
    if (!this.isActiveStep('delivery-method') || !recipient) { return null; }
    const { shippingInformation } = this.state;

    return(
      <div className={ `card delivery-methods square ${ this.isActiveStep('delivery-method') ? 'bg-grey-2x no-borders' : '' }` }>
        <h4 className='text-dark'>
          <a href="#deliveryMethod" data-toggle="collapse" href="#deliveryMethod" role="button" aria-expanded="false" aria-controls="deliveryMethod">
            <span className='text-dark'>Delivery Method</span>
          </a>
        </h4>

        <div className={ `collapse ${ this.state.currentStep === 3 ? 'show' : '' }` } id="deliveryMethod">
          <form className='form-checkout mt-4' onSubmit={ (e) => { this.onSubmitDeliveryMethod(e) } }>
            {
              shipping_methods.map((method) => {
                return(
                  <a href='#' key={ method.id } className={ `js-notJump delivery-item ${ shippingInformation.shipping_method_id === method.id ? 'active' : '' }` } onClick={ () => { this.setShippingMethod(method) } }>
                    <div className="media">
                      <img src={ method.image_url } />
                      <div className="media-body">
                        <h5 className="mt-0">{ method.name }<span className='text-lighter-weight'> | { method.price == 0 ? 'Free' : method.price_string }</span></h5>
                        { method.description }
                      </div>
                    </div>
                  </a>
                )
              })
            }

            <br />
            <input type="submit" className='mt-2 mb-0 btn btn-primary btn-block' disabled={ submittingInfo == true ? true : false } value={ submittingInfo == true ? 'Submitting ...' : "Continue" } />
          </form>
        </div>
      </div>
    )
  }

  createAccountSection = () => {
    const { showSigninForm } = this.props;

    const checkSignedIn = () => {
      if (showSigninForm) {
        return { __html: '1. SIGN IN' };
      } else {
        return { __html: '1. Create Your Account' };
      }
    }

    return (
      <div className={ `card create-account square ${ this.isActiveStep('create-account') ? 'bg-grey-2x no-borders' : '' }` }>
        <a href="#createAccount" data-toggle="collapse" href="#createAccount" role="button" aria-expanded="false" aria-controls="createAccount">
          <h4 className='text-dark' dangerouslySetInnerHTML={ checkSignedIn() } />
        </a>

        { this.formCreateAccount() }
      </div>
    )
  }

  paymentMethodSection = () => {
    const {
      orderSkipRecipientDeliveryMethod,
      latestCreditCard,
      editMyCreditCard,
      clearError,
      orderCurrency,
      placingOrder,
      displayTotal,
      paymentInformationErrors
    } = this.props;

    const { payBy } = this.state;

    const renderPaymentForm = () => {
      if (payBy === 'CreditCard' && !this.props.current_user_has_free_limit) {
        return (
          <div>
            <div className="alert alert-info primary-color mb-1 mt-3">
              This order exceeds your pre-verification spending limit. To proceed with this transaction please <a href='#' className='js-notJump text-warning text-underline text-primary-hover' onClick={ (e) => { $('#modalVerifyAccount').modal('show') } }>verify your account</a>.
            </div>

            <p className='text-italic text-small-1x text-gray text-center my-3'>For questions and assistance verifying your account please contact us at <a href='tel:+220-2225000'>+220-2225000</a> or <a href='mailto:admin@terangas.com'>admin@terangas.com</a>.</p>

            <div className='text-right'>
              <a href='#' className={ `js-notJump mt-3 btn btn-cancel-gray btn-block` } onClick={ (e) => { $('#modalVerifyAccount').modal('show') } }>VERIFY ACCOUNT & CONTINUE</a>
            </div>
          </div>
        )
      }
      if (payBy === 'CreditCard' && latestCreditCard && !editMyCreditCard) { return this.disabledFormPaymentMethod() }
      return this.formPaymentMethod()
    }

    const paymentMethodOption = () => {
      if (orderCurrency === 'GMD' && this.isActiveStep('payment-method')) {
        return (
          <form className='form-checkout' onSubmit={ (e) => { this.onSubmitCardInformation(e) } }>
            <div className='choose-method d-flex mt-4'>
              <a href='#' className={ `w-50 btn btn-gray js-notJump square flex-fill pay-paypal mr-1 ${ payBy === 'COD' ? 'active' : '' }` } onClick={ () => { this.setState({ payBy: 'COD' }) }}>
                <span style={{ verticalAlign: 'bottom' }}>Cash on Delivery</span>
                <img src={ window.terangas.images.GMD_LOGO } className='img-fluid pull-right' style={{ maxWidth: '40px' }} />
              </a>
              <a href='#' className={ `w-50 btn btn-gray js-notJump square flex-fill pay-paypal mr-1 ${ payBy === 'BankTransfer' ? 'active' : '' }` } onClick={ () => { this.setState({ payBy: 'BankTransfer' }) }}>
                <span style={{ verticalAlign: 'bottom' }}>Bank Transfer</span>
                <img src={ window.terangas.images.BANK_TRANSFER_LOGO } className='img-fluid pull-right' style={{ maxWidth: '40px' }} />
              </a>
            </div>

            { this.errorMessage(paymentInformationErrors, 'fullMessageError') ? <div className='alert alert-danger'>{ this.errorMessage(paymentInformationErrors, 'fullMessageError') }</div> : null }

            {
              payBy === 'COD' ?
                <p className='mt-4'>
                  Your order will be processed now, and you will be required to pay { displayTotal } when your items are delivered.
                </p> :
                <p className='mt-4'>
                  Your order will be processed now. Please make your payment of { displayTotal } to Terranga Gambia Ltd using one of the account numbers below and send a screenshot by WhatsApp to +220-2225000 with your order number.
                  <br />
                  <br />GT: 216-856646-110
                  <br />Ecobank: 6261000195
                  <br />
                </p>
            }

            <input type="submit" className='mt-3 btn btn-primary btn-block' disabled={ placingOrder } value={ placingOrder ? 'Finishing order...' : 'Finish'  } />
          </form>
        );
      } else {
        return (
          <div>
            {
              this.isActiveStep('payment-method') ?
                <div className='choose-method d-flex mt-4'>
                  <a href='#' className={ `w-50 btn btn-gray js-notJump square flex-fill pay-paypal mr-1 ${ payBy === 'PayPal' ? 'active' : '' }` } onClick={ () => { this.setState({ payBy: 'PayPal' }) }}>
                    <span>Pay by paypal</span>
                    <img src={ window.terangas.images.PAYPAL_LOGO } className='img-fluid pull-right' />
                  </a>
                  <a href='#' className={ `w-50 btn btn-gray js-notJump square flex-fill pay-credit-card ${ payBy === 'CreditCard' ? 'active' : '' }` } onClick={ () => { this.setState({ payBy: 'CreditCard' }) }}>
                    <span>Pay by Card</span>
                    <ul className='pull-right list-unstyled d-flex list-image-credit-card'>
                      <li>
                        <img src={ window.terangas.images.VISA_CARD } className='img-fluid' />
                      </li>
                      <li>
                        <img src={ window.terangas.images.MASTER_CARD } className='img-fluid' />
                      </li>
                      <li>
                        <img src={ window.terangas.images.AMERICAN_CARD } className='img-fluid' />
                      </li>
                    </ul>
                  </a>
                </div> : null
            }
            {
              this.isActiveStep('payment-method') ? renderPaymentForm() : null
            }
          </div>
        );
      }
    }

    return (
      <div className={ `card payment-method square ${ this.isActiveStep('payment-method') ? 'bg-grey-2x no-borders' : '' }` }>
        <div className={ `submitting-payment d-flex align-items-center ${ placingOrder ? '' : 'hidden' } js-submittingPaymentInfo` }>
          <div className="spinner">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
        <h4 className='text-dark'>
          <a href="#paymentMethod" data-toggle="collapse" href="#paymentMethod" role="button" aria-expanded="false" aria-controls="paymentMethod">
            <h4 className='text-dark'>{ orderSkipRecipientDeliveryMethod ? '2' : '3' }. Payment Method</h4>
          </a>
        </h4>
        {
          paymentMethodOption()
        }
      </div>
    );
  }

  recipientAndDeliverySection = () => {
    const self = this;

    const {
      orderSkipRecipientDeliveryMethod,
      recipient,
      recipients,
      recipientInformationErrors,
      submittingInfo,
      cities
    } = this.props;

    const {
      createNewRecipient,
      handleRecipientInformation,
      addMoreInfoRecipient,
      recipientInformation,
      recipientInformationConfirmed
    } = this.state;

    const setAddRecipientInfo = (value) => {
      this.setState({ addMoreInfoRecipient: value })
    }

    const setRecipientInformationConfirmed = (value) => {
      this.setState({ recipientInformationConfirmed: value })
    }

    const returnWoofFormRecipient = () => {
      if (!self.isActiveStep('shipping-information')) { return null };

      let _recipient = recipient;
      if (self.state.recipientInformation && self.state.recipientInformation.recipient_id) {
        _recipient = recipients.find(i => i.id === parseInt(self.state.recipientInformation.recipient_id))
      }

      return (
        <div className={ `collapse ${ this.state.currentStep === 2 ? 'show' : '' }` } id="recipientInfo">
          <WoofRecipientForm
            onSubmitRecipientInfo={ this.onSubmitRecipientInfo }
            errorMessage={ this.errorMessage }
            handleRecipientSelect={ this.handleRecipientSelect }
            recipientInformationErrors={ recipientInformationErrors }
            recipients={ recipients }
            recipientInformation={ recipientInformation }
            createNewRecipient={ createNewRecipient }
            useFormCreateRecipient={ this.useFormCreateRecipient }
            handleRecipientInformation={ this.handleRecipientInformation }
            addMoreInfoRecipient={ addMoreInfoRecipient }
            setAddRecipientInfo={ setAddRecipientInfo }
            setRecipientInformationConfirmed={ setRecipientInformationConfirmed }
            recipientInformationConfirmed={ recipientInformationConfirmed }
            selectedRecipient={ _recipient }
            submittingInfo={ submittingInfo }
            cities={ cities }
            showVerificationSection={ true }
            />
        </div>
      )
    }

    if (orderSkipRecipientDeliveryMethod) {
      return null;
    } else {
      return (
        <div>
          <div className={ `card shipping-information square ${ this.isActiveStep('shipping-information') ? 'bg-grey-2x no-borders' : '' }` }>
            <h4 className='text-dark'>
              <a href="#recipientInfo" data-toggle="collapse" href="#recipientInfo" role="button" aria-expanded="false" aria-controls="recipientInfo">
                <span className='text-dark'>2. { recipient ? 'Recipient & Delivery' : 'Recipient Information' }</span>
              </a>
              {
                this.isActiveStep('shipping-information') ?
                  <span className='text-small-2x text-gray text-lighter-weight'><br />Please provide the details of the person who will receive the order</span> : null
              }
            </h4>

            { returnWoofFormRecipient() }
          </div>

          { this.formChooseDeliveryMethod() }
        </div>
      );
    }
  }

  chooseSubscriptionRecipient = () => {
    if (!this.ensureActiveStep('subscription-recipient')) { return null };

    const {
      orderSkipRecipientDeliveryMethod,
      subscriptionRecipient,
      recipients,
      recipientInformationErrors,
      submittingInfo,
      cities
    } = this.props;

    const {
      createNewRecipient,
      handleRecipientInformation,
      addMoreInfoRecipient,
      recipientInformation,
      recipientInformationConfirmed
    } = this.state;

    const setAddRecipientInfo = (value) => {
      this.setState({ addMoreInfoRecipient: value })
    }

    const setRecipientInformationConfirmed = (value) => {
      this.setState({ recipientInformationConfirmed: value })
    }

    return (
      <div>
        <div className='card shipping-information square bg-grey-2x no-borders'>
          <h4 className='text-dark'>
            <span className='text-dark'>Subscription Recipient</span>
            <br />
            <span className='text-small-2x text-gray text-lighter-weight'>
              Please provide the details of who will recieve the physical items in your
              subscription box (cashpower & phone credit recipeints handled separately).
            </span>
          </h4>

          <WoofRecipientForm
            onSubmitRecipientInfo={ this.onSubmitRecipientInfo }
            errorMessage={ this.errorMessage }
            handleRecipientSelect={ this.handleRecipientSelect }
            recipientInformationErrors={ recipientInformationErrors }
            recipients={ recipients }
            recipientInformation={ recipientInformation }
            createNewRecipient={ createNewRecipient }
            useFormCreateRecipient={ this.useFormCreateRecipient }
            handleRecipientInformation={ this.handleRecipientInformation }
            addMoreInfoRecipient={ addMoreInfoRecipient }
            setAddRecipientInfo={ setAddRecipientInfo }
            setRecipientInformationConfirmed={ setRecipientInformationConfirmed }
            recipientInformationConfirmed={ recipientInformationConfirmed }
            selectedRecipient={ subscriptionRecipient }
            submittingInfo={ submittingInfo }
            cities={ cities }
            forSubscription={ true }
            />
        </div>
      </div>
    );
  }

  verifyAccountSection = () => {
    if (!this.ensureActiveStep('verify-account') && this.state.showAsSection !== 'SHOW_AS_MODAL') { return null };

    const {
      first_name,
      last_name
    } = this.state.currentUser;

    return (
      <AppVerifyAccount verifying={ this.props.verifying } showAsSection={ this.state.showAsSection } id_first_name={ first_name } id_last_name={ last_name } />
    )
  }

  render () {
    return (
      <React.Fragment>
        <div>
          {/* Create Account Section: STEP 0 */}
          { this.createAccountSection() }

          {/* Complete Account Information: STEP 1 */}
          { this.completeAccountInfoSection() }

          {/* Add recipient Info: STEP 2 - Add Delivery Info: STEP 3 */}
          { this.recipientAndDeliverySection() }

          {/* Add subscription recipient Info: Add Delivery Info: STEP 4 */}
          { this.chooseSubscriptionRecipient() }

          {/* Complete Account Information: STEP 5 */}
          { this.verifyAccountSection() }

          {/* Complete Account Information: STEP 6 */}
          { this.paymentMethodSection() }

        </div>
      </React.Fragment>
    );
  }
}

export default WoofCheckoutComponent;
