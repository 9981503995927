import { userConstants } from '../constants';
import { userService } from '../../../services/user_service';
import { GTagRequest } from '../../../lib/GTag';

const actionType = userConstants;

const request = user => ({type: actionType.REGISTER_REQUEST, user});
const success = response => ({ type: actionType.REGISTER_SUCCESS, response });
const failure = response => ({ type: actionType.REGISTER_FAILURE, response });
const errors = error => ({ type: actionType.REGISTER_ERROR, error });

function createPassword(user, currentUser, successCallback) {
  return dispatch => {
    dispatch(request(user));
    userService.cratePassword(currentUser)
      .then(
        response => {
          if(response.errors) {
            dispatch({ type: actionType.ADD_INFORMATION_ERRORS, key: 'signupErrors', name: 'password', message: (response.errors[0] || response.errors.password[0]) })
            dispatch(failure(response));
          } else {
            dispatch({ type: actionType.REGISTER_PASSWORD_TO_FALSE, first_name: currentUser.first_name, phone_number: currentUser.first_name });
            dispatch({ type: actionType.CHECKOUT_PASSWORD_TO_FALSE, first_name: currentUser.first_name, phone_number: currentUser.first_name });
            GTagRequest.checkoutPersonalDetail(user.email);
            successCallback();
          }
        },
        error => {
          dispatch(errors(error.toString()));
        }
      );
  };
}

function register(user, successCallback) {
  return dispatch => {
    dispatch(request(user));
    userService.register(user)
      .then(
        response => {
          if(response.errors) {
            dispatch({ type: actionType.ADD_INFORMATION_ERRORS, key: 'signupErrors', name: 'email', message: response.errors.email[0] })
            dispatch(failure(response));
          } else {
            const $checkoutItems = $('.js-checkoutItems');
            $checkoutItems.html(response.cart_details);

            GTagRequest.checkoutCreateAccount(user.email);
            successCallback(user);
            dispatch(success(response));
          }
        },
        error => {
          dispatch(errors(error.toString()));
        }
      );
  };
}

const registerEmail = email => ({ type: userConstants.REGISTER_SET_EMAIL, email })
const login = (user) => {
  return dispatch => {
    dispatch(request(user));
    userService.login(user)
      .then(
        response => {
          if (response.errors) {
            dispatch({ type: actionType.ADD_INFORMATION_ERRORS, key: 'signupErrors', name: 'email', message: response.errors[0] })
            dispatch(failure(response))
          } else {
            window.location.href = Routes.spree_checkout_carts_path();
          }
        },
        error => {
          dispatch(errors(error.toString()));
        }
      )
  };
}

export const userActions = {
  register,
  registerEmail,
  login,
  createPassword
};
