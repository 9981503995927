import { cartConstants } from '../constants';
import { cartService } from '../../../services/cart_service';
import { orderService } from '../../../services/order_service';
import { subscriptionService } from '../../../services/subscription_service';
import { store } from '../store';

const reduxStore = store;
const actionType = cartConstants;

const success = (response) => {
  $('.js-navCartLinkMobile .js-navMobileQty').html(`(${ response.current_order_count })`);
  $('.js-navCartLink a').html(`Cart (${ response.current_order_count })`);

  $('.js-navCartLinkMobile a').addClass('active');
  $('.js-navCartLink').addClass('active');

  $('.js-addToCart').html('ADDED TO CART');
  $('.modal').modal('hide');
  setTimeout(() => { $('.js-addToCart').html('ADD TO CART') }, 3000);

  return { type: actionType.CART_UPDATE_SUCCESS, response }
};

const failure = order => ({ type: actionType.CART_UPDATE_FAIL, order });
const error = message => ({ type: actionType.CART_UPDATE_ERROR, message });
const initState = payload => ({ type: cartConstants.CART_INIT_VARIABLES, payload });

const orderServiceRemote = (dispatch, params, remove_extra_bags = false) => {
  orderService.addToCart(params, remove_extra_bags)
    .then(
      response => {
        if(response.errors) {
          dispatch(failure(response));
        } else {
          dispatch(success(response));
        }

        dispatch({ type: actionType.CART_UPDATING, update: false });
      },
      error => {
        dispatch(errors(error.toString()));
        dispatch({ type: actionType.CART_UPDATING, update: false });
      }
    );
}

const remoteCheckingValidCoupon = (code, dispatch) => {
  if (code.length > 2) {
    cartService.checkValidCoupon(code)
      .then(
        response => {
          dispatch({ type: actionType.CART_SET_VALID_COUPON_CODE, ...response, code })
        },
        error => {
          dispatch(error(error.toString()));
        }
      );
  } else {
    dispatch({ type: actionType.CART_SET_VALID_COUPON_CODE, avalaible: false, code })
  }
}

const updateInputCode = code => ({ type: actionType.CART_SET_VALID_COUPON_CODE, avalaible: false, code });

const remoteApplyCouponCode = (order_id, coupon_code, dispatch, authenticity_token) => {
  cartService.applyCouponCode(order_id, coupon_code, authenticity_token)
    .then(
      response => {
        dispatch({ type: actionType.CART_APPLY_COUPON_CODE, ...response })
      },
      error => {
        dispatch(error(error.toString()));
      }
    )
}

const updateLineItems = (orderId, lineItems, itemId, quantity) => {
  // updating lineItems
  const newLineItems = Object.assign([], lineItems);

  if (Number.isNaN(quantity)) {
    newLineItems.find(i => i.id === itemId).quantity = 1;
  } else {
    newLineItems.find(i => i.id === itemId).quantity = quantity;
  }

  let params = {};
  newLineItems.forEach((item) => {
    params[item.id] = { id: item.id, variant_id: item.variant_id, quantity: item.quantity };
  });

  return dispatch => {
    dispatch({ type: actionType.CART_UPDATING, update: true });
    dispatch({ type: actionType.CART_UPDATE_LINE_ITEMS, newLineItems });

    orderService.updateLineItems(orderId, params)
      .then(
        response => {
          if(response.errors || response.success == false) {
            window.location.reload();
            dispatch({ type: actionType.CART_UPDATE_FAIL });
          } else {
            dispatch({ type: actionType.CART_UPDATE_VALUE, key: 'order', value: response.order });
            dispatch(success(response));
          }

          dispatch({ type: actionType.CART_UPDATING, update: false });
        },
        error => {
          if (error && errors) {
            dispatch(errors(error.toString()));
            dispatch({ type: actionType.CART_UPDATING, update: false });
          }
        }
      );
  }
}

const updateSubscriptionLineItems = (type, lineItems, itemId, quantity, destroy=false) => {
  // updating lineItems
  const newSubscriptionLineItems = Object.assign([], lineItems);

  if (destroy === true) {
    newSubscriptionLineItems.find(i => i.id === itemId)['_destroy'] = "true";
  } else {
    if (Number.isNaN(quantity)) {
      newSubscriptionLineItems.find(i => i.id === itemId).quantity = 1;
    } else {
      newSubscriptionLineItems.find(i => i.id === itemId).quantity = quantity;
    }

    newSubscriptionLineItems.find(i => i.id === itemId)['_destroy'] = "false";
  }

  let loadingType = 'updatingWeekLineItem';
  if (type === 'month') { loadingType = 'updatingMonthLineItem'; }

  let params = {};

  newSubscriptionLineItems.forEach((item) => {
    params[item.id] = { id: item.id, variant_id: item.variant_id, quantity: item.quantity, _destroy: item._destroy };
  });

  let lineItemsType = 'lineItemsMonth';
  if (type === 'week') { lineItemsType = 'lineItemsWeek'; }

  return dispatch => {
    dispatch({ type: actionType.CART_UPDATE_VALUE, key: loadingType, value: true });
    dispatch({ type: actionType.CART_UPDATE_VALUE, key: lineItemsType, value: newSubscriptionLineItems.filter((i) => ["false", undefined].includes(i._destroy) ) });

    subscriptionService.updateLineItems(type, params)
      .then(
        response => {
          if(response.errors || response.success == false) {
            window.location.reload();
            dispatch({ type: actionType.CART_UPDATE_FAIL });
          }

          dispatch({ type: actionType.CART_UPDATE_VALUE, key: lineItemsType, value: response.line_items });
          dispatch({ type: actionType.CART_UPDATE_VALUE, key: loadingType, value: false });
        },
        error => {
          if (error && errors) {
            dispatch({ type: actionType.CART_UPDATE_VALUE, key: loadingType, value: false });
          }
        }
      );
  }
}

const removeItem = (orderId, lineItems, itemId) => {
  // updating lineItems
  const newLineItems = Object.assign([], lineItems);
  const item = newLineItems.find(i => i.id === itemId);
  item.quantity = 0;

  let params = {};
  newLineItems.forEach((item) => {
    params[item.id] = { id: item.id, variant_id: item.variant_id, quantity: item.quantity };
  });

  const index = newLineItems.indexOf(item);
  const lineItem = newLineItems[index];

  return dispatch => {
    if (lineItem.subscriptionItemIds && lineItem.subscriptionItemIds.length > 0) {
      const { lineItemsWeek, lineItemsMonth } = reduxStore.getState().cart;

      if (lineItemsWeek && lineItemsWeek.length > 0) {
        const lineItemsWeekNotDeleted = lineItemsWeek.filter(i => !lineItem.subscriptionItemIds.includes(i.id));
        dispatch({ type: actionType.CART_UPDATE_VALUE, key: 'lineItemsWeek', value: lineItemsWeekNotDeleted });
      }

      if (lineItemsMonth && lineItemsMonth.length > 0) {
        const lineItemsMonthNotDeleted = lineItemsMonth.filter(i => !lineItem.subscriptionItemIds.includes(i.id));
        dispatch({ type: actionType.CART_UPDATE_VALUE, key: 'lineItemsMonth', value: lineItemsMonthNotDeleted });
      }

    }

    newLineItems.splice(index, 1);

    dispatch({ type: actionType.CART_REMOVE_ITEM, newLineItems });
    dispatch({ type: actionType.CART_UPDATING, update: true });

    orderService.updateLineItems(orderId, params)
      .then(
        response => {
          if(response.errors) {
            dispatch(failure(response));
          } else {
            dispatch(success(response));
          }

          dispatch({ type: actionType.CART_UPDATING, update: false });
        },
        error => {
          dispatch(errors(error.toString()));
          dispatch({ type: actionType.CART_UPDATING, update: false });
        }
      );
  }
}

const checkThreeMonth = value => ({ type: actionType.CART_SET_THREE_MONTH, value });

const addSubscriptionProduct = (params, remove_extra_bags = false) => {
  return dispatch => {
    dispatch({ type: actionType.CART_UPDATING, update: true });
    orderServiceRemote(dispatch, params, remove_extra_bags);
  };
}

const checkValidCouponCode = (code) => {
  return dispatch => {
    remoteCheckingValidCoupon(code, dispatch)
  }
}

const applyCouponCode = (order_id, coupon_code, authenticity_token) => {
  return dispatch => {
    remoteApplyCouponCode(order_id, coupon_code, dispatch, authenticity_token);
  }
}

export const cartActions = {
  initState,
  updateLineItems,
  removeItem,
  checkThreeMonth,
  addSubscriptionProduct,
  checkValidCouponCode,
  applyCouponCode,
  updateInputCode,
  updateSubscriptionLineItems
};
