import { constants } from '../constants';

const getInitialState = {
  file: undefined,
  verifying: false,
  showWebcam: false,
  captureWebcam: undefined,
  showAsSection: false,
  verifyStatus: undefined,
  id_first_name: '',
  id_last_name: '',
  idFirstNameError: undefined,
  idLastNameError: undefined,
  verificationError: undefined
}

let newStateAfterReduced = undefined;

export function verify(state = {}, action) {
  const { type, containerKey } = action;

  switch (type) {
    case constants.INIT_VARIABLES:
      return {
        ...state,
        ...getInitialState,
        ...action.payload
      };
    case constants.SET_FILE:
      return {
        ...state,
        file: action.file
      }
    case constants.UPDATE_VALUE:
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
