import React from 'react';

class WoofCreditCardComponent extends React.Component {
  componentDidMount = () => {
    ApplicationController.prototype.constructor($("[controller='FormController']"));
    FormController.prototype.constructor($("[controller='FormController']"));
  }

  render = () => {
    const {
      errors,
      number,
      expired,
      cvc,
      isSubmitting,
      onSubmitForm,
      onAddError,
      onClearError,
      onChangeFieldValue,
      successMessage
    } = this.props;

    const changeFieldValue = (key) => {
      const $tag = document.querySelectorAll(`[name="${ key }"]`)[0];

      if ($tag) {
        const value = $tag.value
        const field = key;
        onChangeFieldValue({ field, value })
      }
    }

    const cardSuccessCreated = (response) => {
      setTimeout(
        () => {
          $('#modalChangePaymentMethod').modal('hide');
        }, 2000
      )
      $('.js-lastDigitCreditCard').html(`*${ response.last_digits }`);
      $('.js-creditCardItem').html(response.credit_card_text);
    }

    const submitForm = (e) => {
      e.preventDefault();

      onClearError();

      if (number === '' || number === undefined) {
        onAddError("Credit Card Number can't be blank");
      } else if (expired === '' || expired === undefined) {
        onAddError("Expired date can't be blank");
      } else if (cvc === '' || cvc === undefined) {
        onAddError("CVC can't be blank");
      }

      if (number !== '' && number !== undefined && expired !== '' && expired !== undefined && cvc !== '' && cvc !== undefined) {
        onSubmitForm(cardSuccessCreated);
      }
    }

    return (
      <form className='form-checkout mt-4' controller='FormController' data-clear='disabled' onSubmit={ (e) => { submitForm(e) } }>
        { successMessage != null ? (<div className='alert alert-success'> { successMessage } </div>) : null }
        { errors.length > 0 ? (<div className='alert alert-danger'> { errors[0] } </div>) : null }

        <label htmlFor="card-number">New Payment Details</label>
        <div className="form-merge form-credit-card mb-4">
          <div className={ `form-group form-group-50 form-group-focus card-number ${ errors.length > 0 ? 'has-error-bar' : '' }` }>
            <input type="text" className='form-control' id='card-number' data-input-mask='9999-9999-9999-9999' placeholder="0000-0000-0000-0000" value={ number } name='number' onChange={ changeFieldValue.bind(this, 'number') } />
            <label htmlFor="card-number">Card Number</label>
          </div>
          <div className="form-group form-group-50 form-group-focus card-date">
            <input type="text" className='form-control' id='card-date' placeholder="00 / 00" data-input-mask='99 / 99' value={ expired } name='expired' onChange={ changeFieldValue.bind(this, 'expired') } />
            <label htmlFor="card-date">MM/YY</label>
          </div>
          <div className="form-group form-group-50 form-group-focus card-cvc">
            <input type="password" className='form-control' id='card-cvc' placeholder="000" data-input-mask="999" value={ cvc } name='cvc' onChange={ changeFieldValue.bind(this, 'cvc') } />
            <label htmlFor="card-cvc">CVC</label>
          </div>
        </div>

        <input type="submit" className={ `btn btn-primary btn-block ${ isSubmitting ? 'disabled' : '' }` } disabled={ isSubmitting } value={ isSubmitting ? 'SAVING ...' : 'SAVE' } />
      </form>
    );
  }
};

export default WoofCreditCardComponent;