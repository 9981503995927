import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import { orderActions } from './actions';

import WoofProductCatalogContainer from './containers/woof_product_catalog_container';
import AppVerifyAccount from '../app-verify-account';

export default class WoofProductCatalogs extends Component {
  render() {
    store.dispatch(orderActions.initState(this.props.viewType, this.props));

    return (
      <div>
        <Provider store={store}>
          <WoofProductCatalogContainer containerKey={ this.props.viewType }  />
        </Provider>
        <AppVerifyAccount verifying={ this.props.verifying } showAsSection="SHOW_AS_MODAL" />
      </div>
    );
  }
}

