import React from "react";
import { Link } from "react-router-dom";

class Customize extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitOnBags: false,
      selectedImage: 0,
      selectedColor: 'blue',
      productImages: [
        {
          blue: woof.images.EXAMPLE_BLUE,
          yellow: woof.images.EXAMPLE_YELLOW,
          dark: woof.images.EXAMPLE_DARK
        },
        {
          blue: woof.images.EXAMPLE_BLUE,
          yellow: woof.images.EXAMPLE_YELLOW,
          dark: woof.images.EXAMPLE_DARK
        },
        {
          blue: woof.images.EXAMPLE_BLUE,
          yellow: woof.images.EXAMPLE_YELLOW,
          dark: woof.images.EXAMPLE_DARK
        }
      ]
    };
  }

  componentDidMount() {
    if(this.state.productImages.length > 4) {
      ApplicationController.prototype.initSlickProductGallery();
    }
  }

  setWaitOnBags = (event) => {
    this.setState({ waitOnBags: event.currentTarget.checked })
  }

  setProductImage = (event) => {
    this.setState({ selectedImage: event.currentTarget.attributes[2].value })
  }

  setProductColor = (event) => {
    this.setState({ selectedColor: event.currentTarget.attributes.color.value })
  }

  render () {
    return (
      <React.Fragment>
        <section id="product-show">
          <div className="container">
            <div className="product-caption text-center">
              <h3 className='m-0'>It's Time To</h3>
              <h1>Walk n' Roll</h1>
            </div>

            <div className="row no-gutters">
              <div className="col-6 pr-5">
                <div className="product--thumb-img-gallery">
                  <img src={ this.state.productImages[this.state.selectedImage][this.state.selectedColor] } className='product-img-zoom img-fluid mb-4' />

                  <ul className={ `product-img-list list-unstyled js-slickProductGallery ${ this.state.productImages.length < 5 ? 'less-than-4' : '' }` }>
                    {
                      this.state.productImages.map((product, i) => {
                        return (
                          <li key={ i }>
                            <a href="#" className='background-image product-img-item' data-select={ i } style={{ backgroundImage: `url(${ product[this.state.selectedColor] })` }} onClick={ this.setProductImage }></a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="pull-right text-gray">
                  <small>
                    Only want the leash? <a href="#" className='text-blue'>Click here.</a>
                  </small>
                </div>
              </div>
              <div className="col-6 d-flex flex-column">
                <h3 className='product-title mb-0'>The Woof { this.state.waitOnBags ? 'Leash' : 'Pack'}</h3>
                <h5 className='product-price text-blue'>
                  <span className='text-line-through text-gray'>{ this.state.waitOnBags ? '' : '$35' }</span> { this.state.waitOnBags ? '$35' : '$25' } + Free Shipping
                </h5>

                <p className='text-gray product-details'>
                  48 Premium Woof Leash<br />
                  { this.state.waitOnBags ? '20' : '140' } Compostable "Business Bags"<br />
                  <span className={ this.state.waitOnBags ? 'd-none' : 'd-inline-block' }>New bags delivered every 2 months</span>
                </p>

                <div className="product-designs">
                  Choose A Leash Design

                  <ul className='product-designs__list list-unstyled'>
                    <li>
                      <a href="#" color='blue' className={ `bullet bullet-blue ${ this.state.selectedColor === 'blue' ? 'bullet-selected' : '' }` } onClick={ this.setProductColor }></a>
                    </li>
                    <li>
                      <a href="#" color='yellow' className={ `bullet bullet-yellow ${ this.state.selectedColor === 'yellow' ? 'bullet-selected' : '' }` } onClick={ this.setProductColor }></a>
                    </li>
                    <li>
                      <a href="#" color='dark' className={ `bullet bullet-dark ${ this.state.selectedColor === 'dark' ? 'bullet-selected' : '' }` } onClick={ this.setProductColor }></a>
                    </li>
                  </ul>
                </div>

                <div className="form-check checkbox-wrapper">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" value={ this.state.waitOnBags } onClick={ this.setWaitOnBags } />
                  <label className="form-check-label checkbox-label text-gray" htmlFor="exampleCheck1">
                    <span className='check-css'></span>
                    I'd rather wait on the bags
                  </label>
                </div>

                <div className='mt-auto'>
                  <Link to="/get_started/pack" className="btn btn-primary btn-block">Next Step</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Customize;