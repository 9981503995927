import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import { checkoutActions } from './actions';
import WoofCheckoutContainer from './containers/woof_checkout_container';

export default class WoofCheckout extends Component {
  render() {
    store.dispatch(checkoutActions.initState(this.props));

    return (
      <div>
        <Provider store={store}>
          <WoofCheckoutContainer ownProps={{ ...this.props }} />
        </Provider>
      </div>
    );
  }
}
