import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VerifyAccount from '../components/verify_account';
import { mainActions } from '../actions';

const VerifyAccountContainer = (payload) => {
  return <VerifyAccount { ...payload } />;
}
const mapStateToProps = (state, props) => ({ ...state.verify, containerKey: props.containerKey });
const mapDispatchToProps = dispatch => bindActionCreators({ ...mainActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccountContainer);
