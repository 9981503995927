import { creditCardConstants } from '../constants';

export function credit_card(state = {}, action) {
  const { type, containerKey } = action;

  switch (type) {
    case creditCardConstants.INIT_VARIABLES:
      return {
        ...state,
        errors: [],
        number: '',
        expired: '',
        cvc: '',
        isSubmitting: false,
        successMessage: null
      }
    case creditCardConstants.SUBMIT:
      return {
        ...state,
        isSubmitting: true,
        errors: []
      }
    case creditCardConstants.SUBMIT_SUCCESS:
      return {
        ...state,
        errors: [],
        number: '',
        expired: '',
        cvc: '',
        isSubmitting: false,
        successMessage: action.message
      }
    case creditCardConstants.SUBMIT_FAIL:
      return {
        ...state,
        isSubmitting: false
      }
    case creditCardConstants.ADD_ERROR:
      const newErrors = state.errors;
      newErrors.push(action.message);

      return {
        ...state,
        errors: newErrors
      }
    case creditCardConstants.CLEAR_ERROR:
      return {
        ...state,
        errors: []
      }
    case creditCardConstants.CHANGE_FIELD_VALUE:
      const newState = Object.assign({}, state);
      const { field, value } = action.payload;
      newState[field] = value;

      return {
        ...newState
      }
    case creditCardConstants.CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: null
      }
    default:
      return state
  }
}