import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import Customize from './components/Customize';
import Pack from './components/Pack';
import configureStore from './configure_store'

const store = configureStore();

class WoofGetStarted extends React.Component {
  render () {
    return (
      <Provider store={ store }>
        <BrowserRouter>
          <Switch>
            <Route path='/get_started/customize' render={ () => <Customize /> } />
            <Route path='/get_started/pack' render={ () => <Pack /> } />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default WoofGetStarted;
