import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

function create(params) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  };

  return fetch(Routes.spree_woof_recipients_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function update(id, params) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  };

  return fetch(`${ Routes.spree_woof_recipient_path({ id }) }.json`, requestOptions).then(handleResponse)
}

function deleteRecipient(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token }
  };

  return fetch(`${ Routes.spree_woof_recipient_path({ id, format: 'json', authenticity_token }) }.json`, requestOptions).then(handleResponse)
}

export const recipientService = {
  create,
  update,
  deleteRecipient
};
