import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WoofCartComponent from '../components/WoofCartComponent';
import { cartActions } from '../actions';

const WoofCartContainer = payload => <WoofCartComponent { ...payload } />;

const mapStateToProps = state => {
  return { ...state.cart }
};
const mapDispatchToProps = dispatch => bindActionCreators({ ...cartActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WoofCartContainer);
