import { userConstants } from '../constants';

export function checkout(state = {}, action) {
  switch (action.type) {
    case userConstants.INIT_VARIABLE:
      return {
        ...state,
        ...action.payload,
        shippingInformationErrors: [],
        paymentInformationErrors: [],
        recipientInformationErrors: [],
        signupErrors: [],
        placingOrder: false,
        addingShippingInfo: false,
        showSigninForm: false,
        editMyCreditCard: false,
        submittingInfo: false
      };
    case userConstants.UPDATE_PAYMENT_INFORMATION_ERRORS:
      const errorPayment = state.paymentInformationErrors.find(i => i.name === action.name);

      if (error) {
        error.message = action.message;
      } else {
        state.paymentInformationErrors.push({ name: action.name, message: action.message });
      }

      const newPaymentInformationErrors = Object.assign([], state.paymentInformationErrors);

      return {
        ...state,
        paymentInformationErrors: newPaymentInformationErrors,
        placingOrder: false
      }
    case userConstants.CLEAR_PAYMENT_INFORMATION_ERRORS:
      const updateError = state.paymentInformationErrors.find(i => i.name === action.name);

      if (updateError) {
        const index = state.paymentInformationErrors.indexOf(updateError);
        state.paymentInformationErrors.splice(index, 1);
      }

      const paymentInformationErrors = Object.assign([], state.paymentInformationErrors);

      return {
        ...state,
        ...paymentInformationErrors
      }
    case userConstants.UPDATE_SHIPPING_INFORMATION_ERRORS:
      const error = state.shippingInformationErrors.find(i => i.name === action.name);

      if (error) {
        error.message = action.message;
      } else {
        state.shippingInformationErrors.push({ name: action.name, message: action.message });
      }

      const newShippingInformationErrors = Object.assign([], state.shippingInformationErrors);

      return {
        ...state,
        shippingInformationErrors: newShippingInformationErrors,
        addingShippingInfo: false,
        submittingInfo: false
      }
    case userConstants.UPDATE_RECIPIENT_INFORMATION_ERRORS:
      const recipientInformationError = state.recipientInformationErrors.find(i => i.name === action.name);

      if (recipientInformationError) {
        recipientInformationError.message = action.message;
      } else {
        state.recipientInformationErrors.push({ name: action.name, message: action.message });
      }

      const newRecipientInformationErrors = Object.assign([], state.shippingInformationErrors);

      return {
        ...state,
        shippingInformationErrors: newRecipientInformationErrors,
        submittingInfo: false
      }
    case userConstants.SUCCESS_UPDATE_RECIPIENT_INFORMATION:
      const recipientInformation = Object.assign({}, state.recipientInformation);

      const optionRecipients = () => {
        if(state.recipients.find(i => i.id === action.response.recipient.id)) {
          return state.recipients;
        } else {
          return [...state.recipients, action.response.recipient];
        }
      }

      return {
        ...state,
        submittingInfo: false,
        recipientInformationErrors: [],
        recipient: action.response.recipient,
        recipients: optionRecipients(),
        shipping_methods: action.response.shipping_methods
      }
    case userConstants.CLEAR_SHIPPING_INFORMATION_ERRORS:
      const shippingUpdateError = state.shippingInformationErrors.find(i => i.name === action.name)

      if (updateError) {
        const index = state.shippingInformationErrors.indexOf(updateError);
        state.shippingInformationErrors.splice(index, 1);
      }

      const shippingInformationErrors = Object.assign([], state.shippingInformationErrors);

      return {
        ...state,
        ...shippingInformationErrors
      }
    case userConstants.CLEAR_RECIPIENT_INFORMATION_ERRORS:
      return {
        ...state,
        recipientInformationErrors: []
      }
    case userConstants.SUCCESS_UPDATE_SHIPPING_INFORMATION:
      const shippingInformation = Object.assign({}, state.shippingInformation);
      shippingInformation.id = action.response.bill_address.id

      return {
        ...state,
        shippingInformation,
        shipAddressId: action.response.ship_address.id,
        addingShippingInfo: false,
        submittingInfo: false,
        shippingInformationErrors: [],
        braintreePaymentMethod: (action.response.braintree_payment_method || {}),
        billAddressId: action.response.bill_address.id
      }
    case userConstants.ADD_INFORMATION_ERRORS:
      state[action.key].push({ name: action.name, message: action.message });
      const infoState = Object.assign({}, state);

      return {
        ...state,
        ...infoState,
        addError: true
      };
    case userConstants.CLEAR_INFORMATION_ERRORS:
      state[action.key] = [];

      return {
        ...state,
        addError: false
      }
    case userConstants.ADDING_SHIPPING_INFO:
      return {
        ...state,
        addingShippingInfo: true,
        addingShippingInfoTrigger: 'true'
      }
    case userConstants.SUBMITTING_INFO:
      return {
        ...state,
        submittingInfo: true
      }
    case userConstants.PLACING_ORDER:
      return {
        ...state,
        placingOrder: true
      }
    case userConstants.SUCCESS_UPDATE_PAYMENT_INFORMATION:
      return {
        ...state,
        placingOrder: false
      }
    case userConstants.STOP_PLACING_ORDER:
      return {
        ...state,
        placingOrder: false
      }
    case userConstants.UPDATE_VALUE:
      if (action.key === 'showSigninForm') {
        state['signupErrors'] = [];

        return {
          ...state,
          addError: false,
          [action.key]: action.value
        }
      } else {
        return {
          ...state,
          [action.key]: action.value
        }
      }
    case userConstants.CHECKOUT_PASSWORD_TO_FALSE:
      return {
        ...state,
        confirmationToken: null,
        current_user_first_name: action.first_name,
        current_user_last_name: action.last_name,
        current_user_phone_number: action.phone_number
      }
    default:
      return state
  }
}
