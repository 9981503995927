import { orderConstants } from '../constants';

const defaultRecipientAttributes = (city) => ({
  createNew: false,
  needSpecificForm: false,
  selectedRecipient: undefined,
  first_name: '',
  last_name: '',
  phone_number: '',
  city: city,
  cashpower_meter_num: '',
  confirm_cashpower_meter_num: '',
  phone_carrier_id: ''
});

const checkNeedSpecificForm = (state, containerKey, recipient) => {
  const currentState = state[containerKey];

  switch (currentState.product.product_type) {
    case 'cash_power':
      if (recipient && !recipient.cashpower_meter_num) {
        return true
      }

      return false;
    default:
      return false;
  }
}

const initSelecteddMainProduct = (payload) => {
  if (!payload.mainProducts) { return null };
  if (payload.mainProduct.variant_keys && payload.mainProduct.variant_keys.color.length > 0) {
    return payload.mainProducts.find(i => i.woof_unique_id.includes(payload.mainProduct.variant_keys.color[0].toLowerCase()));
  }

  return payload.mainProducts[0];
}

export function order(state = {}, action) {
  const { type, containerKey } = action;

  switch (type) {
    case orderConstants.ORDER_ADD_REQUEST:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          requesting: !action.payload.subscription_line_item,
          requestingSubscription: action.payload.subscription_line_item ? true : false
        }
      };
    case orderConstants.ORDER_ADD_SUCCESS:
      let orderRecipientInformation = { ...state[containerKey].recipientInformation };
      let orderRecipients = [...state[containerKey].recipients];

      if (orderRecipientInformation && orderRecipientInformation.selectedRecipient) {
        orderRecipientInformation.selectedRecipient.phone_carrier_id = orderRecipientInformation.selectedRecipient.phone_carrier_id || orderRecipientInformation.phone_carrier_id;
      }

      if (orderRecipients && orderRecipientInformation && orderRecipientInformation.selectedRecipient) {
        const recipient = orderRecipients.find(i => parseInt(i.id) === parseInt(orderRecipientInformation.selectedRecipient.id))
        recipient.phone_carrier_id = orderRecipientInformation.selectedRecipient.phone_carrier_id;
      }

      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          order: action.order.order,
          requesting: false,
          requestingSubscription: false,
          orderAdded: true,
          recipientInformation: orderRecipientInformation,
          recipients: orderRecipients
        }
      };
    case orderConstants.ORDER_HAS_ADDED:

      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          orderAdded: false
        }
      }
    case orderConstants.ORDER_ADD_FAILURE:
      const { errors } = action.user;

      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          errors,
          requesting: false,
          requestingSubscription: false
        }
      };
    case orderConstants.ORDER_ADD_ERROR:
      const addErrors = { fullMessageError: action.error };

      if (action.error === "Recipient has reached the limit from unverified user.") {
        return {
          ...state,
          [containerKey]: {
            ...state[containerKey],
            showVerifyUserSection: true,
            requesting: false,
            requestingSubscription: false
          }
        };
      } else {
        return {
          ...state,
          [containerKey]: {
            ...state[containerKey],
            errorMessages: addErrors,
            requesting: false,
            requestingSubscription: false
          }
        };
      }
    case orderConstants.ORDER_INIT_VARIABLES:
      const initialState = {};
      const { payload } = action;

      initialState[action.payload.viewType] = {
        ...payload,
        selectedImage: 0,
        requesting: false,
        requestingSubscription: false,
        orderAdded: false,
        selectedVariant: payload.variants.sort((a,b) => parseInt(a.price) - parseInt(b.price))[0],
        errorMessages: {},
        submittingNewRecipient: false,
        showVerifyUserSection: false,
        recipientInformation: {
          ...defaultRecipientAttributes(payload.cities[0]),
          createNew: payload.showFormNewRecipient
        }
      };

      return {
        ...state,
        ...initialState
      };
    case orderConstants.ORDER_SET_SELECTED_PRODUCT:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          selectedVariant: action.product
        }
      };
    case orderConstants.ORDER_SET_SELECTED_IMAGE:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          selectedImage: action.index
        }
      };
    case orderConstants.ORDER_SET_USE_SUBSCRIPTION:
      if (!state[containerKey].selectedSubscriptionProduct) {
        return {
          ...state,
          [containerKey]: {
            ...state[containerKey],
            useSubscription: action.value,
            selectedSubscriptionProduct: state.subscriptionProducts[0]
          }
        }
      } else {
        return {
          ...state,
          [containerKey]: {
            ...state[containerKey],
            useSubscription: action.value
          }
        }
      }
    case orderConstants.ORDER_SET_SELETED_RECIPIENT:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          errorMessages: {},
          recipientInformation: {
            ...defaultRecipientAttributes(state[containerKey].cities[0]),
            selectedRecipient: action.recipient,
            needSpecificForm: checkNeedSpecificForm(state, containerKey, action.recipient)
          }
        }
      };
    case orderConstants.ORDER_SET_PHONE_CARRIER:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          recipientInformation: {
            ...defaultRecipientAttributes(state[containerKey].cities[0]),
            selectedRecipient: {
              ...state[containerKey].recipientInformation.selectedRecipient,
              phone_carrier_id: action.value
            }
          }
        }
      };
    case orderConstants.ORDER_SET_FIELD_NEW_RECIPIENT:
      const fieldRecipientInformation = {
        ...state[containerKey].recipientInformation,
        [action.key]: action.value
      }

      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          recipientInformation: fieldRecipientInformation
        }
      };
    case orderConstants.ORDER_SET_ADD_NEW_RECIPIENT:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          errorMessages: {},
          recipientInformation: {
            ...defaultRecipientAttributes(state[containerKey].cities[0]),
            createNew: true,
            needSpecificForm: false
          }
        }
      };
    case orderConstants.ORDER_ADDED_RECIPIENT:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          recipients: action.response.recipients,
          submittingNewRecipient: false,
          recipientInformation: {
            ...defaultRecipientAttributes(state[containerKey].cities[0]),
            selectedRecipient: action.response.recipient,
            needSpecificForm: checkNeedSpecificForm(state, containerKey, action.response.recipient)
          }
        }
      };
    case orderConstants.ORDER_SET_PRODUCT_BY_OPTION_VALUE:
      let color = state[containerKey].selectedColor;
      let size = state[containerKey].selectedSize;
      let product = state[containerKey].selectedVariant;

      if (action.option === 'size') {
        size = action.value;
      } else if (action.option === 'color') {
        color = action.value;
      }

      product = getProductByOptionValue(state[containerKey].mainProducts, size, color, containerKey);

      if (containerKey !== 'ModalWoofCollar') {
        return {
          ...state,
          ['ModalWoofCollar']: {
            ...state['ModalWoofCollar'],
            selectedColor: color,
            selectedSize: (state['ModalWoofCollar'] && state['ModalWoofCollar'].mainProduct ? state['ModalWoofCollar'].mainProduct.variant_keys.size[0] : null)
          },
          [containerKey]: {
            ...state[containerKey],
            selectedColor: color,
            selectedSize: size,
            selectedVariant: product
          }
        };
      } else {
        return {
          ...state,
          [containerKey]: {
            ...state[containerKey],
            selectedColor: color,
            selectedSize: size,
            selectedVariant: product
          }
        };
      }
    case orderConstants.ORDER_SET_ERROR_MESSAGE:
      let newErrorMessages = {};

      if (action.errorAction === 'add') {
        newErrorMessages = { ...state[containerKey].errorMessages, ...action.errors };
      } else if (action.errorAction === 'reduce') {
        newErrorMessages = { ...state[containerKey].errorMessages };
        newErrorMessages = delete newErrorMessages[action.key];
      }

      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          submittingNewRecipient: false,
          errorMessages: newErrorMessages
        }
      };
    case orderConstants.ORDER_ADDING_RECIPIENT:
      return {
        ...state,
        [containerKey]: {
          ...state[containerKey],
          submittingNewRecipient: action.value
        }
      };
    default:
      return state
  }
}
