import React from "react";
import RecipientForm from '../../lib/components/RecipientForm'

const CashPower = (payload) => {
  const { instances, actions } = payload;
  const {
    selectedVariant,
    requesting,
    orderAdded,
    containerKey,
    product,
    variants,
    recipients,
    recipientInformation,
    cities,
    errorMessages,
    submittingNewRecipient,
    phoneCarriers,
    userSignedIn,
    fullMessageError,
    showVerifyUserSection,
    requestingSubscription
  } = instances;

  const {
    addMultipleProduct,
    onSetProduct,
    setSelectedRecipient,
    setAddNewRecipient,
    setFieldNewRecipient,
    onAddRecipient,
    setPhoneCarrierId
  } = actions;

  const onAddProductToCart = (subscription = false) => {
    const selectedRecipientId = recipientInformation.selectedRecipient ? recipientInformation.selectedRecipient.id : null;
    const phone_carrier_id = recipientInformation.selectedRecipient ? (recipientInformation.selectedRecipient.phone_carrier_id || recipientInformation.phone_carrier_id) : recipientInformation.phone_carrier_id;
    const productParams = {
      variant_id: selectedVariant.id,
      quantity: 1,
      recipient_id: selectedRecipientId,
      phone_carrier_id
    }

    if (subscription === true) {
      addMultipleProduct({
        ...productParams,
        subscription_line_item: {
          quantity: 1,
          subscribable_id: selectedVariant.id,
          interval_length: "1",
          interval_units: "month"
        }
      });
    } else {
      addMultipleProduct(productParams);
    }
  }

  const onSelectVariant = (e) => {
    const id = e.currentTarget.value;
    const variant = variants.find(i => i.id === parseInt(id));

    onSetProduct(variant);
  }

  const showVerifyModal = (e) => {
    $('#modalVerifyAccount').modal({backdrop: 'static', keyboard: false})
  }

  const showMessageLimit = recipientInformation && recipientInformation.selectedRecipient && recipientInformation.selectedRecipient.has_free_limit == false;

  return (
    <section id='product-recipient-form' className='card square bg-grey-2x no-borders'>
      <div>
        <h1 className='text-dark'>{ product.name }</h1>

        <div className='recipient-information mb-4'>
          <h4>1. Recipient Information</h4>

          <RecipientForm
            product={ product }
            recipients={ recipients }
            recipientInformation={ recipientInformation }
            cities={ cities }
            onSetSelectedRecipient={ setSelectedRecipient }
            containerKey={ containerKey }
            setAddNewRecipient={ setAddNewRecipient }
            errorMessages={ errorMessages }
            setFieldNewRecipient={ setFieldNewRecipient }
            onAddRecipient={ onAddRecipient }
            submittingNewRecipient={ submittingNewRecipient }
            phoneCarriers={ phoneCarriers }
            setPhoneCarrierId={ setPhoneCarrierId }
            userSignedIn={ userSignedIn } />
        </div>

        <div className='select-amount mb-3'>
          <h4>2. Select Amount</h4>

          <div className='form-checkout'>
            <div className="row">
              <div className="col-12">
                <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0` }>
                  <select className='form-control' id='variant-id' name='variant_id' value={ selectedVariant.id } onChange={ (e) => { onSelectVariant(e) } }>
                    { variants.sort((a,b) => parseInt(a.price) - parseInt(b.price)).map(i => <option key={ i.id } value={ i.id }>{ i.price_string } ({ i.short_description })</option>) }
                  </select>
                  <label htmlFor="variant-id">Select Amount</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          showVerifyUserSection || showMessageLimit ?
            <div>
              <div className="alert alert-info primary-color">
                This order exceeds your pre-verification spending limit. To proceed with this transaction please <a href='#' className='js-notJump text-warning text-underline text-primary-hover' onClick={ showVerifyModal }>verify your account</a>.
              </div>

              <div className='text-right'>
                <a href='#' className={ `js-notJump btn-submit btn btn-cancel-gray` } onClick={ showVerifyModal }>Verify Account</a>
              </div>
            </div> :
            <div className='text-right d-flex submit-button mt-5'>
              <a href='#' className={ `js-notJump btn-submit w-50 btn btn-primary buy-once-btn ${ requesting ? 'disabled' : '' }` } onClick={ () => { onAddProductToCart() }}>
                { orderAdded && requesting == true ? 'Added to Cart' : 'Add To Cart' }
              </a>
              {/* <span className='d-inline-block'></span> */}
              {/* <a href='#' className={ `js-notJump btn-submit w-50 btn btn-primary ${ requestingSubscription ? 'disabled' : '' }` } onClick={ () => { onAddProductToCart(true) }}>
                { orderAdded && requestingSubscription == true ? 'Added to Cart' : 'Send Every Month' }
              </a> */}
            </div>
        }
      </div>
    </section>
  );
}

export default CashPower;
