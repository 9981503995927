const checkoutCreateAccount = (email) => {
  if (typeof gtag == "undefined") { return null };

  gtag('event', 'set_checkout_option', {
    'checkout_step': 1,
    'checkout_option': 'create account',
    'value': email
  });
}

const checkoutPersonalDetail = () => {
  if (typeof gtag == "undefined") { return null };

  gtag('event', 'set_checkout_option', {
    'checkout_step': 2,
    'checkout_option': 'add personal details'
  });
}

const checkoutAddRecipient = (recipientName) => {
  if (typeof gtag == "undefined") { return null };

  let recipientParams = {
    'checkout_step': 3,
    'checkout_option': 'add new recipient'
  }

  if (recipientName) {
    recipientParams["value"] = recipientName;
    recipientParams['checkout_option'] = 'add recipient';
  }

  gtag('event', 'set_checkout_option', recipientParams);
}

const checkoutSubmitDeliveryMethod = (deliveryMethod) => {
  if (typeof gtag == "undefined") { return null };

  gtag('event', 'set_checkout_option', {
    'checkout_step': 4,
    'checkout_option': 'select delivery method',
    'value': deliveryMethod
  });
}

const checkoutSubmitPayment = (paymentType) => {
  if (typeof gtag == "undefined") { return null };

  gtag('event', 'set_checkout_option', {
    'checkout_step': 5,
    'checkout_option': 'complete payment',
    'value': paymentType
  });
}

const checkoutSuccess = (orderDetails) => {
  if (typeof gtag == "undefined") { return null };

  let purchaseParams = {
    'affiliation': "Google online store"
  }

  gtag('event', 'purchase', { ...purchaseParams, orderDetails });
}

export const GTagRequest = {
  checkoutCreateAccount,
  checkoutPersonalDetail,
  checkoutAddRecipient,
  checkoutSubmitDeliveryMethod,
  checkoutSubmitPayment,
  checkoutSuccess
};
