import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

function addMoreQuantity(variant_id, quantity = 1) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ variant_id, quantity, authenticity_token })
  };

  return fetch(Routes.spree_populate_orders_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function checkValidCoupon(code) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
  };

  return fetch(Routes.spree_checking_woof_coupon_path({ id: code, format: 'json', authenticity_token }), requestOptions).then(handleResponse)
}

function applyCouponCode(order_id, coupon_code, authenticity_token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ authenticity_token })
  };

  return fetch(Routes.spree_order_coupon_codes_path({ format: 'json', order_id, coupon_code }), requestOptions).then(handleResponse)
}

export const cartService = {
  addMoreQuantity,
  checkValidCoupon,
  applyCouponCode
};
