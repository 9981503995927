import React from "react";
import _ from 'lodash';

import CashPower from './CashPower';
import PhoneInstant from './PhoneInstant';

class ProductCatalogs extends React.Component {
  componentDidMount() {
    ApplicationController.prototype.initHrefNotJump();
    ApplicationController.prototype.initLazyLoad();

    FormController.prototype.initialize();
    FormController.prototype.listenForEvents();
  }

  componentDidUpdate(oldProps) {
    FormController.prototype.initialize();
    FormController.prototype.listenForEvents();
  }

  showSubscriptionReactiveModal = () => {
    $('#modalPackEditItems').modal('show');
  }

  /**
  * @orders:
  * [
  *   { variant_id: 1, quantity: 1 },
  *   { variant_id: 2, quantity: 1 }
  * ]
  **/
  addMultipleProduct = (orders) => {
    const { instances, actions } = this.props;
    const { containerKey, viewType, errorMessages, recipientInformation } = instances;
    const { addToCart } = actions;

    this.validate();

    addToCart(containerKey, orders);
  }

  validate = () => {
    const { instances, actions } = this.props;
    const { containerKey, product, recipientInformation, errorMessages } = instances;
    const { setErrorMessage } = actions;

    setErrorMessage(containerKey, 'clear');

    if (['cash_power', 'phone_credit'].includes(product.product_type) && !recipientInformation.selectedRecipient) {
      setErrorMessage(containerKey, 'add', { recipient_id: "is required" });
    }

    if (['phone_credit'].includes(product.product_type) && ((recipientInformation.selectedRecipient && !recipientInformation.selectedRecipient.phone_carrier_id) && !recipientInformation.phone_carrier_id)) {
      setErrorMessage(containerKey, 'add', { phone_carrier_id: "is required" });
    }
  }

  onAddRecipient = () => {
    const { instances, actions } = this.props;
    const { containerKey, errorMessages, recipientInformation } = instances;
    const { addRecipient } = actions;

    this.validateNewRecipient()

    addRecipient(containerKey);
  }

  validateNewRecipient = () => {
    const { instances, actions } = this.props;
    const { containerKey, recipientInformation, errorMessages, product } = instances;
    const { setErrorMessage } = actions;
    let errors = {}
    let requiredAttr = null;

    setErrorMessage(containerKey, 'clear');

    if (product.product_type === 'cash_power') {
      if (recipientInformation.selectedRecipient) {
        requiredAttr = ['cashpower_meter_num', 'confirm_cashpower_meter_num'];
      } else {
        requiredAttr = ['first_name', 'last_name', 'phone_number', 'cashpower_meter_num', 'confirm_cashpower_meter_num'];
      }
    } else if (product.product_type === 'phone_credit') {
      if (recipientInformation.selectedRecipient) {
        requiredAttr = ['phone_carrier_id'];
      } else {
        requiredAttr = ['first_name', 'last_name', 'phone_number', 'phone_carrier_id'];
      }
    }

    requiredAttr.forEach((i) => {
      if (recipientInformation[i] === '') {
        errors[i] = 'is required';
      }
    });

    if (product.product_type === 'cash_power' && recipientInformation['cashpower_meter_num'] && recipientInformation['cashpower_meter_num'] !== recipientInformation['confirm_cashpower_meter_num']) {
      errors['confirm_cashpower_meter_num'] = "is not match";
    }

    if (!_.isEmpty(errors)) {
      setErrorMessage(containerKey, 'add', errors);
    }
  }

  setProduct = (product) => {
    const { instances, actions } = this.props;
    const { containerKey } = instances;
    const { setSelectedProduct } = actions;

    setSelectedProduct(containerKey, product);
  }

  setProductImage = (i) => {
    this.props.actions.setSelectedImage(this.props.instances.containerKey, i);
  }

  productForImage = () => {
    const { instances, actions } = this.props;

    if (instances.mainProduct) {
      return instances.selectedProduct;
    } else if (!instances.mainProduct && instances.bagProducts) {
      return instances.selectedBagProduct;
    }
  }

  setProductImageByControl = (control) => {
    const { instances, actions } = this.props;
    const { containerKey, selectedImage } = instances;
    const { image_attributes } = this.productForImage();
    const { setSelectedImage } = actions;

    const images = image_attributes;

    if (control === 'next') {
      const nextIndex = selectedImage + 1;
      const index = images.length <= nextIndex ? 0 : nextIndex;
      setSelectedImage(containerKey, index);
    } else {
      const prevIndex = selectedImage - 1;
      const index = 0 > prevIndex ? 5 : prevIndex;
      setSelectedImage(containerKey, index);
    }
  }

  render () {

    const {
      actions,
      instances
    } = this.props

    const {
      viewType,
      selectedVariant
    } = instances;

    if (viewType === 'CashPower') {
      return <CashPower
                actions={{
                  ...actions,
                  addMultipleProduct: this.addMultipleProduct,
                  onSetProduct: this.setProduct,
                  onAddRecipient: this.onAddRecipient
                }}
                instances={{ ...instances }}
              />;
    } else if (viewType === 'PhoneInstantView') {
      return <PhoneInstant
                actions={{
                  ...actions,
                  addMultipleProduct: this.addMultipleProduct,
                  onSetProduct: this.setProduct,
                  onAddRecipient: this.onAddRecipient
                }}
                instances={{ ...instances }}
              />;
    }
  }
}

export default ProductCatalogs;
