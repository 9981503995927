export const cartConstants = {
  CART_INIT_VARIABLES: 'CART_INIT_VARIABLES',
  CART_UPDATE_LINE_ITEMS: 'CART_UPDATE_LINE_ITEMS',
  CART_UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
  CART_UPDATE_FAIL: 'CART_UPDATE_FAIL',
  CART_UPDATE_ERROR: 'CART_UPDATE_ERROR',
  CART_REMOVE_ITEM: 'CART_REMOVE_ITEM',
  CART_SET_THREE_MONTH: 'CART_SET_THREE_MONTH',
  ADD_SUBSCRIPTION_RPDOUCT: 'ADD_SUBSCRIPTION_RPDOUCT',
  CART_SET_VALID_COUPON_CODE: 'CART_VALID_COUPON_CODE',
  CART_APPLY_COUPON_CODE: 'CART_APPLY_COUPON_CODE',
  CART_UPDATING: 'CART_UPDATING',
  CART_UPDATE_VALUE: 'CART_UPDATE_VALUE'
};
