export const userConstants = {
  INIT_VARIABLE: 'INIT_VARIABLE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  REGISTER_ERROR: 'USERS_REGISTER_ERROR',
  REGISTER_SET_EMAIL: 'USERS_REGISTER_SET_EMAIL',
  REGISTER_PASSWORD_TO_FALSE: 'REGISTER_PASSWORD_TO_FALSE',
  CHECKOUT_PASSWORD_TO_FALSE: 'CHECKOUT_PASSWORD_TO_FALSE',

  UPDATE_SHIPPING_INFORMATION: 'UPDATE_SHIPPING_INFORMATION',
  UPDATE_SHIPPING_INFORMATION_ERRORS: 'UPDATE_SHIPPING_INFORMATION_ERRORS',
  CLEAR_SHIPPING_INFORMATION_ERRORS: 'CLEAR_SHIPPING_INFORMATION_ERRORS',
  SUCCESS_UPDATE_SHIPPING_INFORMATION: 'SUCCESS_UPDATE_SHIPPING_INFORMATION',

  UPDATE_PAYMENT_INFORMATION: 'UPDATE_PAYMENT_INFORMATION',
  UPDATE_PAYMENT_INFORMATION_ERRORS: 'UPDATE_PAYMENT_INFORMATION_ERRORS',
  CLEAR_PAYMENT_INFORMATION_ERRORS: 'CLEAR_PAYMENT_INFORMATION_ERRORS',

  ADD_INFORMATION_ERRORS: 'ADD_INFORMATION_ERRORS',
  CLEAR_INFORMATION_ERRORS: 'CLEAR_INFORMATION_ERRORS',
  SUCCESS_UPDATE_PAYMENT_INFORMATION: 'SUCCESS_UPDATE_PAYMENT_INFORMATION',

  ADDING_SHIPPING_INFO: 'ADDING_SHIPPING_INFO',
  PLACING_ORDER: 'PLACING_ORDER',

  STOP_PLACING_ORDER: 'STOP_PLACING_ORDER',
  UPDATE_VALUE: 'UPDATE_VALUE',

  SUBMITTING_INFO: 'SUBMITTING_INFO',
  UPDATE_RECIPIENT_INFORMATION_ERRORS: 'UPDATE_RECIPIENT_INFORMATION_ERRORS',
  CLEAR_RECIPIENT_INFORMATION_ERRORS: 'CLEAR_RECIPIENT_INFORMATION_ERRORS',
  SUCCESS_UPDATE_RECIPIENT_INFORMATION: 'SUCCESS_UPDATE_RECIPIENT_INFORMATION',
};
