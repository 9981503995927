import React from "react";
import Webcam from "react-webcam";
import _ from 'lodash';

const VerifyAccount = (payload) => {
  /**
  * Instances
  **/
  const {
    file,
    verifying,
    showWebcam,
    captureWebcam,
    showAsSection,
    id_first_name,
    id_last_name,
    idFirstNameError,
    idLastNameError,
    verificationError
  } = payload;

  /**
  * Actions
  **/
  const {
    setFile,
    verifyDocument,
    updateProp,
    checkingVerification,
    verifyStatus
  } = payload;

  const intervalCheckingVerification = () => {
    window.checkVerifying = window.checkVerifying || undefined;

    if (verifying === true) {
      window.checkVerifying = setInterval(() => { checkingVerification(); }, 5000);
    } else if (verifying === false && window.checkVerifying) {
      clearInterval(window.checkVerifying);
      window.checkVerifying = undefined;
    }
  }

  intervalCheckingVerification();

  const turnOffWebacam = () => {
    updateProp('showWebcam', false);
    updateProp('file', undefined);
  }

  const setFileFromWebcam = (dataurl, filename) => {
    let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  const webcamRef = React.useRef(null);
  const takeWencamCapture = React.useCallback(
    () => {
      setFile(setFileFromWebcam(webcamRef.current.getScreenshot(), 'webcam-capture'));
      updateProp('captureWebcam', webcamRef.current.getScreenshot())
    },
    [webcamRef]
  );

  const successVerify = () => {
    window.location.reload();
  }

  const renderMainControl = () => {
    if(showWebcam) {
      let widthLength = 0;

      try {
        widthLength = document.getElementById('modalVerifyAccount').getElementsByClassName('modal-body')[0].offsetWidth - 200;
      } catch(err) {
        widthLength = document.getElementsByClassName('verify-account')[0].offsetWidth - 75;
      }

      const videoConstraints = {
        width: widthLength,
        height: 350,
        facingMode: "user"
      };

      if (file) {
        return (
          <div>
            <div className='webcam-container'>
              <img src={ captureWebcam } />
            </div>
            <div className='d-flex control-webcamp'>
              <button className='btn btn-danger btn-block m-0 mt-2' onClick={ (e) => { updateProp('file', undefined); } }>Cancel</button>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className='webcam-container'>
              <Webcam
                audio={ false }
                imageSmoothing={ true }
                mirrored={ true }
                screenshotQuality={ 1 }
                ref={ webcamRef }
                screenshotFormat="image/jpeg"
                videoConstraints={ videoConstraints }
                />
            </div>

            <div className='d-flex control-webcamp'>
              <button className='btn btn-primary btn-block m-0 mr-1' onClick={ takeWencamCapture }>Take a picture</button>
              <button className='btn btn-danger btn-block m-0' onClick={ (e) => { updateProp('showWebcam', false); } }>Cancel</button>
            </div>
          </div>
        );
      }
    } else if (verifying) {
      return (
        <div className='loading-wrapper'>
          {/**
            <i className='fa fa-sync-alt rotating text-primary'></i>

            **/}
          <h1>Verifying Your Documents</h1>
          <p>
            Your document is being verified. Thanks for your patience.
          </p>
        </div>
      );
    } else {
      if (verifyStatus === 'failed') {
        return (
          <div className='loading-wrapper'>
            <i className='fa fa-times-circle text-danger'></i>
            <h1>Verification Failed { verificationError }</h1>
            <p>
              Please contact us by email (admin@terangas.com) or <br /> WhatsApp <a href='whatsapp://send?abid=+220-222-5000' className='text-blue'>+220-222-5000</a>) to be manually verified. Thanks!
            </p>
          </div>
        );
      } else if (verifyStatus === 'success') {
        return (
          <div className='loading-wrapper'>
            <i className='fa fa-check-circle text-success'></i>
            <h1>Verification Complete</h1>
            <p>
              Thank you for your patience.
            </p>
          </div>
        );
      } else {
        return (
          <div className='submit-wrapper'>

            <h5 className='text-left mb-3'>Step 1 - Enter Name (as it appears on your ID and credit card)</h5>

            <div className='form-checkout mb-4'>
              <div className="row">
                <div className="col-6">
                  <div className={ `form-group form-group-50 form-group-focus ${ idFirstNameError ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='id_first_name' name='id_first_name' value={ id_first_name } onChange={ (e) => { updateProp('id_first_name', e.currentTarget.value) }} />
                    <label htmlFor="id_first_name"><i>First Name</i></label>
                    <span className={ `hint text-danger ${ idFirstNameError ? 'd-inline-block' : 'd-none' }` }>{ idFirstNameError }</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className={ `form-group form-group-50 form-group-focus ${ idLastNameError ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='id_last_name' name='id_last_name' value={ id_last_name } onChange={ (e) => { updateProp('id_last_name', e.currentTarget.value) }} />
                    <label htmlFor="id_last_name"><i>Last Name</i></label>
                    <span className={ `hint text-danger ${ idLastNameError ? 'd-inline-block' : 'd-none' }` }>{ idLastNameError }</span>
                  </div>
                </div>
              </div>
            </div>

            <h5 className='text-left mb-3'>Step 2 - Document Verification</h5>

            <button className='btn btn-primary btn-block btn-lg square' onClick={ e => document.getElementsByClassName("js-uploadDocument")[0].click() }>Upload A Photo of Your ID or Driver's License</button>
            <h5 className='text-gray text-center text-size-lg-3x'>(or)</h5>
            <button className='btn btn-primary btn-block btn-lg square' onClick={ e => { updateProp('showWebcam', true); updateProp('file', undefined) } }>Take a Photo of Your ID With Your Webcam</button>
          </div>
        );
      }
    }
  }

  const renderSubmitButton = () => {
    if (verifying) {
      return (
        <input
          type='submit'
          value='VERIFYING ACCOUNT ...'
          className='btn btn-cancel-gray btn-lg btn-block'
          disabled={ true } />
      );
    } else {
      if (verifyStatus === 'failed') {
        return (<a href='#' onClick={ () => { updateProp('verifyStatus', undefined); updateProp('file', undefined) }} className='js-notJump hidden btn btn-cancel-gray btn-lg btn-block'>TRY AGAIN</a>);
      } else if (verifyStatus === 'success') {
        return (<a href='#' className='js-notJump btn btn-cancel-gray btn-lg btn-block' onClick={ () => { successVerify() } }>CONTINUE</a>);
      } else {
        return (
          <input
            type='submit'
            value='VERIFY ACCOUNT & CONTINUE'
            className='btn btn-cancel-gray btn-lg btn-block'
            disabled={ !file || !id_first_name || !id_last_name } />
        );
      }
    }
  }

  const mainForm = () => {
    return (
      <div>
        <h1>CREDIT CARD VERIFICATION</h1>

        <p className='text-center text-gray mt-3'>
          To protect our users and platform from fraud we require credit card users to verify their account before increasing their spending limit.
        </p>

        <p className='text-center text-gray mb-5 mt-3'>
          To verify your account please send a photo of your ID by email to <a href='mailto:admin@terangas.com' className='text-blue'>admin@terangas.com</a> or WhatsApp (<a href='whatsapp://send?abid=+220-222-5000' className='text-blue'>+220-222-5000</a>).  Have questions? We would be happy to answer any questions!
        </p>

        { renderMainControl() }

        <div className='mt-5'>
          <form onSubmit={ (e) => { verifyDocument(e, file, id_first_name, id_last_name) } }>
            <input type='file' className='hidden js-uploadDocument' accept="image/x-png,image/gif,image/jpeg" onChange={ e => setFile(e.currentTarget.files[0]) } />
            { renderSubmitButton() }
            <p className='text-small-1x text-learn-fraud-protection mt-2'>For questions and assistance verifying your account please contact us at <a href='tel:+220-2225000'>+220-2225000</a> or <a href='mailto:admin@terangas.com'>admin@terangas.com</a>.</p>
          </form>
        </div>
      </div>
    );
  }

  return (
    showAsSection === 'SHOW_AS_SECTION' ?
      <div className='section-verify-account'>
        <div className='card verify-account square no-borders text-white'>
          { mainForm() }
        </div>
      </div>
      : <div className="modal fade section-verify-account modal-style-2" id="modalVerifyAccount" tabIndex="-1" role="dialog" aria-labelledby="modalVerifyAccountLabel" aria-hidden="true" controller='SubscriptionCalendarController' data-user-subscriptions="<%= spree_current_user.interval_subscriptions.to_json %>">
        <div className="modal-dialog modal-dialog-centered modal-lg-2x" role="document">
          <div className="modal-content bg-grey-3x">
            <div className="modal-body text-center">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ e => {  turnOffWebacam() } }>
                <span aria-hidden="true">&times;</span>
              </button>

              { mainForm() }
            </div>
          </div>
        </div>
      </div>
  )
}

export default VerifyAccount;
