import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

function register(user) {
  user.skip_phone_number = true;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({spree_user: user, authenticity_token })
  };

  return fetch(Routes.spree_spree_user_registration_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function login(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({spree_user: user, authenticity_token})
  };

  return fetch(Routes.spree_create_new_session_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function cratePassword(params, confirmation_token) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ ...params, confirmation_token, authenticity_token })
  };

  return fetch(Routes.spree_update_password_checkout_woof_account_index_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function uploadVerifyDocument(file, first_name, last_name) {
  const formData = new FormData();

  formData.append('document', file);
  formData.append('first_name', first_name);
  formData.append('last_name', last_name);
  formData.append('authenticity_token', authenticity_token);

  const requestOptions = {
    method: 'POST',
    headers: { 'X-CSRF-Token': authenticity_token },
    body: formData
  };

  return fetch(Routes.spree_verify_woof_account_index_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function checkingVerification() {
  const requestOptions = {
    method: 'GET',
    headers: { 'X-CSRF-Token': authenticity_token }
  };

  return fetch(Routes.spree_check_verifying_woof_account_index_path({ format: 'json', authenticity_token }), requestOptions).then(handleResponse);
}

export const userService = {
  register,
  login,
  cratePassword,
  uploadVerifyDocument,
  checkingVerification
};
