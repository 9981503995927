import React from 'react';

const WoofBillingAddressForm = (payload) => {
  const {
    address,
    zip_code,
    country_id,
    state_id,
    city,
    states,
    countries
  } = payload.addressInformation;

  const {
    errorAttributes,
    updateAddressAttribute,
    showErrorAttribute,
    fraudLabel
  } = payload;

  return (
    <div>
      <h5 className='text-dark mt-4'>
        Billing Address { fraudLabel === false ? '' : <span className='text-gray text-small-1x text-italic'> - this help us protect you from fraud.</span> }
      </h5>
      <div className="row">
        <div className="col-12">
          <div className={ `form-group form-group-50 form-group-focus ${ showErrorAttribute(errorAttributes, 'address') ? 'has-error-bar' : '' }` }>
            <input type="text" className='form-control' id='address' name='address' value={ address } onChange={ (e) => ( updateAddressAttribute('address', e.currentTarget.value))} />
            <label htmlFor="address">Street Address</label>
            <span className={ `hint text-danger ${ showErrorAttribute(errorAttributes, 'address') ? 'd-inline-block' : 'd-none' }` }>{ showErrorAttribute(errorAttributes, 'address') }</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className={ `form-group form-group-50 form-group-focus ${ showErrorAttribute(errorAttributes, 'city') ? 'has-error-bar' : '' }` }>
            <input type="text" className='form-control' id='city' name='city' value={ city } onChange={ (e) => ( updateAddressAttribute('city', e.currentTarget.value))} />
            <label htmlFor="city">City</label>
            <span className={ `hint text-danger ${ showErrorAttribute(errorAttributes, 'city') ? 'd-inline-block' : 'd-none' }` }>{ showErrorAttribute(errorAttributes, 'city') }</span>
          </div>
        </div>
        <div className="col-6">
          <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ showErrorAttribute(errorAttributes, 'country_id') ? 'has-error-bar' : '' }` }>
            <select className='form-control' id='country_id' name='country_id' value={ country_id } onChange={ (e) => ( updateAddressAttribute('country_id', e.currentTarget.value))}>
              <option value=''></option>
              { countries.map(country => <option key={ country.id } value={ country.id }>{ country.name }</option>) }
            </select>
            <label htmlFor="country_id">Country</label>
            <span className={ `hint text-danger ${ showErrorAttribute(errorAttributes, 'country_id') ? 'd-inline-block' : 'd-none' }` }>{ showErrorAttribute(errorAttributes, 'country_id') }</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ showErrorAttribute(errorAttributes, 'state_id') ? 'has-error-bar' : '' }` }>
            <select className='form-control' id='state_id' name='state_id' value={ state_id } onChange={ (e) => ( updateAddressAttribute('state_id', e.currentTarget.value))}>
              <option value=''></option>
              { states.map(state => <option key={ state.id } value={ state.id }>{ state.name }</option>) }
            </select>
            <label htmlFor="state_id">State or Province</label>
            <span className={ `hint text-danger ${ showErrorAttribute(errorAttributes, 'state_id') ? 'd-inline-block' : 'd-none' }` }>{ showErrorAttribute(errorAttributes, 'state_id') }</span>
          </div>
        </div>
        <div className="col-6">
          <div className={ `form-group form-group-50 form-group-focus ${ showErrorAttribute(errorAttributes, 'zip_code') ? 'has-error-bar' : '' }` }>
            <input type="text" className='form-control' id='zip_code' name='zip_code' value={ zip_code } onChange={ (e) => ( updateAddressAttribute('zip_code', e.currentTarget.value))} />
            <label htmlFor="zip_code">Zip or Postcode</label>
            <span className={ `hint text-danger ${ showErrorAttribute(errorAttributes, 'zip_code') ? 'd-inline-block' : 'd-none' }` }>{ showErrorAttribute(errorAttributes, 'zip_code') }</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WoofBillingAddressForm;