import { userConstants } from '../constants';
import { checkoutService } from '../../../services/checkout_service';
import { GTagRequest } from '../../../lib/GTag';

function updateShippingInformation(params, successCallback, failCallback) {
  const actionType = userConstants;

  return dispatch => {
    let anyError = false;

    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'firstname' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'address1' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'city' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'state_id' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'zipcode' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'phone' });
    dispatch({ type: actionType.CLEAR_SHIPPING_INFORMATION_ERRORS, name: 'shipping_method_id' });

    [['firstname', 'Full name'], ['address1', 'Address'], ['city', 'City'], ['state_id', 'State'], ['zipcode', 'Zip'], ['shipping_method_id', 'Shipping method']].forEach((i) => {
      if (params.order.bill_address_attributes[i[0]] === "" || params.order.bill_address_attributes[i[0]] === null) {
        dispatch({ type: actionType.UPDATE_SHIPPING_INFORMATION_ERRORS, name: i[0], message: `* required` });
        anyError = true
      }
    })

    if (!anyError) {
      checkoutService.update(params, 'address')
        .then(
          response => {
            if(response.errors) {
              failCallback(response);
              dispatch(failure(response));
            } else {
              successCallback(response);
              dispatch(success(response));

              GTagRequest.checkoutSubmitDeliveryMethod(response.shipments[0].selected_shipping_rate.name);
            }
          },
          error => {
            dispatch(errors(error.toString()));
          }
        );
    }
  };

  function failure(response) { return { type: actionType.UPDATE_SHIPPING_INFORMATION_ERRORS, name: 'fullMessageError', message: response.errors[0]}}
  function success(response) { return { type: actionType.SUCCESS_UPDATE_SHIPPING_INFORMATION, response }}
  function errors(error) { return { type: actionType.REGISTER_ERROR, error } };
}

function updateRecipientInfo(params, successCallback, failCallback, forSubscription) {
  const actionType = userConstants;

  return dispatch => {
    let anyError = false;

    dispatch({ type: actionType.CLEAR_RECIPIENT_INFORMATION_ERRORS });

    if (params.create_new_recipient === true) {
      [['first_name', 'First name'], ['last_name', 'Last name'], ['phone_number', 'Phone number'], ['city', 'City']].forEach((i) => {
        if (['', null, undefined].includes(params.recipient[i[0]])) {
          dispatch({ type: actionType.UPDATE_RECIPIENT_INFORMATION_ERRORS, name: i[0], message: `* required` });
          anyError = true
        }
      })
    } else {
      const _recipient_id = forSubscription ? 'subscription_recipient_id' : 'recipient_id';
      [[_recipient_id, 'Recipient']].forEach((i) => {
        if (['', null, undefined].includes(params.recipient[i[0]])) {
          dispatch({ type: actionType.UPDATE_RECIPIENT_INFORMATION_ERRORS, name: i[0], message: `* required` });
          anyError = true
        }
      })
    }

    if (!anyError && !params.confirmed) {
      dispatch({ type: actionType.UPDATE_RECIPIENT_INFORMATION_ERRORS, name: 'fullMessageError', message: `Please confirm recipient by check the checkbox.` });
      anyError = true
    }

    if (!anyError) {
      if (params.recipient.phone_number) {
        params.recipient.phone_number = params.recipient.phone_number.replace("+220 ", "");
      }

      checkoutService.addRecipient(params, forSubscription)
        .then(
          response => {
            if(response.errors) {
              failCallback(response);
              dispatch(failure(response));
            } else {
              GTagRequest.checkoutAddRecipient(response.recipient.full_name);

              successCallback(response);
              if (!forSubscription) {
                dispatch(success(response));
              } else {
                dispatch({ type: 'NOTHING', response });
              }
            }
          },
          error => {
            dispatch(errors(error.toString()));
          }
        );
    }
  };

  function failure(response) { return { type: actionType.UPDATE_RECIPIENT_INFORMATION_ERRORS, name: 'fullMessageError', message: response.errors[0] }};
  function success(response) { return { type: actionType.SUCCESS_UPDATE_RECIPIENT_INFORMATION, response }};
  function errors(error) { return { type: actionType.UPDATE_RECIPIENT_INFORMATION_ERRORS, name: 'fullMessageError', message: error }};
}

function validatePaymentInformation(params) {
  const actionType = userConstants;

  return dispatch => {
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'name_on_card' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'number' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'exp' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'cvc' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'address' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'zip_code' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'country_id' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'state_id' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'city' });
    dispatch({ type: actionType.CLEAR_PAYMENT_INFORMATION_ERRORS, name: 'fullMessageError' });

    [['name_on_card', 'Name on Card'], ['number', 'Number'], ['exp', 'Expiration Date'], ['cvc', 'CVC'], ['address', 'Address'], ['zip_code', 'Zip'], ['country_id', 'Country'], ['state_id', 'State'], ['city', 'City']].forEach((i) => {
      if (params[i[0]] === "" || params[i[0]] === null) {
        dispatch({ type: actionType.UPDATE_PAYMENT_INFORMATION_ERRORS, name: i[0], message: `${ i[1] } is required` });
      }
    })
  };
}

const addError = (key, name, message) => {
  return { type: userConstants.ADD_INFORMATION_ERRORS, key, name, message }
};
const clearError = (key) =>  ({ type: userConstants.CLEAR_INFORMATION_ERRORS, key });

function updatePaymentInformation(params, successCallback, failCallback) {
  const actionType = userConstants;

  return dispatch => {
    checkoutService.update(params, 'payment')
      .then(
        response => {
          if(response.errors) {
            failCallback(response);
            dispatch(errors(response.errors[0]))
          } else {
            if (response.self_children.length > 1) {
              successCallback(response);
              dispatch(errors(error.toString()))
            } else {
              checkoutService.update({}, 'confirm').
                then(
                  response => {
                    if (response.errors) {
                      dispatch(errors(response.errors[0]))
                    } else {
                      GTagRequest.checkoutSubmitPayment('CreditCard');
                      GTagRequest.checkoutSuccess({
                        "transaction_id": response.number,
                        "value": response.total,
                        "currency": response.currency,
                        "tax": response.tax_total,
                        "shipping": response.ship_total,
                        "items": response.line_items.map((i) => ({
                          "id": i.id,
                          "name": i.variant.name,
                          "quantity": i.quantity,
                          "price": i.price
                        }))
                      });

                      successCallback(response);
                      dispatch(success(response));
                    }
                  },
                  error => {
                    dispatch(errors(error.toString()))
                  }
                )
            }
          }
        },
        error => {
          dispatch(errors(error.toString()));
        }
      );
  };

  function success(response) { return { type: actionType.SUCCESS_UPDATE_PAYMENT_INFORMATION, response }}
  function errors(error) { return { type: actionType.UPDATE_PAYMENT_INFORMATION_ERRORS, name: 'fullMessageError', message: error } };
}

const initState = payload => ({ type: userConstants.INIT_VARIABLE, payload })
const onAddingShippingInfo = () => ({ type: userConstants.ADDING_SHIPPING_INFO });
const onSubmittingInfo = () => ({ type: userConstants.SUBMITTING_INFO });
const onPlacingOrder = () => ({ type: userConstants.PLACING_ORDER });
const stopPlacingOrder = () => ({ type: userConstants.STOP_PLACING_ORDER });
const updateValue = (key, value) => ({ type: userConstants.UPDATE_VALUE, key, value });

export const checkoutActions = {
  updateShippingInformation,
  updatePaymentInformation,
  validatePaymentInformation,
  addError,
  clearError,
  initState,
  onAddingShippingInfo,
  onPlacingOrder,
  stopPlacingOrder,
  updateValue,
  updateRecipientInfo,
  onSubmittingInfo
};
