export const handleResponse = function (response) {
  return response.text().then(text => {
    let data = undefined;
    try {
      data = text && JSON.parse(text);

      if (data.reload === true) {
        location.reload(true);
      }
    } catch(e) {
      location.reload(true);
    }

    if (!response.ok) {
        if ([401, 404].includes(response.status)) {
          // auto logout if 401 response returned from api
          location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    }
  );
}
