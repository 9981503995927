import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import { creditCardActions } from './actions';
import WoofCreditCardContainer from './containers/woof_credit_card_container';

export default class WoofCreditCard extends Component {
  render() {
    store.dispatch(creditCardActions.initState(this.props));

    return (
      <Provider store={store}>
        <WoofCreditCardContainer />
      </Provider>
    );
  }
}

