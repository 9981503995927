import React from "react";
import RecipientForm from '../../lib/components/RecipientForm'

const CashPower = (payload) => {
  const { instances, actions } = payload;
  const {
    selectedVariant,
    requesting,
    orderAdded,
    containerKey,
    product,
    variants,
    recipients,
    recipientInformation,
    cities,
    errorMessages,
    submittingNewRecipient,
    phoneCarriers,
    userSignedIn
  } = instances;

  const {
    addMultipleProduct,
    onSetProduct,
    setSelectedRecipient,
    setAddNewRecipient,
    setFieldNewRecipient,
    onAddRecipient,
    setPhoneCarrierId
  } = actions;

  const onAddProductToCart = () => {
    const selectedRecipientId = recipientInformation.selectedRecipient ? recipientInformation.selectedRecipient.id : null;
    const phone_carrier_id = recipientInformation.selectedRecipient ? (recipientInformation.selectedRecipient.phone_carrier_id || recipientInformation.phone_carrier_id) : recipientInformation.phone_carrier_id;
    addMultipleProduct({ variant_id: selectedVariant.id, quantity: 1, recipient_id: selectedRecipientId, phone_carrier_id });
  }

  const onSelectVariant = (e) => {
    const id = e.currentTarget.value;
    const variant = variants.find(i => i.id === parseInt(id));

    onSetProduct(variant);
  }

  return (
    <div className='container-center'>
      <h3 className='mb-3'>Top Up on Phone Credit</h3>
      <p className='mb-3'>We'll send you a phone credit right away!</p>

      <div className='select-amount mb-3'>
        <div className='form-checkout'>
          <div className="row">
            <div className="col-12">
              <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0` }>
                <select className='form-control' id='variant-id' name='variant_id' value={ selectedVariant.id } onChange={ (e) => { onSelectVariant(e) } }>
                  { variants.map(i => <option key={ i.id } value={ i.id }>{ i.price_string } ({ i.short_description })</option>) }
                </select>
                <label htmlFor="variant-id">Select Amount</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='recipient-information mb-4'>
        <RecipientForm
          product={ product }
          recipients={ recipients }
          recipientInformation={ recipientInformation }
          cities={ cities }
          onSetSelectedRecipient={ setSelectedRecipient }
          containerKey={ containerKey }
          setAddNewRecipient={ setAddNewRecipient }
          errorMessages={ errorMessages }
          setFieldNewRecipient={ setFieldNewRecipient }
          onAddRecipient={ onAddRecipient }
          submittingNewRecipient={ submittingNewRecipient }
          phoneCarriers={ phoneCarriers }
          setPhoneCarrierId={ setPhoneCarrierId }
          userSignedIn={ userSignedIn }
          addFromModal={ true } />
      </div>

      <a href='#' className={ `js-notJump btn-submit btn btn-primary buy-once-btn ${ requesting ? 'disabled' : '' }` } onClick={ () => { onAddProductToCart() }}>
        { orderAdded ? 'Added to Cart' : 'Add to Cart' }
      </a>
    </div>
  );
}

export default CashPower;
