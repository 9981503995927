import React from 'react';

const WoofCartLineItem = (payload) => {
  const {
    name, price, price_number, totalPrice, color,
    bag, image_url, quantity, type, id, is_recurring,
    short_description, recipient_label,
    subscription_interval,
    currency
  } = payload.item;

  const { onUpdateLineItems, order, lineItems, onRemoveItem, updatingLineItem, interval_type } = payload;

  const changeLineItems = (event) => {
    const value = event.currentTarget.value;
    const quantity = parseInt(value);

    if (interval_type) {
      onUpdateLineItems(interval_type, lineItems, id, quantity);
    } else {
      onUpdateLineItems(order.id, lineItems, id, quantity);
    }
  }

  const changeQuantityItems = (number) => {
    if ((number === -1 && quantity > 1) || number === 1) {
      if (interval_type) {
        onUpdateLineItems(interval_type, lineItems, id, (quantity + number));
      } else {
        onUpdateLineItems(order.id, lineItems, id, (quantity + number));
      }
    }
  }

  const removeItem = (itemId) => {
    if (interval_type) {
      onRemoveItem(interval_type, lineItems, itemId)
    } else {
      onRemoveItem(order.id, lineItems, itemId);
    }
  }

  const shortDescription = () => {
    if (!short_description && !subscription_interval) { return null };

    let label = short_description;

    if (!interval_type && subscription_interval === 'month') { label = (<span><div className='mb-2'>With monthly subscription item</div>{ label }</span>) }
    if (interval_type && subscription_interval === 'month') { label = (<span><div className='mb-2'>Items delivered once per month</div>{ label }</span>) }

    if (!interval_type) {
      if (short_description.includes('monthly')) {
        label = 'With monthly subscription item';
      }
      if (short_description.includes('weekly')) {
        label = 'With weekly subscription item';
      }
      if (short_description.includes('two_weeks')) {
        label = 'With two weeky subscription item';
      }
    } else if (short_description.includes('monthly')) { label = 'Items delivered once per month' }
    else if (short_description.includes('weekly')) { label = 'Items delivered every week' }
    else if (short_description.includes('two_weeks')) { label = 'Items delivered once every 2 weeks' }

    return <span>{ label }<span className='d-md-none'> - { price }</span></span>;
  }

  const nameItem = () => {
    return (
      <div>
        <span className='d-md-none'>{ quantity } x </span>
        { name }
      </div>
    );
  }

  const renderChangeQuantityBox = () => {
    const reduceButton = () => {
      if (quantity === 1 && window.innerWidth <= 767) {
        return (
          <a href="#" className='js-notJump d-flex align-items-center justify-content-center' onClick={ () => { removeItem(id) }}>
            <i className="fa fa-times"></i>
          </a>
        );
      } else {
        return (
          <a href="#" className='js-notJump d-flex align-items-center justify-content-center' onClick={ () => { changeQuantityItems(-1) }}>
            <i className="fa fa-minus"></i>
          </a>
        );
      }
    };

    return (
      <div className="product-count d-flex align-items-center justify-content-end">
        <a href="#" className='js-notJump d-flex align-items-center justify-content-center' onClick={ () => { changeQuantityItems(1) }} >
          <i className="fa fa-plus"></i>
        </a>
        <input type="text" className="form-control d-none d-md-block" readOnly={ updatingLineItem } value={ quantity } onChange={ changeLineItems } />
        { reduceButton() }
      </div>
    );
  }

  return (
    <div className="line-items">
      <div className="row">
        <div className="col-10 col-md-6">
          <div className="media d-flex">
            <img src={ image_url } className='img-fluid align-self-center m-2 mr-md-4' />
            <div className="media-body align-self-center">
              <h4 className='mb-1'>{ nameItem() }</h4>
              <p className='text-gray m-0' >
                { shortDescription() }
                { recipient_label ? ` | ${ recipient_label }` : null }
              </p>
            </div>
          </div>
        </div>
        <div className="col-2 col-md-3 align-self-center">
          { renderChangeQuantityBox() }
        </div>
        <div className="col-1 col-md-2 align-self-center text-right d-none d-md-flex justify-content-center text-gray text-semi-bold-weight">
          { totalPrice }
        </div>
        <div className="col-1 align-self-center text-right d-none d-md-flex">
          <a href="#" className='js-notJump text-gray' onClick={ () => { removeItem(id) } }>
            <i className="fa fa-times-circle"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default WoofCartLineItem;
