import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

/**
* POST: addToCart
* Parameters: {
*   "variant_id"=>"9",
*   "quantity"=>"1"
* }
**/

function addToCart(payload, remove_extra_bags = false) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ products: payload, type: 'add_to_cart', remove_extra_bags, authenticity_token })
  };

  return fetch(Routes.spree_add_to_cart_path({ format: 'json' }), requestOptions).then(handleResponse);
}

/**
* POST: addToCart
* Parameters: {
*   "variant_id"=>"9",
*   "quantity"=>"1"
* }
**/

function addSingleToCart(payload, remove_extra_bags = false) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ ...payload, remove_extra_bags, authenticity_token })
  };

  return fetch(Routes.spree_add_to_cart_path({ format: 'json' }), requestOptions).then(handleResponse);
}

/**
* PATCH: updateLineItems
**/

function updateLineItems(orderId, lineItems) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ order: { line_items_attributes: lineItems }, authenticity_token })
  };

  return fetch(`${ Routes.spree_order_path({ format: 'json', id: orderId }) }.json`, requestOptions).then(handleResponse);
}

export const orderService = {
  addToCart,
  updateLineItems,
  addSingleToCart
};
