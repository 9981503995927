import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Recipients from '../components/recipients';
import { mainActions } from '../actions';

const RecipientsContainer = (payload) => {
  const {
    changeSimpleValue,
    toggleEditRecipient,
    changeRecipientField,
    saveRecipientChange,
    deleteRecipient
  } = payload;

  const actions = {
    changeSimpleValue,
    toggleEditRecipient,
    changeRecipientField,
    saveRecipientChange,
    deleteRecipient
  }

  return <Recipients
    fullMessageError={ payload.fullMessageError }
    recipients={ payload.recipients }
    viewType={ payload.viewType }
    phoneCarries={ payload.phoneCarries }
    cities={ payload.cities }
    newRecipient={ payload.newRecipient }
    addNewRecipient={ payload.addNewRecipient }
    actions={ actions }
  />;
}
const mapStateToProps = (state, props) => ({ ...state.recipients });
const mapDispatchToProps = dispatch => bindActionCreators({ ...mainActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsContainer);
