import React from "react";
import _ from 'lodash';
import InputMask from 'react-input-mask';

class Recipients extends React.Component {
  componentDidMount() {
    ApplicationController.prototype.initHrefNotJump();
    ApplicationController.prototype.initLazyLoad();

    FormController.prototype.initialize();
    FormController.prototype.listenForEvents();
  }

  componentDidUpdate(oldProps) {
    FormController.prototype.initialize();
    FormController.prototype.listenForEvents();
  }

  onSaveRecipient = (e, recipient, newRecipient = false) => {
    e.preventDefault();

    const {
      saveRecipientChange
    } = this.props.actions;

    saveRecipientChange(recipient, newRecipient);
  }

  renderRecipientForm = (recipient, isNewForm = false) => {
    const {
      changeSimpleValue,
      toggleEditRecipient,
      changeRecipientField,
      deleteRecipient
    } = this.props.actions;

    const {
      full_name,
      phone_number,
      city,
      phone_carrier_label,
      cashpower_meter_num
    } = recipient;

    const {
      cities,
      phoneCarries
    } = this.props;

    const captionFormRecipient = isNewForm ? 'NEW RECIPIENT' : 'EDIT RECIPIENT';
    const indexRecipient = recipient.id ? recipient.id : 0;

    return (
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-6">
              <strong className='text-size-lg-3x'>{ captionFormRecipient }</strong>
            </div>
            {
              isNewForm ?
                null :
                <div className="col-6 text-right">
                  <a href='#' className='js-notJump text-gray' onClick={ () => { deleteRecipient(recipient) } }>Delete This Recipient</a>
                </div>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className={ `form-group form-group-50 form-group-focus ${ recipient.errors && recipient.errors.first_name ? 'has-error-bar' : '' }` }>
              <input type="text" className='form-control' id={ `first_name_${ indexRecipient }` } name='change_first_name' onChange={ (e) => { changeRecipientField(recipient.id, 'change_first_name', e.currentTarget.value) } } value={ recipient.change_first_name } />
              <label htmlFor={ `first_name_${ indexRecipient }` }>First Name</label>
              <span className={ `hint text-danger ${ recipient.errors && recipient.errors.first_name ? 'd-inline-block' : 'd-none' }` }>{ recipient.errors && recipient.errors.first_name ? recipient.errors.first_name : '' }</span>
            </div>
          </div>
          <div className="col-4">
            <div className={ `form-group form-group-50 form-group-focus ${ recipient.errors && recipient.errors.last_name ? 'has-error-bar' : '' }` }>
              <input type="text" className='form-control' id={ `last_name_${ indexRecipient }` } name='change_last_name' onChange={ (e) => { changeRecipientField(recipient.id, 'change_last_name', e.currentTarget.value) } } value={ recipient.change_last_name } />
              <label htmlFor={ `last_name_${ indexRecipient }` }>Last Name</label>
              <span className={ `hint text-danger ${ recipient.errors && recipient.errors.last_name ? 'd-inline-block' : 'd-none' }` }>{ recipient.errors && recipient.errors.last_name ? recipient.errors.last_name : '' }</span>
            </div>
          </div>
          <div className="col-4">
            <div className={ `form-group form-group-50 form-group-focus ${ recipient.errors && recipient.errors.phone_number ? 'has-error-bar' : '' }` }>
              <InputMask maskChar=' ' mask="+220\ 9999999" className='form-control' id={ `phone_number_${ indexRecipient }` } name='change_phone_number' onChange={ (e) => { changeRecipientField(recipient.id, 'change_phone_number', e.currentTarget.value) } } value={ recipient.change_phone_number } />
              <label htmlFor={ `phone_number_${ indexRecipient }` }>Phone number</label>
              <span className={ `hint text-danger ${ recipient.errors && recipient.errors.phone_number ? 'd-inline-block' : 'd-none' }` }>{ recipient.errors && recipient.errors.phone_number ? recipient.errors.phone_number : '' }</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className={ `form-group form-group-50 form-group-focus select-form square m-0` }>
              <select className='form-control' id={ `city_${ indexRecipient }` } name='change_city' onChange={ (e) => { changeRecipientField(recipient.id, 'change_city', e.currentTarget.value) } } value={ recipient.change_city }>
                { cities.map(i => <option key={ i } value={ i }>{ i }</option>) }
              </select>
              <label htmlFor={ `city_${ indexRecipient }` }>City</label>
            </div>
          </div>
          <div className="col-4">
            <div className='form-group form-group-50 form-group-focus'>
              <input type="text" className='form-control' id={ `cashpower_meter_num_${ indexRecipient }` } name='change_cashpower_meter_num' onChange={ (e) => { changeRecipientField(recipient.id, 'change_cashpower_meter_num', e.currentTarget.value) } } value={ recipient.change_cashpower_meter_num } />
              <label htmlFor={ `cashpower_meter_num_${ indexRecipient }` }>Cashpower Meter #</label>
            </div>
          </div>
          <div className="col-4">
            <div className={ `form-group form-group-50 form-group-focus select-form square m-0` }>
              <select className='form-control' id={ `phone_carrier_id_${ indexRecipient }` } name='change_phone_carrier_id' onChange={ (e) => { changeRecipientField(recipient.id, 'change_phone_carrier_id', e.currentTarget.value) } } value={ recipient.change_phone_carrier_id }>
                <option></option>
                { phoneCarries.map(i => <option key={ i.id } value={ i.id }>{ i.name }</option>) }
              </select>
              <label htmlFor={ `phone_carrier_id_${ indexRecipient }` }>Phone Carrier</label>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-6">
            {
              isNewForm ?
                <a href='#' className='js-notJump btn btn-cancel-gray' onClick={ () => { changeSimpleValue('addNewRecipient', false) } }>Cancel</a> :
                <a href='#' className='js-notJump btn btn-cancel-gray' onClick={ () => { toggleEditRecipient(recipient, false) } }>Cancel</a>
            }
          </div>
          <div className="col-6 text-right">
            <button className='btn btn-primary'>Save Changes</button>
          </div>
        </div>
      </div>
    );
  }

  renderRecipientList = (recipient) => {
    const { full_name, phone_number, city, phone_carrier_label, cashpower_meter_num } = recipient;
    const { toggleEditRecipient } = this.props.actions;

    return (
      <li key={ recipient.id } data-show={ recipient.edit }>
        {
          recipient.edit === true ?
            <div className='row d-flex no-gutters'>
              <div className="col-12 align-self-center">
                <form className='form-checkout' onSubmit={ (e) => { this.onSaveRecipient(e, recipient) } }>
                  { this.renderRecipientForm(recipient, false) }
                </form>
              </div>
            </div> :
            <div className='row d-flex no-gutters'>
              <div className="col-10 align-self-center">
                <div className="row mb-2">
                  <div className="col-12">
                    <strong className='text-size-lg-3x'>{ full_name.toUpperCase() }</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-gray">Phone: { phone_number }</div>
                  <div className="col-7 text-gray">City: { city }</div>
                </div>
                <div className="row">
                  <div className="col-5 text-gray">Carrier: { phone_carrier_label }</div>
                  <div className="col-7 text-gray">Cashpower Meter #: { cashpower_meter_num }</div>
                </div>
              </div>
              <div className='col-2 align-self-center'>
                <a href="#" className='js-notJump btn btn-sm btn-cancel-gray btn-block' onClick={ () => { toggleEditRecipient(recipient, true) } }>Edit</a>
              </div>
            </div>
        }
      </li>
    )
  }

  newRecipientForm = () => {
    const { newRecipient } = this.props;

    return (
      <form className='form-checkout' onSubmit={ (e) => { this.onSaveRecipient(e, newRecipient, true) } }>
        { this.renderRecipientForm(newRecipient, true) }
      </form>
    );
  }

  finishUpdatingRecipient = () => {
    if (window.terangas && window.terangas.configRecipientFromEditPack === true) {
      const { recipients } = this.props;
      const selectedId = $('.js-selectRecipients').find('option:selected').attr('value')
      $('.js-selectRecipients').html(recipients.map(r => `<option value='${ r.id }' ${ parseInt(selectedId) === parseInt(r.id) ? 'selected' : '' }>${  r.full_name }</option>`).join(""))
      $('.modal').modal('hide');
      setTimeout(
        () => {
          $('#modalEditDeliveryDatePack').modal({backdrop: 'static', keyboard: false});
        }, 400
      )
    } else {
      window.location.reload(false);
    }
  }

  render () {
    const { recipients, addNewRecipient, fullMessageError } = this.props;
    const { changeSimpleValue } = this.props.actions;

    return (
      <div className="modal fade modal-style-2" id="modalManageRecipient" tabIndex="-1" role="dialog" aria-labelledby="modalManageRecipientLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg-2x" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

              <div>
                <h2>Your Recipients</h2>

                <p className='text-gray mb-5'>
                  You can update your recipient list here
                </p>

                { fullMessageError ? <div className='alert alert-danger'>{ fullMessageError }</div> : null }

                <ul className='recipients-items list-unstyled'>
                  {
                    recipients.map(r => {
                      return this.renderRecipientList(r);
                    })
                  }
                </ul>

                {
                  addNewRecipient ?
                    this.newRecipientForm() :
                    <div className="text-right mt-5">
                      <a href='#' className='js-notJump btn btn-primary' onClick={ () => { changeSimpleValue('addNewRecipient', true) } }>NEW RECIPIENT</a>
                    </div>
                }
                <br />
                <hr />
                <br />
                <div className='text-center'>
                  <a href='#' className='js-notJump btn btn-primary' onClick={ () => { this.finishUpdatingRecipient() } }>Done</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Recipients;
