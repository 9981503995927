import { store } from '../store';

import { creditCardConstants } from '../constants/index';
import { creditCardService } from '../../../services/credit_card_service';
import StripeClient from '../../../lib/StripeClient';

const actionType = creditCardConstants;


const initState = payload => ({ type: creditCardConstants.INIT_VARIABLES, payload });
const submitForm = (successCallback) => {

  return dispatch => {
    dispatch({ type: creditCardConstants.SUBMIT });

    const stripeHandler = (status, response) => {
      if (status == 200) {
        const params = {
          month: response.card.exp_month,
          year: response.card.exp_year,
          cc_type: response.card.brand.toLowerCase(),
          last_digits: response.card.last4,
          gateway_payment_profile_id: response.id
        }

        creditCardService.create(params).then(
          (response) => {
            if (response.success) {
              setTimeout(
                () => {
                  dispatch({ type: creditCardConstants.CLEAR_SUCCESS_MESSAGE });
                }, 2000
              );

              dispatch({ type: creditCardConstants.SUBMIT_SUCCESS, message: 'Credit card has been success updated.' });
              successCallback(response);
            } else {
              dispatch({ type: creditCardConstants.ADD_ERROR, message: response.message })
              dispatch({ type: creditCardConstants.SUBMIT_FAIL });
            }
          },
          (error) => {
            dispatch({ type: creditCardConstants.ADD_ERROR, message: 'Something went wrong.' })
            dispatch({ type: creditCardConstants.SUBMIT_FAIL });
          }
        );
      } else {
        dispatch({ type: creditCardConstants.ADD_ERROR, message: response.error.message })
        dispatch({ type: creditCardConstants.SUBMIT_FAIL });
      }
    }

    const stripeClient = new StripeClient;
    const state = store.getState().credit_card;
    const card = { number: state.number, expired: state.expired, cvc: state.cvc };

    stripeClient.createToken(card, stripeHandler);
  };

  // success
  // { type: creditCardConstants.SUBMIT, payload }
};
const submitSuccess = payload => ({ type: creditCardConstants.SUBMIT_SUCCESS, payload });
const addError = message => ({ type: creditCardConstants.ADD_ERROR, message });
const clearError = () => ({ type: creditCardConstants.CLEAR_ERROR });
const changeFieldValue = payload => ({ type: creditCardConstants.CHANGE_FIELD_VALUE, payload });

export const creditCardActions = {
  initState,
  submitForm,
  submitSuccess,
  addError,
  clearError,
  changeFieldValue
};