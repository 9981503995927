import React from "react";
import _ from "lodash";

import WoofCartLineItem from './WoofCartLineItem';

class WoofCartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tempSelectBag: null,
      showPromoForm: false
    };
  }

  componentDidMount = () => {
    ApplicationController.prototype.initHrefNotJump();
  }

  onSetShowFormCode = (show) => {
    this.setState({ showPromoForm: show });
  }

  onChangeCouponCode = (e) => {
    const { checkValidCouponCode, updateInputCode } = this.props
    updateInputCode(e.currentTarget.value);
  }

  onApplyCouponCode = () => {
    const { couponCode, applyCouponCode, couponCodeIsValid, order, authenticity_token, hasCouponCode } = this.props;

    if (couponCode && !hasCouponCode) {
      applyCouponCode(order.id, couponCode, authenticity_token);
    }
  }

  renderPromoForm = () => {
    const { selectedSubscriptionProduct, subscriptionProducts, choseSubscriptionProduct, hasActiveSubscription, couponCode, couponCodeIsValid, hasCouponCode, promotions, errorCouponCode } = this.props;
    const { showPromoForm } = this.state;

    if (showPromoForm || hasCouponCode) {
      const promoCode = promotions.find(i => i.promotion_code_text);

      if (promoCode && promoCode.promotion_code_text === couponCode) {
        return (
          <div className='form-inline form-promo-code d-flex justify-content-end'>
            <input type='text' disabled={ true } className='form-control' placeholder="Enter Code Here" value={ couponCode } />
            <a href='#' className='js-notJump btn my-1 text-uppercase btn-primary text-white disabled' disabled={ true }>APPLIED</a>
          </div>
        );
      } else if (showPromoForm && !hasCouponCode) {
        return (
          <div className='form-inline form-promo-code d-flex justify-content-end'>
            <input type='text' className='form-control' placeholder="Enter Code Here" value={ couponCode } onChange={ (e) => { this.onChangeCouponCode(e) } } onBlur={ (e) => { this.onChangeCouponCode(e) } } />
            <a href='#' className={ ` js-notJump btn my-1 text-uppercase ${ couponCode && couponCode.length > 0 ? 'btn-primary text-white' : 'btn-gray text-gray' }` } onClick={ () => { this.onApplyCouponCode() } }>APPLY</a>
            <div className={ `text-right text-primary ${ errorCouponCode ? '' : 'hidden' }` }>oops, that code was invalid</div>
          </div>
        );
      } else {
        return null;
      }
    } else if (promotions.length === 0){
      return (
        <a href='#' className='js-notJump text-gray text-base-2x text-primary-hover' onClick={ () => { this.onSetShowFormCode(true) } }>
          <i>Have promo code? Click here</i>
        </a>
      );
    }
  }

  increaseNumber = (options) => {
    if (options.type === 'woofLeash') {
      const count   = this.state.woofLeash.count + 1;
      const woofLeash = { ...this.state.woofLeash, count, totalPrice: this.state.woofLeash.price * count };
      this.setState({ woofLeash });
    } else {
      const count   = this.state.businessBags.count + 1;
      const businessBags = { ...this.state.businessBags, count, totalPrice: this.state.businessBags.price * count };
      this.setState({ businessBags });
    }
  }

  decreaseNumber = (options) => {
    if (options.type === 'woofLeash') {
      const count   = this.state.woofLeash.count - 1;
      const woofLeash = { ...this.state.woofLeash, count, totalPrice: this.state.woofLeash.price * count };
      this.setState({ woofLeash });
    } else {
      const count   = this.state.businessBags.count - 1;
      const businessBags = { ...this.state.businessBags, count, totalPrice: this.state.businessBags.price * count };
      this.setState({ businessBags });
    }
  }

  setWoofLeashCount = (event) => {
    const el = event.currentTarget;
    const count   = parseInt(el.value) ? parseInt(el.value) : 0;
    const woofLeash = { ...this.state.woofLeash, count, totalPrice: this.state.woofLeash.price * count };
    this.setState({ woofLeash });
  }

  setBusinessBagsCount = (event) => {
    const el = event.currentTarget;
    const count   = parseInt(el.value) ? parseInt(el.value) : 0;
    const businessBags = { ...this.state.businessBags, count, totalPrice: this.state.businessBags.price * count };
    this.setState({ businessBags });
  }

  choosebusinessBagsSelected = (type) => {
    this.setState({ businessBagsSelected: type })
  }

  addBusinessBags = () => {
    const { businessBags } = this.state;
    businessBags.type = this.state.businessBagsSelected;
    this.setState({ businessBags })
  }

  showModalDogs = (event) => {
    if ($(event.currentTarget).prop('checked')) {
      $('#modalMoreDogs').modal('show');
    }
  }

  onDogNumberSelected = (number) => {
    this.setState({ moreDogSelected: number });
  }

  renderAlertToAddSubscription = () => (
    <div className="modal-alert modal fade" id="modalAlertToAddSubscription" tabIndex="-1" role="dialog" aria-labelledby="modalAlertToAddSubscriptionLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body text-center">
            <h3>Do you still want the extra bags in your cart?</h3>

            <p className='text-gray text-lighter-weight'>
              We’ll send you your monthly delivery of bags on { moment().subtract(1, "month").startOf("month").format('MMMM') } { moment()._d.getDate() + 1 }. Do you still want the other bags in your cart?
            </p>

            <div className='text-center d-flex flex-wrap'>
              <div className='pr-1 w-50'>
                <a href="#" className="js-notJump btn btn-block btn-cancel-gray" onClick={ () => { this.addSubscriptionProduct(this.state.tempSelectBag, true) }}>NO THANKS</a>
              </div>
              <div className='pl-1 w-50'>
                <a href="#" className="js-notJump btn btn-block btn-primary" onClick={ () => { this.addSubscriptionProduct(this.state.tempSelectBag) }} >KEEP 'EM</a>
              </div>
              <a href="#" className="js-notJump text-gray w-100 mt-3" data-dismiss="modal">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  setTempSelectBag = (product) => {
    this.setState({ tempSelectBag: product });
  }

  renderLineItems = () => {
    const { updateLineItems, removeItem, lineItems, order, updatingLineItem } = this.props;

    return(
      this.props.lineItems.map((item) => (
        <WoofCartLineItem
          key={ item.id }
          item={ item }
          lineItems={ lineItems }
          order={ order }
          onUpdateLineItems={ updateLineItems }
          onRemoveItem={ removeItem }
          updatingLineItem={ updatingLineItem } />
      ))
    )
  }

  removeSubscriptionItem = (type, lineItems, itemId, destroy=false) => {
    const { updateSubscriptionLineItems } = this.props;
    updateSubscriptionLineItems(type, lineItems, itemId, 1, true);
  }

  renderLineItemsWeek = () => {
    const { updateSubscriptionLineItems, removeItem, lineItemsWeek, order, updatingLineItem, updatingWeekLineItem } = this.props;

    return(
      lineItemsWeek.map((item) => (
        <WoofCartLineItem
          key={ item.id }
          item={ item }
          lineItems={ lineItemsWeek }
          order={ order }
          onUpdateLineItems={ updateSubscriptionLineItems }
          onRemoveItem={ this.removeSubscriptionItem }
          updatingLineItem={ updatingWeekLineItem }
          interval_type='week' />
      ))
    )
  }

  renderLineItemsMonth = () => {
    const { updateSubscriptionLineItems, removeItem, lineItemsMonth, order, updatingMonthLineItem } = this.props;

    return(
      lineItemsMonth.map((item) => (
        <WoofCartLineItem
          key={ item.id }
          item={ item }
          lineItems={ lineItemsMonth }
          order={ order }
          onUpdateLineItems={ updateSubscriptionLineItems }
          onRemoveItem={ this.removeSubscriptionItem }
          updatingLineItem={ updatingMonthLineItem }
          interval_type='month' />
      ))
    )
  }

  renderAddtionalPrice = () => {
    const {
      choseSubscriptionProduct,
      promotions,
      hasActiveSubscription,
      selectedSubscriptionProduct,
      order,
      totalShippingPrice,
      totalShippingPriceString
    } = this.props;

    const renderPromotions = () => {
      if (promotions.length > 0) {
        return (
          <div className='promotions'>
            {
              promotions.map((p) => {
                return (
                  <p className='additionalPrice' key={ p.id }>
                    <span className="pull-right text-gray text-lighter-weight">{ p.amount_text }</span>
                    <span className="text-gray text-lighter-weight">{ p.name }</span>
                  </p>
                )
              })
            }
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <div>
        <p className='additionalPrice'>
          <span className="pull-right text-gray text-lighter-weight">{ totalShippingPrice > 0 ? totalShippingPriceString : 'Free!'  }</span>
          <span className="text-gray text-lighter-weight">Taxes & Shipping</span>
        </p>
        {
          choseSubscriptionProduct ?
            <p className='additionalPrice'>
              <span className="pull-right text-gray text-lighter-weight">{ choseSubscriptionProduct.price_string }</span>
              <span className="text-gray text-lighter-weight">Bags (shipped every 3 months)</span>
            </p> :
            null
        }
        { renderPromotions() }
      </div>
    );
  }

  checkToAddSubscriptionProduct = (product) => {
    $('#modalMoreDogs').modal('hide');

    const { lineItems, choseSubscriptionProduct, order, onRemoveItem } = this.props;
    const lineItemBags = lineItems.filter(i => i.type === 'bag');

    if (choseSubscriptionProduct && (choseSubscriptionProduct.id === product.id)) {
      const item = lineItems.find(i => i.variant_id === product.id);
      onRemoveItem(order.id, lineItems, item.id);
    } else if (choseSubscriptionProduct && (choseSubscriptionProduct.id !== product.id)) {
      let order = [{ variant_id: product.id, quantity: 1 }];
      this.addSubscriptionProduct(product);
    } else if (!choseSubscriptionProduct && lineItemBags.length === 0) {
      let order = [{ variant_id: product.id, quantity: 1 }];
      this.addSubscriptionProduct(product);
    } else if (!choseSubscriptionProduct && lineItemBags.length > 0) {
      $('#modalAlertToAddSubscription').modal('show');
      this.setState({ tempSelectBag: product });
    } else {
      throw new Error('Condition is not valid');
    }
  }

  addSubscriptionProduct = (product, remove_extra_bags = false) => {
    const { onAddSubscriptionProduct } = this.props;
    let order = [{ variant_id: product.id, quantity: 1 }];
    onAddSubscriptionProduct(order, remove_extra_bags);
  }

  loadingTag = (type) => {
    const { updatingLineItem, updatingWeekLineItem, updatingMonthLineItem } = this.props;
    let checking = updatingLineItem;

    if (type === 'week') {
      checking = updatingWeekLineItem;
    } else if (type === 'month') {
      checking = updatingMonthLineItem;
    }

    return (
      <div className={ `loading-wrapper ${ checking ? '' : 'stop-loading' }` }>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  render () {
    const {
      promotions,
      lineItems,
      lineItemsWeek,
      lineItemsMonth,
      hasShipment,
      order,
      hasActiveSubscription,
      subscriptionActionableDates,
      currencySymbol
    } = this.props;

    let totalPriceNumber = 0;
    let totalPromotions = 0;
    let totalPriceArray = [];
    const add = (a, b) => (a + b);

    if (lineItems && lineItems.length > 0) {
      totalPriceArray = this.props.lineItems.map(i => i.quantity * i.price_number);
      if (promotions.length > 0) {
        totalPromotions = promotions.map(i => i.amount_number);
        totalPromotions = totalPromotions.reduce(add);
      }
      totalPriceNumber = totalPriceArray.reduce(add) + totalPromotions;

      if (!hasShipment) {
        totalPriceNumber = totalPriceNumber + parseFloat(order.shipment_total);
      }
    }

    const renderSubmit = () => {
      if (this.props.lineItems && this.props.lineItems.length > 0) {
        return (<a href={ Routes.spree_checkout_carts_path() } className='btn btn-primary btn-large btn-block'>Checkout</a>);
      } else {
        return '';
      }
    }

    return (
      <React.Fragment>
        {
          (lineItems && lineItems.length > 0) ?
            <div className="cart-container">
              <div className="cart-line-items pt-3">
                { this.loadingTag() }
                { this.renderLineItems() }
              </div>

              {
                lineItemsWeek && lineItemsWeek.length > 0 ?
                  <div>
                    <h4 className='mt-5'>Items Added to Weekly Subscription - <span className='text-gray text-lighter-weight'>Next Delivery { subscriptionActionableDates.week }</span></h4>
                    <div className="cart-line-items pt-3">
                      { this.loadingTag('week') }
                      { this.renderLineItemsWeek() }
                    </div>
                  </div> : null
              }

              {
                lineItemsMonth && lineItemsMonth.length > 0 ?
                  <div>
                    <h4 className='mt-5'>Items Added to Monthly Subscription - <span className='text-gray text-lighter-weight'>Charged & Delivered on { subscriptionActionableDates.month }

                  </span></h4>
                    <div className="cart-line-items pt-3">
                      { this.loadingTag('month') }
                      { this.renderLineItemsMonth() }
                    </div>
                  </div> : null
              }

              <div className='clearfix'></div>

              <div className='d-flex'>
                <div className='align-self-end'>
                  <a href={ Routes.spree_products_path() } className='btn btn-cancel-gray btn-large btn-block text-size-lg-3x text-bold-weight d-xs-none'>Continue Shopping</a>
                </div>
                <div className="cart-submit">
                  {
                    window.innerWidth > 767 ?
                      <div>
                        <div className="form-check checkbox-wrapper d-none d-md-block mb-4">
                          { this.renderPromoForm() }
                        </div>
                      </div>
                      : null
                  }

                  { this.renderAddtionalPrice() }

                  <p>
                    <span className="pull-right text-dark">{ order.display_total_price }</span>
                    <span className="text-dark">Today's Subtotal</span>
                  </p>

                  { renderSubmit() }

                  {
                    window.innerWidth <= 767 ?
                      <div className="pull-right mt-3 mt-md-4 text-lighter-weight">
                        { this.renderPromoForm() }
                      </div> : null
                  }

                  <div className='clearfix'></div>
                </div>
              </div>

              { this.renderAlertToAddSubscription() }
            </div> :
            null
        }
      </React.Fragment>
    );
  }
}

export default WoofCartComponent;
