import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import { cartActions } from './actions';
import WoofCartContainer from './containers/woof_cart_container';

class WoofMainCart extends Component {
  render() {
    store.dispatch(cartActions.initState(this.props));

    return (
      <Provider store={store}>
        <WoofCartContainer />
      </Provider>
    );
  }
}

export default WoofMainCart;