import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WoofCheckOutComponent from '../components/woof_checkout_component';
import { checkoutActions } from '../actions';
import { userActions } from '../actions';

const mapStateToProps = (state, props) => {
  return { ...state.registration, ...state.checkout, ...props, registrationState: { ...state.registration } }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...checkoutActions, ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WoofCheckOutComponent);
