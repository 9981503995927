import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WoofCreditCardComponent from '../components/WoofCreditCardComponent';
import { creditCardActions } from '../actions';

const WoofCreditCardContainer = ({ errors, number, expired, cvc, isSubmitting, submitForm, addError, clearError, changeFieldValue, successMessage }) => {
  return <WoofCreditCardComponent
            errors={ errors }
            number={ number }
            expired={ expired }
            cvc={ cvc }
            isSubmitting={ isSubmitting }
            onSubmitForm={ submitForm }
            onAddError={ addError }
            onClearError={ clearError }
            onChangeFieldValue={ changeFieldValue }
            successMessage={ successMessage }
          />;
}

const mapStateToProps = (state, props) => ({ ...state.credit_card });
const mapDispatchToProps = dispatch => bindActionCreators({ ...creditCardActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WoofCreditCardContainer);
