import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

/**
* PATCH: updateLineItems
**/

function updateLineItems(type, params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ user: { subscription_line_items_attributes: params }, authenticity_token, type })
  };

  return fetch(Routes.spree_order_update_line_item_woof_subscriptions_path({ format: 'json' }), requestOptions).then(handleResponse);
}

export const subscriptionService = {
  updateLineItems
};