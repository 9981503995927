import { handleResponse } from './helper';

const token = document.querySelector('meta[name="csrf-token"]').content;

function apiStates(country_id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': token }
  };

  return fetch(Routes.spree_api_states_path({ format: 'json', country_id }), requestOptions).then(handleResponse);
}

export const locationService = {
  apiStates
};