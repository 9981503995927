import { userConstants } from '../constants';

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        registering: true
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        email: action.response.email,
        confirmationToken: action.response.confirmation_token
      };
    case userConstants.REGISTER_FAILURE:
      const { errors } = action.response;
      return {
        ...state,
        registering: false,
        errors
      };
    case userConstants.REGISTER_SET_EMAIL:
      return {
        ...state,
        email: action.email
      };
    case userConstants.REGISTER_PASSWORD_TO_FALSE:
      return {
        ...state,
        confirmationToken: null,
        registering: false,
        current_user_first_name: action.first_name,
        current_user_last_name: action.last_name,
        current_user_phone_number: action.phone_number
      };
    default:
      return state
  }
}
