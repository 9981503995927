export const orderConstants = {
  ORDER_INIT_VARIABLES: 'ORDER_INIT_VARIABLES',

  ORDER_ADD_REQUEST: 'ORDER_ADD_REQUEST',
  ORDER_ADD_SUCCESS: 'ORDER_ADD_SUCCESS',
  ORDER_ADD_FAILURE: 'ORDER_ADD_FAILURE',
  ORDER_ADD_ERROR: 'ORDER_ADD_ERROR',
  ORDER_HAS_ADDED: 'ORDER_HAS_ADDED',

  ORDER_SET_SELECTED_PRODUCT: 'ORDER_SET_SELECTED_PRODUCT',
  ORDER_SET_SELECTED_IMAGE: 'ORDER_SET_SELECTED_IMAGE',
  ORDER_SET_USE_SUBSCRIPTION: 'ORDER_SET_USE_SUBSCRIPTION',

  ORDER_SET_SELECTED_SUBCRIPTION_PRODUCT: 'ORDER_SET_SELECTED_SUBCRIPTION_PRODUCT',

  ORDER_SET_PRODUCT_BY_OPTION_VALUE: 'ORDER_SET_PRODUCT_BY_OPTION_VALUE',
  UPDATE_CHECK_SUBSCRIPTION: 'UPDATE_CHECK_SUBSCRIPTION',
  ORDER_SET_BAG_PRODUCT: 'ORDER_SET_BAG_PRODUCT',

  ORDER_SET_SELETED_RECIPIENT: 'ORDER_SET_SELETED_RECIPIENT',
  ORDER_SET_ADD_NEW_RECIPIENT: 'ORDER_SET_ADD_NEW_RECIPIENT',
  ORDER_SET_FIELD_NEW_RECIPIENT: 'ORDER_SET_FIELD_NEW_RECIPIENT',
  ORDER_SET_ERROR_MESSAGE: 'ORDER_SET_ERROR_MESSAGE',
  ORDER_ADDED_RECIPIENT: 'ORDER_ADDED_RECIPIENT',
  ORDER_ADDING_RECIPIENT: 'ORDER_ADDING_RECIPIENT',
  ORDER_SET_PHONE_CARRIER: 'ORDER_SET_PHONE_CARRIER'
};
