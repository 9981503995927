import { cartConstants } from '../constants';

const updateTitleText = (lineItems) => {
  if (lineItems && lineItems.length <= 0) {
    $('.js-yourCartText').html('Your Cart is empty');
    $('.js-freeShippingText').remove();
  }
}

export function cart(state = {}, action) {
  switch (action.type) {
    case cartConstants.CART_INIT_VARIABLES:
      const { payload } = action;

      return {
        ...state,
        ...payload,
        everyThreeMonth: false,
        couponCodeIsValid: false,
        updatingLineItem: false,
        updatingWeekLineItem: false,
        updatingMonthLineItem: false
      };
    case cartConstants.CART_UPDATE_LINE_ITEMS:
      updateTitleText(action.newLineItems);

      return {
        ...state,
        lineItems: action.newLineItems
      };
    case cartConstants.CART_UPDATE_SUCCESS:
      updateTitleText(action.response.line_items);

      return {
        ...state,
        lineItems: action.response.line_items,
        promotions: action.response.promotions,
        hasActiveSubscription: action.response.hasActiveSubscription,
        hasCouponCode: action.response.hasCouponCode,
        couponCode: action.response.couponCode,
        order: action.response.order
      };
    case cartConstants.CART_UPDATE_FAIL:
      return { ...state };
    case cartConstants.CART_UPDATE_ERROR:
      return { ...state };
    case cartConstants.CART_REMOVE_ITEM:
      updateTitleText(action.newLineItems);

      return {
        ...state,
        lineItems: action.newLineItems
      };
    case cartConstants.CART_SET_THREE_MONTH:
      return {
        ...state,
        everyThreeMonth: action.value
      };
    case cartConstants.CART_SET_VALID_COUPON_CODE:
      return {
        ...state,
        couponCodeIsValid: action.available || false,
        couponCode: action.code
      }
    case cartConstants.CART_APPLY_COUPON_CODE:
      if (action.errors) {
        return {
          ...state,
          hasCouponCode: action.hasCouponCode,
          errorCouponCode: action.errors[0]
        }
      } else {
        updateTitleText(action.line_items);

        return {
          ...state,
          hasCouponCode: action.hasCouponCode,
          lineItems: action.line_items,
          promotions: action.promotions,
          errorCouponCode: undefined
        }
      }
    case cartConstants.CART_UPDATING:
      return {
        ...state,
        updatingLineItem: action.update
      }
    case cartConstants.CART_UPDATE_VALUE:
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state;
  }
}
