import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import { mainActions } from './actions';
import RecipientsContainer from './containers/recipients_container';

export default class AppRecipients extends Component {
  render() {
    store.dispatch(mainActions.initState(this.props));

    return (
      <Provider store={store}>
        <RecipientsContainer />
      </Provider>
    );
  }
}

