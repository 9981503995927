import { handleResponse } from './helper';

const token = document.querySelector('meta[name="csrf-token"]').content;

/**
* PATCH: update
**/
function create(params) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  };

  return fetch(Routes.spree_update_credit_card_woof_account_index_path({ format: 'json' }), requestOptions).then(handleResponse);
}

export const creditCardService = {
  create
};
