import { handleResponse } from './helper';

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content;

/**
* PATCH: update
* All update checkout
* Address Paramerters
*
* {
*   "order"=>{
*     "email"=>"edon@gmail.com",
*     "bill_address_attributes"=>{
*       "firstname"=>"hedon",
*       "address1"=>"test",
*       "city"=>"test",
*       "country_id"=>"233",
*       "state_id"=>"3351",
*       "zipcode"=>"222",
*       "phone"=>"222"
*     },
*     "use_billing"=>"1"
*   },
*   "save_user_address"=>"1",
*   "state"=>"address"
* }
*
**/

let requestOptions = null;

function update(params, state) {
  requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  };

  return fetch(Routes.spree_update_checkout_path({ state, save_user_address: '1', format: 'json' }), requestOptions).then(handleResponse);
}

function addRecipient(params, forSubscription) {
  const for_subscription = forSubscription || false;

  requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify({ ...params, for_subscription, authenticity_token })
  }

  return fetch(Routes.spree_add_recipient_woof_orders_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function createRecipient(params) {
  requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  }

  return fetch(Routes.spree_create_recipient_woof_orders_path({ format: 'json' }), requestOptions).then(handleResponse);
}

function updateRecipient(params, recipientId) {
  requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authenticity_token },
    body: JSON.stringify(Object.assign(params, { authenticity_token }))
  }

  return fetch(Routes.spree_update_recipient_woof_orders_path({ format: 'json', recipient_id: recipientId }), requestOptions).then(handleResponse);
}

export const checkoutService = {
  update,
  addRecipient,
  createRecipient,
  updateRecipient
};
