import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductCatalogs from '../../../lib/ProductCatalogs';
import { orderActions } from '../actions';

const WoofProductCatalogContainer = (payload) => {

  const instances = { ...payload[payload.containerKey], containerKey: payload.containerKey };

  const {
    addToCart,
    setSelectedProduct,
    setSelectedImage,
    containerKey,
    setProductByOptionValue,
    selectedVariant,
    setSelectedRecipient,
    setAddNewRecipient,
    setErrorMessage,
    setFieldNewRecipient,
    addRecipient,
    setPhoneCarrierId,
  } = payload;

  const actions = {
    addToCart,
    setSelectedProduct,
    setSelectedImage,
    containerKey,
    setProductByOptionValue,
    setSelectedRecipient,
    setAddNewRecipient,
    setErrorMessage,
    setFieldNewRecipient,
    addRecipient,
    setPhoneCarrierId
  };

  return <ProductCatalogs instances={{ ...instances }} actions={{ ...actions }} />;
}

const mapStateToProps = (state, props) => ({ ...state.order, containerKey: props.containerKey });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WoofProductCatalogContainer);
