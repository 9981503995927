import React from "react";
import { Link } from "react-router-dom";

class Pack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dogsNumber: null,
      leashNumber: 1,
      showMoreLeash: false,
      showMoreDogs: false
    };
  }

  setDogNumber = (event) => {
    if (event.currentTarget.nodeName == 'SELECT') {
      this.setState({ dogsNumber: parseInt(event.currentTarget.value) });
    } else {
      const value = event.currentTarget.attributes.value.value;
      this.setState({ dogsNumber: parseInt(value), showMoreDogs: parseInt(value) > 4 });
    }
  }

  setLeashNumber = (event) => {
    if (event.currentTarget.nodeName == 'SELECT') {
      this.setState({ leashNumber: parseInt(event.currentTarget.value) });
    } else {
      const value = event.currentTarget.attributes.value.value;
      this.setState({ leashNumber: parseInt(value), showMoreLeash: parseInt(value) > 2  });
    }
  }

  setMoreLeash = (event) => {
    this.setState({ showMoreLeash: true, leashNumber: 3 });
  }

  resetSelect = () => {
    this.setState({ dogsNumber: null });
  }

  renderModalMoreLeash = () => {
    return (
      <div className="modal-get-started modal fade" id="modalMoreLeash" tabIndex="-1" role="dialog" aria-labelledby="modalMoreLeashLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>Do You Need An Extra Leash?</h3>
              <p className='text-gray'>
                <strong>
                  It looks like you have a couple dogs in your pack. Do you need more than on leash?
                </strong>
              </p>

              <div className="row">
                <div className="col-4">
                  <a href="#" className={ `woof-number-option d-flex justify-content-center align-items-center ${ this.state.leashNumber === 1 ? 'woof-number-option__selected' : '' }` } onClick={this.setLeashNumber} value='1'>
                    No, one's fine.
                  </a>
                </div>
                <div className="col-4">
                  <a href="#" className={ `woof-number-option d-flex justify-content-center align-items-center ${ this.state.leashNumber === 2 ? 'woof-number-option__selected' : '' }` } onClick={this.setLeashNumber} value='2'>
                    I'll take two!
                  </a>
                </div>
                <div className="col-4">
                  <a href="#" className={ `woof-number-option d-flex justify-content-center align-items-center ${ this.state.showMoreLeash ? 'woof-number-option__selected' : '' }` } onClick={this.setMoreLeash}>
                    I need several.
                  </a>
                </div>
              </div>

              <div className="pull-right text-small text-gray my-2">
                <small>
                  * additional leashes are $24.99 each.
                </small>
              </div>

              <div className="clearfix"></div>

              <div className={ `form-group select-form ${ this.state.showMoreLeash ? 'visible' : 'invisible' }` }>
                <select onChange={ this.setLeashNumber }>
                  <option value="3">3 Extra leashes - $74.99</option>
                  <option value="4">4 Extra leashes - $99.99</option>
                </select>
              </div>

              <div className="submit-more-dog">
                <a href="#" className="btn btn-primary btn-block" onClick={ this.onSubmit }>Done</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderModalMoreDogs = () => {
    const onCheckout = () => {
      $('#modalMoreDogs').modal('hide');
      $('#modalMoreLeash').modal('show');
    }

    return (
      <div className="modal-get-started modal fade" id="modalMoreDogs" tabIndex="-1" role="dialog" aria-labelledby="modalMoreDogsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>We Love Dogs Too.</h3>
              <p className='text-gray'>
                <strong>
                  Let us know how many dogs you have so we can send you the right number of bags.
                </strong>
              </p>

              <div className="row">
                <div className="col-4 text-center">
                  <a href='#' className={`woof-item d-flex flex-wrap justify-content-center ${this.selectedClass(3)}`} onClick={this.setDogNumber} value='3'>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                  </a>

                  <h4>3 Pups</h4>
                  <small className='text-gray'>216 Bags - $18/month</small>
                </div>
                <div className="col-4 text-center">
                  <a href='#' className={`woof-item d-flex flex-wrap justify-content-center ${this.selectedClass(4)}`} onClick={this.setDogNumber} value='4'>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                  </a>

                  <h4>4 Pups</h4>
                  <small className='text-gray'>144 Bags - $12/month</small>
                </div>
                <div className="col-4 text-center">
                  <a href='#' className={`woof-item more-dogs d-flex flex-wrap justify-content-center ${ this.state.dogsNumber > 4 ? 'woof-item-selected' : '' }`} onClick={this.setDogNumber} value='5'>
                    <i className="fa fa-plus align-self-center"></i>
                    <i className="icon icon-woof no-hover align-self-center"></i>
                  </a>

                  <h4>More Pups</h4>
                  <small className='text-gray'>(that's a lot of dogs)</small>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className={ `form-group select-form ${ this.state.showMoreDogs ? 'visible' : 'invisible' }` }>
                <select onChange={ this.setDogNumber }>
                  <option value="5">5 Dogs (360 bags/month) - $28</option>
                  <option value="6">6 Dogs (432 bags/month) - $32</option>
                </select>
              </div>

              <div className="submit-more-dog">
                <a href="#" className="btn btn-primary btn-block" onClick={ onCheckout }>Checkout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onSubmit = () => {
    window.location.href = Routes.spree_checkout_get_started_index_path();
  }

  selectedClass = (number) => {
    return this.state.dogsNumber === number ? 'woof-item-selected' : '';
  }

  render () {
    const onCheckout = () => {
      if (this.state.dogsNumber === 1) {
        this.onSubmit();
      } else {
        $('#modalMoreLeash').modal('show');
      }
    }

    return (
      <React.Fragment>
        <section id="how-woof">
          <div className="container">
            <div className="product-caption text-center">
              <h3 className='m-0'>How Big's The Pack?</h3>
            </div>

            <div className="row">
              <div className="col-6 text-center">
                <a href='#' className={`woof-item d-flex flex-column justify-content-center ${this.selectedClass(1)}`} onClick={this.setDogNumber} value='1'>
                  <i className="icon icon-woof no-hover align-self-center"></i>
                </a>

                <h4>1 Pup</h4>
                <small className='text-gray'>72 Bags - $7/month</small>
              </div>
              <div className="col-6 text-center">
                <a href='#' className={`woof-item d-flex flex-column justify-content-center ${this.selectedClass(2)}`} onClick={this.setDogNumber} value='2'>
                  <i className="icon icon-woof no-hover align-self-center"></i>
                  <i className="icon icon-woof no-hover align-self-center"></i>
                </a>

                <h4>2 Pup</h4>
                <small className='text-gray'>144 Bags - $12/month</small>
              </div>
            </div>

            <div className="woof-submit text-center">
              <small className='text-gray'>
                Have More Dog? <a href="#" data-toggle="modal" data-target="#modalMoreDogs" onClick={ this.resetSelect }>Click Here</a>
              </small>
              <a href="#" className="btn btn-primary btn-block" onClick={ onCheckout }>Checkout</a>
            </div>
          </div>

          { this.renderModalMoreLeash() }
          { this.renderModalMoreDogs() }

        </section>
      </React.Fragment>
    );
  }
}

export default Pack;