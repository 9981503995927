import React from 'react';
import InputMask from 'react-input-mask';

const RecipientForm = (payload) => {
  const {
    onSubmitRecipientInfo,
    recipients,
    showFormRecipient,
    recipientInformation,
    submittingNewRecipient,
    onSetSelectedRecipient,
    containerKey,
    setAddNewRecipient,
    cities,
    errorMessages,
    setFieldNewRecipient,
    onAddRecipient,
    product,
    phoneCarriers,
    setPhoneCarrierId,
    addFromModal
  } = payload;

  const fullMessageError = errorMessages['fullMessageError'];
  const createNewRecipient = recipientInformation.createNew;
  const selectedRecipientId = recipientInformation.selectedRecipient ? recipientInformation.selectedRecipient.id : '';
  const { needSpecificForm } = recipientInformation;
  const { product_type } = product;
  const selectedPhoneCarrierId = recipientInformation.selectedRecipient ? (recipientInformation.selectedRecipient.phone_carrier_id || recipientInformation.phone_carrier_id) : recipientInformation.phone_carrier_id;

  let errors = [];

  const setRecipient = (e) => {
    const recipientId = e.currentTarget.value;
    const recipient = recipients.find(i => i.id === parseInt(recipientId));
    onSetSelectedRecipient(containerKey, recipient);
  }

  const handleRecipientInformation = (e) => {
    setFieldNewRecipient(containerKey, e.currentTarget.name, e.currentTarget.value);
  }

  const handleChangePhoneCarrier = (e) => {
    setPhoneCarrierId(containerKey, e.currentTarget.value);
  }

  const linkNewRecipient = () => {
    if (addFromModal === true) {
      return(
        <div className='text-right mt-2'>
          <a href='#' className='js-notJump text-blue' data-toggle='modal' data-target="#modalManageRecipient" data-backdrop="static" data-keyboard="false">Add or Edit Recipient</a>
        </div>
      )
    } else {
      if (createNewRecipient) {
        return null
      } else {
        return(
          <div className='text-right mt-2'>
            <a href='#' className='js-notJump text-blue' onClick={ () => { setAddNewRecipient(containerKey) } }>+ Add a new recipient</a>
          </div>
        )
      }
    }
  }

  return (
    <form className='form-checkout'>

      { fullMessageError ? <div className='alert alert-danger'>{ fullMessageError }</div> : null }

      <div className="row">
        <div className="col-12">
          <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessages['recipient_id'] ? 'has-error-bar' : '' }` }>
            <select className='form-control' id='recipient-id' name='recipient_id' value={ selectedRecipientId } onChange={ (e) => ( setRecipient(e))}>
              <option value=''></option>
              { recipients.map(i => <option key={i.id} value={ i.id } disabled={ i.blacklisted }>{ i.cash_power_label }</option>) }
            </select>
            <label htmlFor="recipient-id">Select Existing Recipient</label>
            <span className={ `hint text-danger ${ errorMessages['recipient_id'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['recipient_id'] }</span>
          </div>
        </div>
      </div>

      {
        product_type === 'phone_credit' && !createNewRecipient && selectedRecipientId ?
          <div className="row mt-2">
            <div className="col-12">
              <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessages['phone_carrier_id'] ? 'has-error-bar' : '' }` }>
                <select className='form-control' disabled={ ![null, ''].includes(recipientInformation.selectedRecipient.phone_carrier_id) } id='phone_carrier_id' name='phone_carrier_id' value={ selectedPhoneCarrierId || '' } onChange={ (event) => ( handleRecipientInformation(event))}>
                  <option value=''></option>
                  { phoneCarriers.map(p => <option key={ p.id } value={ p.id }>{ p.name }</option>) }
                </select>
                <label htmlFor="phone_carrier_id">Select Phone Carrier</label>
                <span className={ `hint text-danger ${ errorMessages['phone_carrier_id'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['phone_carrier_id'] }</span>
              </div>
            </div>
          </div> : null
      }

      { linkNewRecipient() }

      {
        !createNewRecipient || addFromModal === true ?
          null :
          <div>
            <hr className='mt-4 mb-3 mx-auto' />

            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <h4 className='text-dark'>Create New Recipient</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['first_name'] ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='first-name' name='first_name' value={ recipientInformation.first_name || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                  <label htmlFor="first-name">Recipient first name</label>
                  <span className={ `hint text-danger ${ errorMessages['first_name'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['first_name'] }</span>
                </div>
              </div>
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['last_name'] ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='last-name' name='last_name' value={ recipientInformation.last_name || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                  <label htmlFor="last-name">Recipient last name</label>
                  <span className={ `hint text-danger ${ errorMessages['last_name'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['last_name'] }</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['phone_number'] ? 'has-error-bar' : '' }` }>
                  <InputMask maskChar=' ' mask="+220\ 9999999" value={ recipientInformation.phone_number || '' } className='form-control' id='phone-number' name='phone_number'  onChange={ (event) => ( handleRecipientInformation(event))} />
                  <label htmlFor="phone-number">Their phone number</label>
                  <span className={ `hint text-danger ${ errorMessages['phone_number'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['phone_number'] }</span>
                </div>
              </div>
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessages['city'] ? 'has-error-bar' : '' }` }>
                  <select className='form-control' id='city' name='city' value={ recipientInformation.city || '' } onChange={ (event) => ( handleRecipientInformation(event))}>
                    { cities.map(i => <option key={ cities.indexOf(i) } value={ i }>{ i }</option>) }
                  </select>
                  <label htmlFor="city">Their City</label>
                  <span className={ `hint text-danger ${ errorMessages['city'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['city'] }</span>
                </div>
              </div>
            </div>

            {
              product_type === 'cash_power' ?
                <div className="row">
                  <div className="col-6">
                    <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['cashpower_meter_num'] ? 'has-error-bar' : '' }` }>
                      <input type="text" className='form-control' id='cashpower-meter-num' name='cashpower_meter_num' value={ recipientInformation.cashpower_meter_num || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                      <label htmlFor="cashpower-meter-num">Meter Number</label>
                      <span className={ `hint text-danger ${ errorMessages['cashpower_meter_num'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['cashpower_meter_num'] }</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['confirm_cashpower_meter_num'] ? 'has-error-bar' : '' }` }>
                      <input type="text" className='form-control' id='confirm-cashpower-meter-num' name='confirm_cashpower_meter_num' value={ recipientInformation.confirm_cashpower_meter_num || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                      <label htmlFor="confirm-cashpower-meter-num">Confirm Meter Number</label>
                      <span className={ `hint text-danger ${ errorMessages['confirm_cashpower_meter_num'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['confirm_cashpower_meter_num'] }</span>
                    </div>
                  </div>
                </div> :
                <div className="row mb-3">
                  <div className="col-12">
                    <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessages['phone_carrier_id'] ? 'has-error-bar' : '' }` }>
                      <select className='form-control' id='phone_carrier_id' name='phone_carrier_id' value={ recipientInformation.phone_carrier_id || '' } onChange={ (event) => ( handleRecipientInformation(event))}>
                        <option value=''></option>
                        { phoneCarriers.map(p => <option key={ p.id } value={ p.id }>{ p.name }</option>) }
                      </select>
                      <label htmlFor="phone_carrier_id">Select Phone Carrier</label>
                      <span className={ `hint text-danger ${ errorMessages['phone_carrier_id'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['phone_carrier_id'] }</span>
                    </div>
                  </div>
                </div>

            }

            <div className='d-block text-right'>
              {
                submittingNewRecipient ?
                  <a href='#' className='js-notJump mt-1 btn btn-primary disabled' disabled='true'>Saving New Recipient...</a>
                  : <a href='#' onClick={ () => { onAddRecipient() } } className='js-notJump mt-1 btn btn-primary'>Save New Recipient</a>
              }
            </div>

            <hr className='mt-4 mb-4 mx-auto' />
          </div>
      }

      {
        needSpecificForm ?
          <div>
            <hr className='mt-4 mb-4 mx-auto' />

            <div className="row">
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['cashpower_meter_num'] ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='cashpower-meter-num' name='cashpower_meter_num' value={ recipientInformation.cashpower_meter_num || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                  <label htmlFor="cashpower-meter-num">Meter Number</label>
                  <span className={ `hint text-danger ${ errorMessages['cashpower_meter_num'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['cashpower_meter_num'] }</span>
                </div>
              </div>
              <div className="col-6">
                <div className={ `form-group form-group-50 form-group-focus ${ errorMessages['confirm_cashpower_meter_num'] ? 'has-error-bar' : '' }` }>
                  <input type="text" className='form-control' id='confirm-cashpower-meter-num' name='confirm_cashpower_meter_num' value={ recipientInformation.confirm_cashpower_meter_num || '' } onChange={ (event) => ( handleRecipientInformation(event))} />
                  <label htmlFor="confirm-cashpower-meter-num">Confirm Meter Number</label>
                  <span className={ `hint text-danger ${ errorMessages['confirm_cashpower_meter_num'] ? 'd-inline-block' : 'd-none' }` }>{ errorMessages['confirm_cashpower_meter_num'] }</span>
                </div>
              </div>
            </div>

            <div className='d-block text-right'>
              {
                submittingNewRecipient ?
                  <a href='#' className='js-notJump mt-1 btn btn-primary disabled' disabled='true'>Saving Meter Number...</a>
                  : <a href='#' onClick={ () => { onAddRecipient() } } className='js-notJump mt-1 btn btn-primary'>Save Meter Number</a>
              }
            </div>

            <hr className='mt-4 mb-4 mx-auto' />
          </div>
          : null
      }

    </form>
  )
}

export default RecipientForm;
