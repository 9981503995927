class StripeClient {
  constructor() {
    Stripe.setPublishableKey(window.stripeClient.key);
  }

  createToken({ number, expired, cvc }, callback) {
    Stripe.card.createToken({
      number,
      cvc,
      exp_month: parseInt(expired.split('/')[0].trim()),
      exp_year: parseInt(expired.split('/')[1].trim())
    }, callback);
  }
}

export default StripeClient;