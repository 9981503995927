export const recipientsConstants = {
  RECIPIENTS_INIT_VARIABLES: 'RECIPIENTS_INIT_VARIABLES',
  RECIPIENTS_CHANGE_VALUE: 'RECIPIENTS_CHANGE_VALUE',
  RECIPIENTS_EDIT_TOGGLE: 'RECIPIENTS_EDIT_TOGGLE',
  RECIPIENTS_CHANGE_RECIPIENT_FIELD: 'RECIPIENTS_CHANGE_RECIPIENT_FIELD',
  RECIPIENTS_SAVE_CHANGE: 'RECIPIENTS_SAVE_CHANGE',
  RECIPIENT_UPDATE_ERRORS: 'RECIPIENT_UPDATE_ERRORS',
  RECIPIENT_ADD_NEW_RECIPIENT: 'RECIPIENT_ADD_NEW_RECIPIENT',
  RECIPIENT_DELETE_RECIPIENT: 'RECIPIENT_DELETE_RECIPIENT'
};
