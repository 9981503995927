import _ from 'lodash';

import { store } from '../store';
import { orderConstants } from '../constants';
import { orderService } from '../../../services/order_service';
import { checkoutService } from '../../../services/checkout_service';

/**
* Private Functions
**/

const actionType = orderConstants;
const reduxStore = store;
const request = (containerKey, payload) => ({ type: actionType.ORDER_ADD_REQUEST, containerKey, payload });
const success = (containerKey, order) => {
  if (order && order.success === false) { return { type: actionType.ORDER_ADD_ERROR, containerKey, error: order.message } };

  const counting = order.current_order_count || order.item_count;

  $('.js-navCartLinkMobile .js-navMobileQty').html(`(${ counting })`);
  $('.js-navCartLink a').html(`Cart (${ counting })`);

  $('.js-navCartLinkMobile a').addClass('active');
  $('.js-navCartLink').addClass('active');

  if (order.added_subscription === true) {
    $('.js-subscriptionAlertAdded').html(order.added_subscription_alert);
  }

  return { type: actionType.ORDER_ADD_SUCCESS, containerKey, order }
};

const failure = (containerKey, order) => ({ type: actionType.ORDER_ADD_FAILURE, containerKey, order });
const errors = (containerKey, error) => ({ type: actionType.ORDER_ADD_ERROR, containerKey, error });
const orderServiceRemote = (containerKey, dispatch, params) => {
  orderService.addSingleToCart(params)
    .then(
      order => {
        if(order.errors) {
          dispatch(errors(containerKey, order.errors[0]));
        } else {
          dispatch(success(containerKey, order));
          setTimeout(() => {
            dispatch(orderHasAdded(containerKey))
          }, 3000)
        }
      },
      error => {
        dispatch(errors(containerKey, error.toString()));
      }
    );
}

const orderHasAdded = (containerKey) => ({ type: actionType.ORDER_HAS_ADDED, containerKey });

/**
* Global Actions
**/

const initState = (containerKey, payload) => ({
  type: orderConstants.ORDER_INIT_VARIABLES,
  containerKey,
  payload
});

const addToCart = (containerKey, params) => {
  const state = reduxStore.getState().order[containerKey];
  const { errorMessages } = state;

  if (_.isEmpty(errorMessages)) {
    return dispatch => {
      dispatch(request(containerKey, params));
      orderServiceRemote(containerKey, dispatch, params);
    };
  } else {
    return dispatch => {};
  }
}

const addRecipient = (containerKey) => {
  const state = reduxStore.getState().order[containerKey];
  const { errorMessages, recipientInformation } = state;
  const recipientId = recipientInformation.selectedRecipient ? recipientInformation.selectedRecipient.id : null;

  let params = {};


  if (_.isEmpty(errorMessages)) {
    let remote = null;

    if (recipientInformation.selectedRecipient) {
      remote = checkoutService.updateRecipient;
      params = { cashpower_meter_num: recipientInformation.cashpower_meter_num };
    } else {
      remote = checkoutService.createRecipient;
      params = { ...recipientInformation };

      delete params.selectedRecipient;
      delete params.createNew;
      delete params.confirm_cashpower_meter_num;
      delete params.needSpecificForm;
    }

    if (params.phone_number) {
      params.phone_number = params.phone_number.replace("+220 ", "")
    }

    return dispatch => {
      dispatch({ type: orderConstants.ORDER_ADDING_RECIPIENT, value: true });

      remote({ recipient: params }, recipientId)
        .then(
          response => {
            if(response.errors) {
              dispatch({ type: orderConstants.ORDER_SET_ERROR_MESSAGE, containerKey, errorAction: 'add', errors: { fullMessageError: response.errors[0] }});
            } else {
              dispatch({ type: orderConstants.ORDER_ADDED_RECIPIENT, containerKey, response });
            }
          },
          error => {
            dispatch({ type: orderConstants.ORDER_SET_ERROR_MESSAGE, containerKey, errorAction: 'add', errors: { fullMessageError: error.toString() }});
          }
        );
    };
  } else {
    return dispatch => {};
  }
}

const setSelectedProduct = (containerKey, product) => ({
  type: orderConstants.ORDER_SET_SELECTED_PRODUCT,
  containerKey,
  product
});

const setSelectedRecipient = (containerKey, recipient) => ({
  type: orderConstants.ORDER_SET_SELETED_RECIPIENT,
  containerKey,
  recipient
});

const setFieldNewRecipient = (containerKey, key, value) => ({
  type: orderConstants.ORDER_SET_FIELD_NEW_RECIPIENT,
  containerKey,
  key,
  value
})

const setPhoneCarrierId = (containerKey, value) => ({
  type: orderConstants.ORDER_SET_PHONE_CARRIER,
  containerKey,
  value
})

const setAddNewRecipient = (containerKey) => {
  const state = reduxStore.getState().order[containerKey];
  const { userSignedIn } = state;

  if (!userSignedIn) {
    $('#modalSignup').modal('show');
    return {
      type: ''
    }
  } else {
    return {
      type: orderConstants.ORDER_SET_ADD_NEW_RECIPIENT,
      containerKey
    }
  }
}

const setSelectedImage = (containerKey, index) => ({
  type: orderConstants.ORDER_SET_SELECTED_IMAGE,
  containerKey,
  index
});

const setProductByOptionValue = (containerKey, option, value) => {
  return { type: orderConstants.ORDER_SET_PRODUCT_BY_OPTION_VALUE, containerKey, option, value };
}

const setErrorMessage = (containerKey, errorAction, errors, key) => {
  return { type: orderConstants.ORDER_SET_ERROR_MESSAGE, containerKey, errorAction, errors, key };
}

export const orderActions = {
  addToCart,
  initState,
  setSelectedProduct,
  setSelectedImage,
  setProductByOptionValue,
  setSelectedRecipient,
  setAddNewRecipient,
  setErrorMessage,
  setFieldNewRecipient,
  addRecipient,
  setPhoneCarrierId
};
