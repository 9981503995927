import React from 'react';
import InputMask from 'react-input-mask';

const WoofRecipientForm = (payload) => {
  const {
    onSubmitRecipientInfo,
    errorMessage,
    recipientInformationErrors,
    recipients,
    handleRecipientSelect,
    createNewRecipient,
    useFormCreateRecipient,
    handleRecipientInformation,
    addMoreInfoRecipient,
    setAddRecipientInfo,
    setRecipientInformationConfirmed,
    recipientInformation,
    selectedRecipient,
    submittingInfo,
    cities,
    recipientInformationConfirmed,
    showVerificationSection,
    forSubscription
  } = payload;

  const confirmLabel = () => {
    if (!forSubscription) {
      if (!createNewRecipient && !selectedRecipient) { return 'Send order to -' };
      if (createNewRecipient) { return `Send order to this recipient` };

      return `Send order to this recipient`;
    } else {
      if (!createNewRecipient && !selectedRecipient) { return 'Send subscription order to -' };
      if (createNewRecipient) { return `Send subscription order to this recipient` };

      return `Send subscription order to this recipient`;
    }
  }

  const showVerifyModal = () => {
    $('#modalVerifyAccount').modal('show')
  }

  const keySelectRecipientId = forSubscription ? 'subscription_recipient_id' : 'recipient_id';

  return (
    <div>
      <form className='form-checkout mt-4' onSubmit={ (e) => { onSubmitRecipientInfo(e, forSubscription) } }>

        {
          errorMessage(recipientInformationErrors, 'fullMessageError', forSubscription) ?
            <div className='alert alert-danger'>{ errorMessage(recipientInformationErrors, 'fullMessageError', forSubscription) }</div> : null
        }

        <div className="row">
          <div className="col-12">
            <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessage(recipientInformationErrors, keySelectRecipientId, forSubscription) ? 'has-error-bar' : '' }` }>
              <select className='form-control' id='recipient-id' name={ keySelectRecipientId } value={ recipientInformation[keySelectRecipientId] } onChange={ () => ( handleRecipientSelect(event, forSubscription))}>
                <option value=''></option>
                { recipients.map(i => <option key={i.id} value={ i.id } disabled={ i.blacklisted }>{ i.first_name } { i.last_name } { i.blacklisted ? ' - Recipient suspended' : '' }</option>) }
              </select>
              <label htmlFor="recipient-id">Select Existing Recipient</label>
              <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, keySelectRecipientId, forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, keySelectRecipientId, forSubscription) }</span>
            </div>
          </div>
        </div>

        {
          createNewRecipient ?
            null :
            <div className='text-right mt-2'>
              <a href='#' className='js-notJump text-blue' onClick={ () => { useFormCreateRecipient(forSubscription) } }>+ Add a new recipient</a>
            </div>
        }

        {
          !createNewRecipient ?
            null :
            <div>
              <hr className='mt-4 mb-3' />

              <div className='row'>
                <div className='col-12'>
                  <div className='form-group'>
                    <h4 className='text-dark'>Create New Recipient</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'first_name', forSubscription) ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='first-name' name='first_name' value={ recipientInformation.create_new_recipient.first_name } onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                    <label htmlFor="first-name">first name</label>
                    <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'first_name', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'first_name', forSubscription) }</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'last_name', forSubscription) ? 'has-error-bar' : '' }` }>
                    <input type="text" className='form-control' id='last-name' name='last_name' value={ recipientInformation.create_new_recipient.last_name } onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                    <label htmlFor="last-name">last name</label>
                    <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'last_name', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'last_name', forSubscription) }</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'phone_number', forSubscription) ? 'has-error-bar' : '' }` }>
                    <InputMask maskChar=' ' mask="+220\ 9999999" value={ recipientInformation.create_new_recipient.phone_number } className='form-control' id='phone-number' name='phone_number' onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                    <label htmlFor="phone-number">Their Gambian Phone (+220)</label>
                    <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'phone_number', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'phone_number', forSubscription) }</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className={ `form-group form-group-50 form-group-focus select-form square no-anchor m-0 ${ errorMessage(recipientInformationErrors, 'city', forSubscription) ? 'has-error-bar' : '' }` }>
                    <select className='form-control' id='city' name='city' value={ recipientInformation.create_new_recipient.city } onChange={ () => ( handleRecipientInformation(event, forSubscription))}>
                      { cities.map(i => <option key={ cities.indexOf(i) } value={ i }>{ i }</option>) }
                    </select>
                    <label htmlFor="city">Their City</label>
                    <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'city', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'city', forSubscription) }</span>
                  </div>
                </div>
              </div>

              {
                !addMoreInfoRecipient ?
                  null :
                  <div>
                    <div className="row">
                      <div className="col-6 d-none">
                        <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'email', forSubscription) ? 'has-error-bar' : '' }` }>
                          <input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" className='form-control' id='email' name='email' value={ recipientInformation.create_new_recipient.email } onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                          <label htmlFor="email">Email</label>
                          <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'email', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'email', forSubscription) }</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'cashpower_meter_num', forSubscription) ? 'has-error-bar' : '' }` }>
                          <input type="text" className='form-control' id='cashpower-meter-num' name='cashpower_meter_num' value={ recipientInformation.create_new_recipient.cashpower_meter_num } onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                          <label htmlFor="cashpower-meter-num">Cashpower Meter Number</label>
                          <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'cashpower_meter_num', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'cashpower_meter_num', forSubscription) }</span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 d-none ">
                        <div className={ `form-group form-group-50 form-group-focus ${ errorMessage(recipientInformationErrors, 'second_phone_number', forSubscription) ? 'has-error-bar' : '' }` }>
                          <InputMask maskChar=' ' mask="9999999" className='form-control' id='second_phone_number' name='phone_number' value={ recipientInformation.create_new_recipient.second_phone_number } onChange={ () => ( handleRecipientInformation(event, forSubscription))} />
                          <label htmlFor="second-phone-number">2nd Phone Number</label>
                          <span className={ `hint text-danger ${ errorMessage(recipientInformationErrors, 'second_phone_number', forSubscription) ? 'd-inline-block' : 'd-none' }` }>{ errorMessage(recipientInformationErrors, 'second_phone_number', forSubscription) }</span>
                        </div>
                      </div>
                    </div>
                  </div>
              }

              {
                addMoreInfoRecipient ?
                  null :
                  <span><a href='#' className='js-notJump text-blue hidden' onClick={ () => { setAddRecipientInfo(forSubscription) } }>+ Add extra information</a> <span className='hidden text-gray text-small-2x'>(Cashpower Meter Number, 2nd Phone, etc.)</span></span>
              }

            </div>
        }

        {
          createNewRecipient ?
            <div className='mt-4 form-check checkbox-wrapper'>
              <input className="form-check-input" id="confirm-recipient" type="checkbox" checked={ recipientInformationConfirmed } name="confirmed" onChange={ () => { setRecipientInformationConfirmed(!recipientInformationConfirmed, forSubscription) } } />
              <label className="form-check-label checkbox-label text-small-1x text-gray text-lighter-weight" htmlFor="confirm-recipient">
                <span className='check-css'></span>
                { confirmLabel() }
              </label>
            </div> :
            null
        }

        <input type="submit" className='mt-3 btn btn-primary btn-block' disabled={ submittingInfo } value={ submittingInfo ? 'Submitting...' : 'Save & Continue'} />
      </form>
    </div>
  )
}

export default WoofRecipientForm;
